import React from 'react';
import { connect } from 'react-redux';
import { closeModal } from '../../../redux/actions/modals';
import { Formik } from 'formik';
import { WithContext as ReactTags } from 'react-tag-input';
import axios from 'axios'


class EmailShareModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      tags: [],
      gEmails: [],
      emailSent: false
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleAuthorization = this.handleAuthorization.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.importContact = this.importContact.bind(this);
    this.importContacts = this.importContacts.bind(this);
    this.start = this.start.bind(this)
  };


  // THIS PART IS FOR THE TAG ADD AND DRAG/DROP

  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    });
  };

  validateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  handleAddition(tag) {
    if (this.validateEmail(tag.text)) {
      this.setState(state => ({ tags: [...state.tags, tag] }));
    }
  };

  handleDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
  };


  //THIS IS THE IMPORT EMAIL CONTACTS OPTIONS

  loadGoogleScript() {
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/client.js";
    document.body.appendChild(script);
  }

  componentDidMount() {
    this.loadGoogleScript();
  }

  start = () => {
    window.gapi.client.init({
      'apiKey': process.env.REACT_APP_GOOGLE_MAPS_KEY,
      'clientId': '884436097807-0e0cgn5j1lo7cjerb0vnkdskneoc22qh.apps.googleusercontent.com',
      'scope': 'https://www.googleapis.com/auth/user.emails.read'
    }).then(() =>
      window.gapi.auth.authorize({
        client_id: '884436097807-0e0cgn5j1lo7cjerb0vnkdskneoc22qh.apps.googleusercontent.com',
        scope: 'https://www.googleapis.com/auth/user.emails.read',
        immediate: false,
        fetch_basic_profile: false
      }, this.handleAuthorization)
    )
      ;
  }

  handleAuthorization = function (authorizationResult) {
    if (authorizationResult && !authorizationResult.error) {

      axios.get(`https://www.google.com/m8/feeds/contacts/default/thin?alt=json&access_token=${authorizationResult.access_token}&max-results=500&v=3.0`, {
        withCredentials: false // https://stackoverflow.com/a/45723981
      }).then(function (response) {
        const self = response.data.feed.id['$t'];
        const contacts = response.data.feed.entry;
        return (() => {
          const result = [];
          for (let contact of Array.from(contacts)) {
            if (contact.gd$email[0].address !== self) {
              result.push(this.state.gEmails.push({
                name: (contact.gd$name != null) ? contact.gd$name.gd$fullName['$t'] : null,
                email: contact.gd$email[0].address
              }));
            } else {
              result.push(undefined);
            }
          }
          return result;
        })();
      }
        , responseError => console.log('tell a friend contact import failed'));
    }
  };

  importContacts = function () {
    return window.gapi.load('client', this.start);
  };

  importContact = function (index, email) {
    this.state.gEmails.push({ email });
    return this.state.gEmails.splice(index, 1);
  };



  render = () => {
    const { tags, suggestions } = this.state;

    return (
      <Formik
        initialValues={{ name: '', emails: [] }}
        validateOnChange={false}
        validate={values => {
          let errors = {};


          if (!values.name) { errors.name = 'Please enter your name' };


         
          if (this.state.tags.map(each => each.text).length === 0) {errors.emails = 'Must enter at least one email'}

          return errors;
        }}
        handleSubmit={(e) => e.preventDefault()}

        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          values.emails = this.state.tags.map(each => each.text);
          axios.post(`${process.env.REACT_APP_BASE_API_URL}/api/v3/mailings/tell_friends`, values, { withCredentials: true })
            .then(res => {
              if (res.statusText === "OK") {
                this.setState({ emailSent: true })
              };
              setSubmitting(false);
            });
        }}

        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
            <form className="d-block" name="emailForm" onSubmit={handleSubmit} noValidate>
              <div className="dialog">
                <header className="top-action-panel">
                  <button className="close-reveal-modal" onClick={() => { this.props.closeModal() }}>✖</button>
                </header>
                <div className="pt-0 pr-5 pl-5">
                  <header className="">
                    <div className="row m-auto">
                      <div className="col-12 d-flex flex-column justify-content-center text-center">
                        <h2 className="funnel-title upercase mb-3">Give your friends a discount!</h2>
                        <p>Share $20 with your friends and <b>get $20 for EVERY friend that moves with Unapkt!</b></p>
                      </div>
                    </div>

                  </header>
                  <div className="">
                    {!this.props.emailSent ? (
                      <>
                        <div>
                          <p className="mb-1 desc">Your Full Name</p>
                          <input type="text" name="name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`unpakt-textbox m-0 w-100 ${touched.name && errors.name && 'field-error'}`}
                            required />
                        </div>
                        {errors.name &&
                          <span className="field-error-message message">{errors.name}</span>
                        }



                        <div className="mt-4">
                          <p className="mb-1 desc">Email Addresses (Press enter or separate with a comma to add each email)</p>

                          <ReactTags
                            tags={tags}
                            suggestions={suggestions}
                            handleDelete={this.handleDelete}
                            handleAddition={this.handleAddition}
                            handleInputFocus={() => { }}
                            handleDrag={this.handleDrag}
                            allowDragDrop={false}
                            placeholder={'Email Addresses'}
                            delimiters={[188, 13]}
                            className="tag-container"
                          />


                          {/* <input type="email" name="emails"
                            value={values.emails}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder='Emails'
                            className={`unpakt-textbox m-0 w-100 ${touched.emails && errors.emails && 'field-error'}`}
                            autoComplete="email"
                            required /> */}

                        </div>
                        {errors.emails &&
                          <span className="field-error-message message">{errors.emails}</span>
                        }


                        {/* TODO THIS HAS NEVER WORKED ON PRODUCTION / FIGURE OUT HOW TO FIX */}
                        <p className="mt-4 text-center">
                          {this.state.emailSent ?
                            <span className="orange-text">✓ Your discounts have been sent!</span>
                            :
                            <small>
                              {/* <span className="link" onClick={this.importContacts}>Import your contacts from Gmail</span> to help more people save money and move easily! */}
                            </small>
                          }
                        </p>


                      </>
                    ) : (
                        <div>
                          <p className="success">
                            <span>✓ Fantasic. You've shared $20 off for {values.emails}</span>
                          </p>
                        </div>
                      )
                    }
                  </div>
                </div>
                <div className="pt-3 pb-5 text-center">
                  
                  {(!this.state.emailSent && !isSubmitting) ? (
                    <button className="primary-btn-small"
                      type="submit"
                      disabled={isSubmitting}
                      ladda="emailSending">
                      Share $20 off with Friends!
                    </button>
                  ) : (
                      <button className="primary-btn-small"
                        onClick={() => { this.props.closeModal() }}>
                        Done
                      </button>
                    )
                  }
                </div>

              </div>
              <style jsx>{/* CSS */`
                    .primary-btn-small {
                      height: 34px;
                    }
                    :global(.ReactTags__tagInput) {
                        width: 100%;
                        border-radius: 2px;
                        display: inline-block;
                    }
                    :global(.ReactTags__tagInput input.ReactTags__tagInputField),
                    :global(.ReactTags__tagInput input.ReactTags__tagInputField:focus) {
                      height: 34px;
                      width:100%;
                      font-size: 13px;
                      color: #042b3b;
                      background: #fff;
                      border: 1px solid #819ca1;
                      padding: 8px 14px;
                      box-shadow: none;
                      border-radius: 2px;
                      font-family: "ProximaNovaRegular", sans-serif;
                    }
                    :global(.ReactTags__selected span.ReactTags__tag) {
                        border: 1px solid #ddd;
                        background: #eee;
                        font-size: 12px;
                        display: inline-block;
                        padding: 5px;
                        margin: 5px 5px 5px 0;
                        border-radius: 2px;
                    }
                    :global(.ReactTags__selected a.ReactTags__remove) {
                        color: #aaa;
                        margin-left: 5px;
                        cursor: pointer;
                    }

        
                    :global(.ReactTags__suggestions) {
                        position: absolute;
                    }
                    :global(.ReactTags__suggestions ul) {
                        list-style-type: none;
                        box-shadow: .05em .01em .5em rgba(0,0,0,.2);
                        background: white;
                        width: 200px;
                    }
                    :global(.ReactTags__suggestions li) {
                        border-bottom: 1px solid #ddd;
                        padding: 5px 10px;
                        margin: 0;
                    }
                    :global(.ReactTags__suggestions li mark) {
                        text-decoration: underline;
                        background: none;
                        font-weight: 600;
                    }
                    :global(.ReactTags__suggestions ul li.ReactTags__activeSuggestion) {
                        background: #b7cfe0;
                        cursor: pointer;

                    }
                    .orange-text{
                      color: #fe931b;
                      font-size: 1.2rem;
                    }

                    h2 {
                      font-size: 2.4rem;
                      font-family: ProximaNovaBold, sans-serif;
                      font-weight:800;
                      color: #0abee8
                    }

                    .desc {
                      font-size: 1.2rem;
                      color: gray
                    }
                    .field-error-message {
                      color: red;
                    }
              `}</style>
            </form>
          )} />
    )
  }
}

export default connect(null, { closeModal })(EmailShareModal);
