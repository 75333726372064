import { INIT_MDA, GET_MOVEPLAN_WITH_TOKEN, SET_MDA_TOKEN, MOVE_DAY_ADJUSTMENT, UPDATE_MDA_PRICE, RESET_MDA, SET_MDA_LOADING, INIT_MDA_FINISHED, ACCEPT_MDA, REJECT_MDA, SET_MDA_HOURLY_UPDATE_LOADING, FETCH_PREV_HOURLY_VALUES } from "../actionTypes"

export const initMDA = () => ({
  type: INIT_MDA
});

export const setMdaToken = token => ({
  type: SET_MDA_TOKEN,
  payload: token
});

export const getMoveplanWithToken = (token) => ({
  type: GET_MOVEPLAN_WITH_TOKEN,
  payload: token
});

export const moveDayAdjustment = (changes) => ({
  type: MOVE_DAY_ADJUSTMENT,
  payload: changes
});

export const updateMDAPrice = (newPrice) => ({
  type: UPDATE_MDA_PRICE,
  payload: newPrice
});

export const resetMDA = (payload) => ({
  type: RESET_MDA,
  payload: payload
});

export const setMDALoading = bool => ({
  type: SET_MDA_LOADING,
  payload: bool
});

export const initMdaFinished = () => ({
  type: INIT_MDA_FINISHED
});

export const acceptMDA = (token) => ({
  type: ACCEPT_MDA,
  payload: token
});

export const rejectMDA = (token) => ({
  type: REJECT_MDA,
  payload: token
});

export const setMDAHourlyUpdateLoading = bool => ({
  type: SET_MDA_HOURLY_UPDATE_LOADING,
  payload: bool
});

export const fetchPrevHourlyValues = (payload) => ({
  type: FETCH_PREV_HOURLY_VALUES,
  payload: payload
});