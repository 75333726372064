import { handleActions } from 'redux-actions';
import {
  TOGGLE_IS_MOVER,
  SET_EMAIL_TAKEN,
  SET_USER,
  SET_ERROR_MSG,
  UPDATE_FACEBOOK_AUTH,
  SET_AUTH_MODAL_VIEW, SET_AUTH_SUBMITTING, UNSET_USER, SET_IS_ADMIN, SET_ROLE, SET_RESET_EMAIL, SET_ALL_MOVEPLANS, SET_LAST_MPID
} from '../actionTypes';
import omit from 'lodash/omit';


const initialState = {
  isMover: false,
  isAdmin: false,
  isSuperAdmin: false,
  isPublic: false,
  isEmailTaken: false,
  facebookResponse: {},
  authModalView: 'login',
  authErrorText: '',
  submitting: false,
  resetEmail: undefined
};

const authReducer = handleActions(
  {
    [SET_ROLE]: (state, {payload}) => {
      switch(payload.role) {
        case 0:
          return ({
            ...state,
            isSuperAdmin: payload.bool,
            isPublic: !payload.bool
          });
        case 1:
          return ({
            ...state,
            isAdmin: payload.bool,
            isPublic: !payload.bool
          });
        case 2:
          return ({
            ...state,
            isMover: payload.bool,
            isPublic: !payload.bool
          });
        default:
          return ({
            ...state,
            isPublic: true
          });
      }
    },
    [TOGGLE_IS_MOVER]: state => ({
      ...state,
      isMover: !state.isMover
    }),
    [SET_IS_ADMIN]: (state, { payload }) => ({
      ...state,
      isAdmin: payload
    }),
    [SET_EMAIL_TAKEN]: (state, action) => ({
      ...state,
      isEmailTaken: action.payload
    }),
    [SET_USER]: (state, {payload}) => ({
      ...state,
      user: payload
    }),
    [SET_ERROR_MSG]: (state, {payload}) => ({
      ...state,
      authErrorText: payload
    }),
    [UPDATE_FACEBOOK_AUTH]: (state, {payload}) => ({
      ...state,
      facebookResponse: payload
    }),
    [SET_AUTH_MODAL_VIEW]: (state, {payload}) => ({
      ...state,
      authModalView: payload
    }),
    [SET_AUTH_SUBMITTING]: (state, {payload}) => ({
      ...state,
      submitting: payload
    }),
    [UNSET_USER]: (state) => (
      omit(state, 'user')
    ),
    [SET_RESET_EMAIL]: (state, {payload}) => ({
      ...state,
      resetEmail: payload
    }),
    [SET_ALL_MOVEPLANS]: (state, {payload}) => ({
      ...state,
      moveplans: payload
    }),
    [SET_LAST_MPID]: (state, {payload}) => ({
      ...state,
      lastMPID: payload
    }),
  },
  initialState
);

export const canEdit = (movePlan, auth) => (
  (!movePlan.currentPlan.is_completed && !movePlan.currentPlan.is_done && !movePlan.currentPlan.read_only_plan && !auth.isMover)
  || (auth.isAdmin && !movePlan.currentPlan.read_only_plan)
);

export const isLoggedIn = (auth) => (
  !!auth.user ? Object.keys(auth.user).length > 0 : false
);

export default authReducer;
