import { INIT_DETAILS_PAGE, SET_DETAILS_LOADING, SUBMIT_LOCATION, SUBMIT_BASICS } from '../actionTypes';

export const initDetailsPage = (movePlanId) => ({
  type: INIT_DETAILS_PAGE,
  payload: movePlanId
});

export const submitLocation = (values) => ({
  type: SUBMIT_LOCATION,
  payload: values
});

export const submitBasics = (values) => ({
  type: SUBMIT_BASICS,
  payload: values
});

export const setDetailsLoading = (bool) => ({
  type: SET_DETAILS_LOADING,
  payload: bool
});