import React from 'react';
import {connect} from 'react-redux';
import {closeModal} from "../../../redux/actions/modals";
import { Container, Row } from 'reactstrap';
import { completeConfirmationFunnel } from '../../../redux/actions/movePlan';

const mapStateToProps = ({movePlan}) => ({
  prices: movePlan.currentPlan.pricing,
  hourly: movePlan.currentPlan.hourly_plan
});


const ConfirmComplete = ({prices, closeModal, completeConfirmationFunnel, hourly}) => (
  <>
    <header className="top-action-panel">
      <button className="close-reveal-modal" onClick={closeModal}>✖</button>
    </header>
    <div className="modal-body">
      <Container>
        <Row className="m-auto mb-5 text-center">
          <div className="confirm-send-off">
            <p>You're all set! </p>
            <p className="gray-subtitle">
            Here is your latest pricing breakdown.
            Unpakt looks forward to moving you!
            </p>
          </div>
        </Row>

        {/* //TODO: this should be done via mapping the price object keys, rather than repeat all this code. KAAAARRRRRLLLLLL*/}
        <Row className="d-flex justify-content-between price-line mt-5 pt-3">
          <div className="col-8 pl-0">
            <p><b>{hourly? 'Hourly' : 'Inventory'}</b></p>
          </div>
          <div className="col-4 text-right pr-0">
            <p>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(prices.moving_cost)}</p>
          </div>
        </Row>
        
        <Row className="d-flex justify-content-between price-line">
          <div className="col-8 pl-0">
            <p><b>Truck Travel</b></p>
          </div>
          <div className="col-4 text-right pr-0">
            <p>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(prices.travel_cost)}</p>
          </div>
        </Row>

        { prices.size_surcharge_cost > 0 &&
        <Row className="d-flex justify-content-between price-line">
          <div className="col-8 pl-0">
            <p><b>Oversize Surcharge</b></p>
          </div>
          <div className="col-4 text-right pr-0">
            <p>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(prices.size_surcharge_cost)}</p>
          </div>
        </Row>
        }

        { prices.special_handling_cost > 0 &&
        <Row className="d-flex justify-content-between price-line">
          <div className="col-8 pl-0">
            <p><b>Special Handling</b></p>
          </div>
          <div className="col-4 text-right pr-0">
            <p>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(prices.special_handling_cost)}</p>
          </div>
        </Row>
        }

        { prices.storage_cost > 0 &&
        <Row className="d-flex justify-content-between price-line">
          <div className="col-8 pl-0">
            <p><b>Storage</b></p>
          </div>
          <div className="col-4 text-right pr-0">
            <p>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(prices.storage_cost)}</p>
          </div>
        </Row>
        }

        { prices.packing_cost > 0 &&
        <Row className="d-flex justify-content-between price-line">
          <div className="col-8 pl-0">
            <p><b>Packing Services</b></p>
          </div>
          <div className="col-4 text-right pr-0">
            <p>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(prices.packing_cost)}</p>
          </div>
        </Row>
        }

        { prices.cardboard_cost > 0 &&
        <Row className="d-flex justify-content-between price-line">
          <div className="col-8 pl-0">
            <p><b>Box Purchases</b></p>
          </div>
          <div className="col-4 text-right pr-0">
            <p>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(prices.cardboard_cost)}</p>
          </div>
        </Row>
        }

        { prices.surcharge_cubic_feet_cost > 0 &&
        <Row className="d-flex justify-content-between price-line">
          <div className="col-8 pl-0">
            <p><b>Minimum Move Size Surcharge</b></p>
          </div>
          <div className="col-4 text-right pr-0">
            <p>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(prices.surcharge_cubic_feet_cost)}</p>
          </div>
        </Row>
        }

        { prices.coi_cost > 0 &&
        <Row className="d-flex justify-content-between price-line">
          <div className="col-8 pl-0">
            <p><b>COI charges</b></p>
          </div>
          <div className="col-4 text-right pr-0">
            <p>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(prices.coi_cost)}</p>
          </div>
        </Row>
        }

        { prices.insurance_cost > 0 &&
        <Row className="d-flex justify-content-between price-line">
          <div className="col-8 pl-0">
            <p><b>Insurance</b></p>
          </div>
          <div className="col-4 text-right pr-0">
            <p>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(prices.insurance_cost)}</p>
          </div>
        </Row>
        }

        { prices.total_adjustments !== 0 &&
        <Row className="d-flex justify-content-between price-line">
          <div className="col-8 pl-0">
            <p><b>Adjustments</b></p>
          </div>
          <div className="col-4 text-right pr-0">
            <p>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(prices.total_adjustments)}</p>
          </div>
        </Row>
        }

        { prices.mover_special_discount !== 0 &&
        <Row className="d-flex justify-content-between price-line">
          <div className="col-8 pl-0">
            <p><b>Mover Special { prices.mover_special_percentage !== 0 && <span> ({prices.mover_special_percentage}% off)</span> }</b></p>
          </div>
          <div className="col-4 text-right pr-0">
            <p>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(prices.mover_special_discount)}</p>
          </div>
        </Row>
        }


        { (prices.discount_without_social_sharing + prices.facebook_social_discount + prices.twitter_social_discount) !== 0 &&
        <Row className="d-flex justify-content-between price-line">
          <div className="col-8 pl-0">
            <p><b>Other Discounts</b></p>
          </div>
          <div className="col-4 text-right pr-0">
            <p>{(prices.discount_without_social_sharing + prices.facebook_social_discount + prices.twitter_social_discount)}</p>
          </div>
        </Row>
        }

        <Row className="d-flex justify-content-between mt-5 mb-5">
          <div className="col-7">
            <h3><b>GRAND TOTAL</b></h3>
          </div>
          <div className="col-5 text-right">
            <h3><b>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(prices.total_cost)}</b></h3>
          </div>
        </Row>

      </Container>

      <div className="d-flex align-items-center justify-content-center w-100 flex-wrap-reverse">
        <span className="p-2">
          <button className="gray-pill-button mt-0 m-4" onClick={closeModal}>GO BACK</button>
        </span>
          <button className="green-pill-button m-4 mt-0" onClick={completeConfirmationFunnel}>ALL DONE!</button>
      </div>
    </div>


    <style jsx>
      {`
        p {
          line-height: 1;
          margin: 0
        }
        :global(.price-line) {
          margin-top: 1rem;
          padding-bottom: 8px;
          border-width: 0 0 1px 0;
          margin-bottom: 0px;
          border-style: solid;
          border-image: linear-gradient( to left, rgba(0,0,0,0), #3fbee8, rgba(0, 0, 0, 0) ) 1 0%;
        }
        :global(.price-line) b {
          font-weight: 200;
        }
        h3 {
            font-size: 1.8rem;
            font-weight: 800;
            color: #47BFE6;
        }
        .green {
          color: #2FC468
        }
        .confirm-send-off p{
          margin: 0 auto;
          line-height: 1.4
        }
        @media screen and (max-width: 420px) {
          .gray-pill-button {
            display: none;
          }
        }

      `}
    </style>
  </>
)


export default connect(mapStateToProps, {closeModal, completeConfirmationFunnel})(ConfirmComplete);
