import { ACCEPT_JOB, DECLINE_JOB, NETWORK_SUCCESS } from '../actionTypes';
import { apiRequest } from '../actions/api';
import {push} from 'connected-react-router';

const moverMiddleware = ({ getState, dispatch }) => next => action => {
  next(action);

  if(action.type === ACCEPT_JOB) {
    dispatch(apiRequest({
      url: `api/v3/jobs/${getState().movePlan.currentPlan.job}/accept`,
      method: 'PATCH',
      fromAction: ACCEPT_JOB
    }))
  }

  if(action.type === DECLINE_JOB) {
    dispatch(apiRequest({
      url: `api/v3/jobs/${getState().movePlan.currentPlan.job}/decline`,
      method: 'PATCH',
      params: {
        job: {
          mover_declined_reason_id: action.payload.mover_declined_reason_id,
          mover_declined_other_reason: action.payload.mover_declined_other_reason,
          mover_declined_comment: action.payload.mover_declined_comment
        }
      },
      fromAction: DECLINE_JOB
    }))
  }

  if(action.type === NETWORK_SUCCESS) {
    switch (action.meta.originalRequest.fromAction) {
      case ACCEPT_JOB:
        //do things
        dispatch(push(`/movers/${getState().currentPlan.mover.id}/dashboard`))
        break;
      case DECLINE_JOB:
        //do things
        break;
      default:
        break;
    }
  }
};

export default moverMiddleware;