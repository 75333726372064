import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';
import LoadingPage from '../common/LoadingPage';
import { initMDA } from '../../redux/actions/mda';

const AsyncMDA = lazy(() => import("../Move_Day_Adjustments/MDAEdit"));
const AsyncMDAHourly = lazy(() => import("../Move_Day_Adjustments/MDAHourly/MDAEditHourly"));
const AsyncMDACustomer = lazy(() => import("../Move_Day_Adjustments/MDACustomer"));
const AsyncMDACustomerHourly = lazy(() => import("../Move_Day_Adjustments/MDAHourly/MDACustomerHourly"));
const AsyncPageNotFound = lazy(() => import("../common/PageNotFound"));

const mapStateToProps = ({ movePlan, router, mda}) => ({
  hourly: movePlan.currentPlan.hourly_plan,
  path: router.location.pathname,
  mpid: movePlan.currentPlan.uuid ? movePlan.currentPlan.uuid : undefined,
  query: router.location.search ? router.location.search : '',
  error: !!movePlan.apiError,
  loading: mda.loading
});

const MainMDA = ({ mpid,  error, initMDA, loading, hourly }) => {
  useEffect(() => {
    initMDA()} //eslint-disable-next-line
  ,[]);
  return loading 
    ? <div className="m-auto w-100">
        <LoadingPage />
      </div>
    : <>
        { mpid 
          ? <div className="">
              <main className="w-100">
                <Switch>
                  <Route exact path="/move-day-adjustment" render={() => hourly?
                  <Suspense fallback={<LoadingPage />}><AsyncMDAHourly/></Suspense>
                  :
                  <Suspense fallback={<LoadingPage />}><AsyncMDA/></Suspense>} />

                  <Route exact path="/move-day-adjustment/customer" render={() => hourly?
                  <Suspense fallback={<LoadingPage/>}><AsyncMDACustomerHourly /></Suspense>
                  :
                  <Suspense fallback={<LoadingPage/>}><AsyncMDACustomer /></Suspense>} />
                  
                  <Route render={() => <Suspense fallback={<LoadingPage />}><AsyncPageNotFound /></Suspense>} />
                </Switch>
              </main>
            </div>
        : <div className="confirm-edit-step-container p-5 text-center mr-auto ml-auto mt-5">
            <p>
              Move plan cannot be identified or your link has expired. Please double check your link
              or contact Unpakt for assistance.
            </p>
            <p>Contact Unpakt: <a href="tel:855-286-7258">855-286-7258 </a></p>
          </div>
        }
    </>
}

export default connect(mapStateToProps, { initMDA })(MainMDA);