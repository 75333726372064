import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { getSuggestions } from '../../redux/actions/common';
import Geosuggest from 'react-geosuggest';
import { track } from '../../redux/actions/analytics';
import has from 'lodash-es/has';

const mapStateToProps = ({ common }) => ({
  suggestions: common.suggestions,
  selected: common.selected
});

const Placeitron = props => {
  let inputRef = useRef();
  useEffect(() => {
    if(!!inputRef && !!props.value) {
      if(!!props.value.geocoded_address && inputRef.props.initialValue !== props.value.geocoded_address) {
        inputRef.update(props.value.geocoded_address)
      }
    }
  }, [props.value])
  const handleSelect = suggestion => {
    if (suggestion === undefined) return;
    let components = {};
    suggestion.gmaps.address_components.map(
      value => (components[value.types[0]] = [value.long_name, value.short_name])
    );
    if (components.locality === 'Washington' && components.administrative_area_level_1 === 'District of Columbia') {
      components.locality[0] = components.administrative_area_level_1.long_name;
      components.locality[1] = components.administrative_area_level_1.short_name;
    }
    let address = {};
    try {
      address = {
        ...props.value,
        lat: suggestion.location.lat,
        lng: suggestion.location.lng,
        geocoded_address: suggestion.description,
        street_address: !!components['street_number'] && components['route']
            ? components['street_number'][0] + ' ' + components['route'][0]
            : null,
        city: !has(components, 'locality') && !has(components, 'administrative_area_level_3') && !has(components, 'administrative_area_level_1')
            ? components['sublocality_level_1'][0]
            : has(components, 'locality')
            ? components['locality'][0]
            : has(components, 'administrative_area_level_3')
            ? components['administrative_area_level_3'][0]
            : components['administrative_area_level_2'][0],
        state: components['administrative_area_level_1'][1],
        zip: !!components['postal_code'] ? components['postal_code'][0] : null
      };
      props.setFieldValue(`${props.addressKey}`, address);
    } catch {
      let addressArray = suggestion.label.split(', ');
      if(addressArray.length > 2) {
        address = {
          ...props.value,
          lat: suggestion.location.lat,
          lng: suggestion.location.lng,
          geocoded_address: suggestion.description,
          street_address: addressArray[0],
          city: addressArray[1],
          state: addressArray[2],
          zip: components['postal_code']
        }
        props.setFieldValue(`${props.addressKey}`, address);
      } else if( addressArray.length === 2) {
        // User tried to enter a state
        props.setFieldError(`${props.addressKey}`, 'Please enter an address, city, or zip code')
      } else {
        props.track('AUTOCOMPLETE ERROR', { addressPayload: components });
        console.log('something went wrong with the address payload');
      }
    }
  };
  return (
    <div className={`replaceify ${props.classes}`} data-icon={props.icon}>
      <svg className={`icon ${props.icon}`}>
        <use xlinkHref={`#${props.icon}`} />
      </svg>
      <Geosuggest
        initialValue={ !! props.value ? props.value.geocoded_address : undefined }
        ref={el => inputRef = el}
        types={!!props.exactAddress ? ['address'] : ['geocode']}
        placeDetailFields={['address_components']}
        country="US"
        autoActivateFirstSuggest={true}
        onSuggestSelect={handleSelect}
        onFocus={() => props.setFieldError(`${props.addressKey}`)}
        minLength={3}
        placeholder={props.placeholder}
        inputClassName={`${!!props.required && 'required'} ${!!props.error && 'field-error'} 
        ${!!props.exactAddress && !!!props.value.street_address && !!!props.error && 'focused-input'}`}
        suggestsClassName="suggestions-dropdown"
        suggestItemClassName="suggestions-item"
        aria-label="Address"
      />
      <style jsx>{/* CSS */`
              .replaceify {
                position: relative;
              }
              .replaceify > svg {
                position: absolute;
                width: 32px;
                height: 44px;
                left: 4px;
              }
              .icon.from-arrow svg {
                fill: #042b3b;
              }
              .icon.to-arrow svg {
                fill: #042b3b;
                height: 10px;
              }
              .icon.blank-pin {
                fill: #91a4aa;
                vertical-align: middle;
                margin-right: 4px;
                -webkit-transition: color 0.25s;
                -o-transition: color 0.25s;
                transition: color 0.25s;
                width: 12px;
              }
      `}</style>
    </div>
  );
};

export default connect(
  mapStateToProps,
  { getSuggestions, track }
)(Placeitron);
