import React from 'react';
import {connect} from 'react-redux';
import { closeModal } from '../../../redux/actions/modals';


const EmailMoveChecklist = ({email, closeModal}) => (
  <>
    <header className="top-action-panel">
      <button className="close-reveal-modal" onClick={() => closeModal()}>✖</button>
    </header>
    <div className="pt-5 pr-5 pl-5">
      <header className="">
        <h3 className="title"><b>Your Move Checklist has been sent</b></h3>
      </header>
      <div className="content-section">
        <p>We sent you an email to {email}.</p>
      </div>
    </div>
    <footer className="p-5">
      <button className="primary-btn-small m-auto w-100" onClick={() => closeModal()}>Done</button>
    </footer>
  </>
);

export default connect(null, {closeModal})(EmailMoveChecklist)