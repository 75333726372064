import {
  NETWORK_SUCCESS,
  GET_CURRENT_MOVEPLAN,
  GET_VALID_DATES,
  UPDATE_MOVE_PLAN,
  UPDATE_BOX_PURCHASES,
  UPDATE_PACKING_SERVICES,
  UPDATE_MOVE_DATE,
  CHECK_VALID_DATES,
  CHECK_MOVER_AVAILABILITY,
  PREPARE_RESCHEDULE_REQUEST,
  EMAIL_PLAN,
  NETWORK_FAILURE,
  ADDRESS_UPDATE, REQUEST_INSURANCE, CANCEL_MOVE, CREATE_MOVEPLAN, CREATE_MOVEPLAN_FOR_PARTNER, UPDATE_COI, UPDATE_ELEVATORS, GET_MOVEPLAN,
  GET_ALL_MOVEPLANS,
  SUBMIT_MOVE_DAY_FEEDBACK,
  COMPLETE_CONFIRMATION_FUNNEL,
  COMPLETE_HOURLY_CONFIRMATION_FUNNEL,
  UPDATE_CONFIRMATION_FUNNEL,
  UPDATE_HOURLY_CONFIRMATION_FUNNEL,
  COMPLETE_CONFIRMATION_STEP,
  COMPLETE_HOURLY_CONFIRMATION_STEP,
  SKIP_CONFIRMATION_STEP,
  SKIP_HOURLY_CONFIRMATION_STEP,
  SUBMIT_DNA,
  CONTINUE_LAST_PLAN,
  UPDATE_NUM_LABORERS,
  UPDATE_NUM_HOURS,
  UPDATE_TRUCK_SIZE,
  UPDATE_NUM_UPRIGHTS,
  UPDATE_HOURLY_STAIRS,
  UPDATE_NUM_BABY_GRAND,
  UPDATE_NUM_HOURLY_OVERWEIGHT,
  UPDATE_NUM_HOURLY_OVERSIZE
} from '../actionTypes';
import {apiRequest} from "../actions/api";
import {
  checkValidDates, clearBoxPurchases,
  getCurrentMoveplan, getValidDates,
  setCurrentPlanInfo, setMoveplanInsurance,
  setValidDates, updateBoxPurchases,
  updateMovePlan,
  setCurrentMoveplanID,
  setMoveplanLoading,
  setSubmitFeedbackResponse,
  updateConfirmationFunnel,
  updateHourlyConfirmationFunnel,
  setMoveplanApiError,
  createMovePlan,
  setHourlyUpdateLoading
} from '../actions/movePlan';
import { openModal, closeModal } from '../actions/modals';
import { setEmailError, setEmailSent, setPlanUpdating } from '../actions/plan';
import { push } from "connected-react-router";
import { setDetailsLoading } from '../actions/details';
import has from 'lodash/has';
import { setInventoryLoading } from '../actions/inventory';
import { hasBoxPurchases } from '../reducers/movePlan';
import { setAllMoveplans, unsetUser } from '../actions/auth';
import { completeConfirmationStep } from '../actions/funnel';
import { completeHourlyConfirmationStep } from '../actions/hourlyFunnel';
import { track } from '../actions/analytics';
import { HOME_SIZES } from '../../constants';

const movePlanMiddleware = ({ getState, dispatch }) => next => action => {
  next(action);

  if (action.type === CREATE_MOVEPLAN) {
    dispatch(setInventoryLoading(true));
    dispatch(apiRequest({
      url: `/api/v3/move_plans`,
      method: 'POST',
      params: {
        move_plan: action.payload
      },
      fromAction: CREATE_MOVEPLAN
    }))
  }

  if (action.type === CREATE_MOVEPLAN_FOR_PARTNER) {
    dispatch(apiRequest({
      url: `/api/v3/move_plans/create_for_partner`,
      method: 'POST',
      params: {
        move_plan: action.payload
      },
      fromAction: CREATE_MOVEPLAN
    }))
  }

  if (action.type === GET_ALL_MOVEPLANS) {
    dispatch(setMoveplanLoading(true));
    dispatch(apiRequest({
      url: `/api/v3/move_plans`,
      method: 'GET',
      fromAction: GET_ALL_MOVEPLANS,
      redirect: action.meta.redirect
    }))
  }

  if (action.type === GET_CURRENT_MOVEPLAN) {
    dispatch(setMoveplanLoading(true));
    const current = getState().movePlan.currentMPID;
    const mpid = !!getState().auth.lastMPID ? getState().auth.lastMPID : current;
    dispatch(
      apiRequest({
        url: `/api/v3/move_plans/${mpid}.json`,
        method: 'GET',
        fromAction: GET_CURRENT_MOVEPLAN
      })
    );
  }



  if (action.type === GET_MOVEPLAN) {
    dispatch(setCurrentMoveplanID(action.payload))
    dispatch(
      apiRequest({
        url: `/api/v3/move_plans/${action.payload}.json`,
        method: 'GET',
        fromAction: GET_MOVEPLAN
      })
    );
  }
  if (action.type === GET_VALID_DATES) {
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/valid_dates`,
        method: 'GET',
        params: {
          move_date: getState().movePlan.currentPlan.details.move_date
        },
        fromAction: GET_VALID_DATES
      })
    );
  }

  if (action.type === UPDATE_MOVE_PLAN) {    
    dispatch(setMoveplanApiError(false));
    let updateParams = {};
    for( let key in action.payload) {
      updateParams[key] = action.payload[key];
    }
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}`,
        method: 'PATCH',
        params: updateParams,
        fromAction: UPDATE_MOVE_PLAN,
        redirect: action.meta.redirect
      })
    )
  }

  if (action.type === UPDATE_BOX_PURCHASES) {
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}`,
        method: 'PATCH',
        params: {
          boxes: action.payload.boxPurchases,
          box_delivery_date: action.payload.date
        },
        fromAction: UPDATE_BOX_PURCHASES
      })
    );
  }

  // if (action.type === REMOVE_BOX_PURCHASES) {
  //   let temp = getState().movePlan.currentPlan.box_purchases;
  //   temp.map( boxPurchase => {
  //     boxPurchase.quantity = 0;
  //     return true;
  //   });
  //   dispatch(
  //     updateMovePlan({
  //       move_plan: {
  //         box_delivery_date: null
  //       }
  //     })
  //   );
  // }
  if (action.type === UPDATE_PACKING_SERVICES) {
    // 1= packing
    // 2= packing & unpacking
    // 4= pack yourself
    dispatch(setMoveplanLoading(true));
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/update_packing`,
        method: 'PATCH',
        params: {
          follow_up_packing_service_id: action.payload
        },
        fromAction: UPDATE_PACKING_SERVICES
      })
    );
  }
  if (action.type === UPDATE_MOVE_DATE) {
    dispatch(
      updateMovePlan({
        move_plan: {
          move_date: action.payload.move_date
        }
      })
    )
  }
  if (action.type === CHECK_VALID_DATES) {
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/valid_dates`,
        method: 'GET',
        params: {
          move_date: action.payload
        },
        fromAction: CHECK_VALID_DATES
      })
    );
  }
  if (action.type === CHECK_MOVER_AVAILABILITY) {
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/for_move_dates`,
        method: 'GET',
        params: action.payload,
        fromAction: CHECK_MOVER_AVAILABILITY
      })
    );
  }
  if (action.type === PREPARE_RESCHEDULE_REQUEST) {
    dispatch(checkValidDates)
  }
  if (action.type === EMAIL_PLAN) {
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/email`,
        method: 'POST',
        params: { email: action.payload },
        fromAction: EMAIL_PLAN
      })
    );
  }

  if (action.type === ADDRESS_UPDATE) {
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}`,
        method: 'PATCH',
        params: {
          address_update: true,
          move_plan: {
            ...getState().movePlan.currentPlan.details,
            [action.payload.addressKey]: action.payload.route
          }
        },
        fromAction: ADDRESS_UPDATE
      })
    )
  }

  if (action.type === REQUEST_INSURANCE) {
    dispatch(setPlanUpdating(true));
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/request_insurance`,
        method: 'POST',
        fromAction: REQUEST_INSURANCE
      })
    )
  }

  if(action.type === CANCEL_MOVE) {
   // TODO: add loader here in the context of 'compare'
   dispatch(
     apiRequest({
       url: `api/v3/move_plans/${getState().movePlan.currentMPID}/cancel_job`,
       method: 'POST',
       fromAction: CANCEL_MOVE
     })
   )
  }

  if(action.type === UPDATE_COI) {
    dispatch(setMoveplanLoading(true));
    const details = getState().movePlan.currentPlan.details;
    let updateParams = {
      address_update: true,
      move_plan: {
        ...details,
        pick_up: {
          ...details.pick_up,
          certificate_of_insurance_required: action.payload.pick_up.certificate_of_insurance_required,
          management_contact_name: action.payload.pick_up.certificate_of_insurance_required
            ? action.payload.pick_up.management_contact_name : null,
          management_phone_number: action.payload.pick_up.certificate_of_insurance_required
            ? action.payload.pick_up.management_phone_number : null
        },
        drop_off: {
          ...details.drop_off,
          certificate_of_insurance_required: action.payload.drop_off.certificate_of_insurance_required,
          management_contact_name: action.payload.drop_off.certificate_of_insurance_required
            ? action.payload.drop_off.management_contact_name : null,
          management_phone_number: action.payload.drop_off.certificate_of_insurance_required
            ? action.payload.drop_off.management_phone_number : null
        },
        extra_pick_up: {
          ...details.extra_pick_up,
          certificate_of_insurance_required: action.payload.extra_pick_up.certificate_of_insurance_required,
          management_contact_name: action.payload.extra_pick_up.certificate_of_insurance_required
            ? action.payload.extra_pick_up.management_contact_name : null,
          management_phone_number: action.payload.extra_pick_up.certificate_of_insurance_required
            ? action.payload.extra_pick_up.management_phone_number : null
        },
        extra_drop_off: {
          ...details.extra_drop_off,
          certificate_of_insurance_required: action.payload.extra_drop_off.certificate_of_insurance_required,
          management_contact_name: action.payload.extra_drop_off.certificate_of_insurance_required
            ? action.payload.extra_drop_off.management_contact_name : null,
          management_phone_number: action.payload.extra_drop_off.certificate_of_insurance_required
            ? action.payload.extra_drop_off.management_phone_number : null
        }
      }
    };
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}`,
        method: 'PATCH',
        params: updateParams,
        fromAction: UPDATE_COI,
        redirect: action.meta.redirect
      })
    )
  }

  if(action.type === SUBMIT_MOVE_DAY_FEEDBACK) {
    // TODO: add loader here in the context of 'compare'
    dispatch(setMoveplanLoading(true));
    dispatch(
      apiRequest({
        url: `api/v3/job_feedbacks/move_day_sms`,
        method: 'POST',
        params: {
          uuid: action.payload.uuid,
          move_ok: action.payload.move_ok,
          move_day_feedback: action.payload.move_day_feedback
        },
        fromAction: SUBMIT_MOVE_DAY_FEEDBACK
      })
    )
   }

  if(action.type === UPDATE_ELEVATORS) {
    dispatch(setInventoryLoading(true));
    const details = getState().movePlan.currentPlan.details;
    let updateParams = {
      address_update: true,
      move_plan: {
        ...details,
        pick_up: {
          ...details.pick_up,
          elevator_start_time: action.payload.pick_up.elevator_start_time,
          elevator_end_time: action.payload.pick_up.elevator_end_time
        },
        drop_off: {
          ...details.drop_off,
          elevator_start_time: action.payload.drop_off.elevator_start_time,
          elevator_end_time: action.payload.drop_off.elevator_end_time
        },
        extra_pick_up: {
          ...details.extra_pick_up,
          elevator_start_time: action.payload.extra_pick_up.elevator_start_time,
          elevator_end_time: action.payload.extra_pick_up.elevator_end_time
        },
        extra_drop_off: {
          ...details.extra_drop_off,
          elevator_start_time: action.payload.extra_drop_off.elevator_start_time,
          elevator_end_time: action.payload.extra_drop_off.elevator_end_time
        }
      }
    };
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}`,
        method: 'PATCH',
        params: updateParams,
        fromAction: UPDATE_ELEVATORS
      })
    )
  }
  if(action.type === COMPLETE_CONFIRMATION_STEP || action.type === SKIP_CONFIRMATION_STEP) {
    dispatch(updateConfirmationFunnel());
  }
  if(action.type === COMPLETE_HOURLY_CONFIRMATION_STEP || action.type === SKIP_HOURLY_CONFIRMATION_STEP) {
    dispatch(updateHourlyConfirmationFunnel());
  }
  if( action.type === UPDATE_CONFIRMATION_FUNNEL) {
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/confirmation_submit`,
        method: 'POST',
        params: getState().funnel.confirmationSteps,
        fromAction: UPDATE_CONFIRMATION_FUNNEL
      })
    );
  }
  if( action.type === UPDATE_HOURLY_CONFIRMATION_FUNNEL) {
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/confirmation_submit`,
        method: 'POST',
        params: getState().hourlyFunnel.confirmationSteps,
        fromAction: UPDATE_HOURLY_CONFIRMATION_FUNNEL
      })
    );
  }
  if(action.type === COMPLETE_CONFIRMATION_FUNNEL) {
    dispatch(setMoveplanLoading(true));
    dispatch(completeConfirmationStep(8)); 
    dispatch(completeConfirmationStep(9));     
    
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/confirmation_submit`,
        method: 'POST',
        params: getState().funnel.confirmationSteps,
        fromAction: COMPLETE_CONFIRMATION_FUNNEL
      })
    );
  }
  if(action.type === COMPLETE_HOURLY_CONFIRMATION_FUNNEL) {
    dispatch(setMoveplanLoading(true));
    dispatch(completeHourlyConfirmationStep(5)); 
    dispatch(completeHourlyConfirmationStep(6)); 
        
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/confirmation_submit`,
        method: 'POST',
        params: getState().hourlyFunnel.confirmationSteps,
        fromAction: COMPLETE_HOURLY_CONFIRMATION_FUNNEL
      })
    );
  }
  if(action.type === SUBMIT_DNA) {
    if(getState().ui.mobile.isMobile) action.payload.source = 'Unpakt';
    dispatch(setMoveplanLoading(true));
    dispatch(track('Move plan started', {
      extra_pick_up: !!action.payload.extra_pick_up_enabled,
      extra_drop_off: !!action.payload.extra_drop_off_enabled,
      home_size: HOME_SIZES[action.payload.home_size_id].type,
      added_storage: action.payload.storage_in_transit || action.payload.move_into_storage,
      created_device_type: getState().ui.mobile.isMobile ? "mobile" : "desktop"
    }));
    if(getState().ui.mobile.isMobile) action.payload.created_device_type = "mobile"
    else action.payload.created_device_type = "desktop"
    dispatch(createMovePlan(action.payload));
    sessionStorage.setItem('addedTypicals', false);
  }

  if(action.type === CONTINUE_LAST_PLAN) {
    dispatch(openModal({ name: 'loader', properties: { state: 'initial' }}));
    // TODO: add funnel action to generate funnel completed states based on last step
    dispatch(getCurrentMoveplan({ redirect: 'last_step'}));
  }

  // Hourly
  if (action.type === UPDATE_TRUCK_SIZE) {
    dispatch(setHourlyUpdateLoading(true))
    dispatch(
      updateMovePlan({
        move_plan: {
          truck_size_id: action.payload
        }
      })
    )
  }

  if (action.type === UPDATE_HOURLY_STAIRS) {
    dispatch(setHourlyUpdateLoading(true))
    dispatch(
      updateMovePlan({
        move_plan: {
          num_hourly_stairs: (action.payload === 'true') ? true : false
        }
      })
    )
  }

  if (action.type === UPDATE_NUM_HOURS) {
    dispatch(setHourlyUpdateLoading(true))
    dispatch(
      updateMovePlan({
        move_plan: {
          num_hours: action.payload
        }
      })
    )
  }

  if (action.type === UPDATE_NUM_LABORERS) {
    dispatch(setHourlyUpdateLoading(true))
    dispatch(
      updateMovePlan({
        move_plan: {
          num_hourly_laborers: action.payload
        }
      })
    )
  }

  if (action.type === UPDATE_NUM_UPRIGHTS) {
    dispatch(setHourlyUpdateLoading(true))
    dispatch(
      updateMovePlan({
        move_plan: {
          num_hourly_upright_pianos: action.payload
        }
      })
    )
  }

  if (action.type === UPDATE_NUM_BABY_GRAND) {
    dispatch(setHourlyUpdateLoading(true))
    dispatch(
      updateMovePlan({
        move_plan: {
          num_hourly_baby_grand_pianos: action.payload
        }
      })
    )
  }

  if (action.type === UPDATE_NUM_HOURLY_OVERWEIGHT) {
    dispatch(setHourlyUpdateLoading(true))
    dispatch(
      updateMovePlan({
        move_plan: {
          num_hourly_overweight: action.payload
        }
      })
    )
  }

  if (action.type === UPDATE_NUM_HOURLY_OVERSIZE) {
    dispatch(setHourlyUpdateLoading(true))
    dispatch(
      updateMovePlan({
        move_plan: {
          num_hourly_oversize: action.payload
        }
      })
    )
  }

  if (action.type === NETWORK_SUCCESS) {
    switch (action.meta.originalRequest.fromAction) {
      case CREATE_MOVEPLAN:
        dispatch(setCurrentMoveplanID(action.payload.response.data.uuid));
        dispatch(setInventoryLoading(false));
        if(getState().ui.mobile.isMobile) window.location.href = `${process.env.REACT_APP_MOBILE_FUNNEL_URL}/moving/${action.payload.response.data.uuid}/overview`;
        else window.location.href = `${process.env.REACT_APP_MARKETING_URL}/moving/${action.payload.response.data.uuid}/inventory`;
        break;
      case UPDATE_MOVE_PLAN:        
        dispatch(setCurrentPlanInfo(action.payload.response.data.plan));
        dispatch(getValidDates());

        if(!!action.meta.originalRequest.redirect) dispatch(push(action.meta.originalRequest.redirect))
        dispatch(setHourlyUpdateLoading(false))
        dispatch(setMoveplanLoading(false));
        break;
      case GET_CURRENT_MOVEPLAN:
        dispatch(setCurrentPlanInfo(action.payload.response.data.plan));
        dispatch(getValidDates());
        if(getState().funnel.currentStep === 'DETAILS') {
          dispatch(setDetailsLoading(false));
        }
        if(has(getState().movePlan.currentPlan, 'mover') && getState().funnel.currentStep === 'INVENTORY') {
          dispatch(setInventoryLoading(false));
        }
        dispatch(setMoveplanLoading(false));
        break;
      case GET_MOVEPLAN:
        dispatch(setCurrentPlanInfo(action.payload.response.data.plan));
        dispatch(getValidDates());
        break;
      case GET_ALL_MOVEPLANS:
        dispatch(setAllMoveplans(action.payload.response.data));
        dispatch(setMoveplanLoading(false));
        if(action.meta.originalRequest.redirect !== {}) dispatch(push(action.meta.originalRequest.redirect));
        break;
      case GET_VALID_DATES:
        dispatch(setValidDates(action.payload.response.data));
        dispatch(setMoveplanLoading(false));
        break;
      case UPDATE_PACKING_SERVICES:
        if((action.meta.originalRequest.params.follow_up_packing_service_id === "1"
          || action.meta.originalRequest.params.follow_up_packing_service_id === "2")
          && hasBoxPurchases(getState().movePlan)){
          dispatch(updateBoxPurchases(null,null));
        } else {
          dispatch(getCurrentMoveplan());
        }
        break;
      case CHECK_VALID_DATES:
        dispatch(setValidDates(action.payload.response.data));
        break;
      case CHECK_MOVER_AVAILABILITY:
        console.log(action.payload.response.data);
        let date = Object.keys(action.payload.response.data)[0];
        let params = action.meta.originalRequest.payload;
        params.validDates = getState().movePlan.validDates;
        params.price = action.payload.response[date];
        dispatch(openModal({ name: 'requestReschedule', properties: params}));
        break;
      case EMAIL_PLAN:
        dispatch(setEmailError(false));
        dispatch(setEmailSent(true));
        break;
      case ADDRESS_UPDATE:
        console.log(action.payload.response.data);
        dispatch(setCurrentPlanInfo(action.payload.response.data.plan));
        break;
      case REQUEST_INSURANCE:
        dispatch(setMoveplanInsurance(true));
        dispatch(setPlanUpdating(false));
        break;
      case CANCEL_MOVE:
        dispatch(getCurrentMoveplan());
        if(getState().funnel.currentStep === 'BOOK' || getState().funnel.currentView === 'plan') {
          dispatch(push(`/moving/${getState().movePlan.currentMPID}/compare`));
        }
        break;
      case UPDATE_BOX_PURCHASES:
        if(action.meta.originalRequest.params.box_delivery_date === null) {
          dispatch(clearBoxPurchases())
        }
        dispatch(setCurrentPlanInfo(action.payload.response.data.plan));
        break;
      case UPDATE_COI:
        dispatch(setCurrentPlanInfo(action.payload.response.data.plan));
        dispatch(setInventoryLoading(false));
        dispatch(push('/2/coi'))
        break;
      case UPDATE_ELEVATORS:
        dispatch(setInventoryLoading(false))
        dispatch(setCurrentPlanInfo(action.payload.response.data.plan));
        dispatch(push('/2/elevator'))
        break; 
      case SUBMIT_MOVE_DAY_FEEDBACK:
        dispatch(setSubmitFeedbackResponse ({ error: false, response: 'We\'ve received your response.'}));
        dispatch(setMoveplanLoading(false));
        break;
      case COMPLETE_CONFIRMATION_FUNNEL:
        dispatch(track('USER FINISHED CONFIRMATION FUNNEL', getState().funnel.confirmationSteps));
        dispatch(closeModal());
        dispatch(push('/completed'));
        dispatch(setMoveplanLoading(false));
        break;
      case COMPLETE_HOURLY_CONFIRMATION_FUNNEL:
          dispatch(track('USER FINISHED HOURLY CONFIRMATION FUNNEL', getState().hourlyFunnel.confirmationSteps));
          dispatch(closeModal());
          dispatch(push('/completed'));
          dispatch(setMoveplanLoading(false));
          break;
      default:
        break;
    }
  }

  if (action.type === NETWORK_FAILURE) {
    switch (action.meta.originalRequest.fromAction) {
      case EMAIL_PLAN:
        dispatch(setEmailError(true));
        dispatch(setEmailSent(false));
        break;
      case REQUEST_INSURANCE:
        dispatch(setPlanUpdating(false));
        alert('there was a problem adding insurance to this move plan');
        break;
      case CREATE_MOVEPLAN:
        dispatch(setInventoryLoading(false));
        console.log(action.payload.response.data)
        alert('Whoops! we weren\'t able to create your plan. Refresh and try again')
        break;
      case SUBMIT_MOVE_DAY_FEEDBACK:
        dispatch(setSubmitFeedbackResponse({ error: true, response: action.payload.response.response.data.error}));
        console.log('😳 Oopsies...  ', action.payload.response.response.data)
        dispatch(setMoveplanLoading(false));
        break;
      case UPDATE_MOVE_PLAN:
        if(action.payload.response.response.status === 422) dispatch(setMoveplanApiError('The mover you\'ve selected does not support this address.'));
        else dispatch(setMoveplanApiError('Something went wrong. Please reload and try again.'));
        dispatch(setMoveplanLoading(false));
        break;
      case GET_CURRENT_MOVEPLAN:
        if(action.payload.response.response.status === 401) {
          dispatch(unsetUser());
        }
        break;
      default:
        break;
    }

  }
};

export default movePlanMiddleware;
