import {OPEN_MODAL} from '../actionTypes';
import {fetchInventory} from "../actions/inventory";

const modalsMiddleware = ({ getState, dispatch }) => next => action => {
  next(action);

  if (action.type === OPEN_MODAL) {
    if(action.payload.modal.name === 'specialHandling') {
      dispatch(fetchInventory(getState().movePlan.currentMPID));
    }
    if(action.payload.modal.name === 'consultationInHome') {
      console.log('in-home')
    }
    if(action.payload.modal.name === 'consultationPhone') {
      console.log('phone')
    }
  }
};

export default modalsMiddleware;
