import React from 'react';
import {connect} from 'react-redux';
import {closeModal} from "../../../redux/actions/modals";
import { Container, Row } from 'reactstrap';
import { completeHourlyConfirmationFunnel } from '../../../redux/actions/movePlan';
import { truckSizeOptions } from '../../../constants'
import { keepOneDecimals, keepTwoDecimals } from '../../../redux/reducers/movePlan'

const mapStateToProps = ({movePlan}) => ({
  prices: movePlan.currentPlan.pricing,
  laborers: movePlan.currentPlan.num_hourly_laborers,
  hours: movePlan.currentPlan.num_hours,
  truckSize: movePlan.currentPlan.hourly_plan? truckSizeOptions.find(item => item.value === movePlan.currentPlan.truck_size_id).label : '',
  stairs: movePlan.currentPlan.hourly_stairs,

  num_hourly_upright_pianos: movePlan.currentPlan.num_hourly_upright_pianos,
  num_hourly_baby_grand_pianos: movePlan.currentPlan.num_hourly_baby_grand_pianos,
  num_hourly_overweight: movePlan.currentPlan.num_hourly_overweight,
  num_hourly_oversize: movePlan.currentPlan.num_hourly_oversize,
});


const ConfirmCompleteHourly = ({ 
  prices, closeModal, completeHourlyConfirmationFunnel, laborers, hours, truckSize, stairs,
  num_hourly_upright_pianos, num_hourly_baby_grand_pianos, num_hourly_overweight, num_hourly_oversize
}) => (
  <>
    <header className="top-action-panel">
      <button className="close-reveal-modal" onClick={closeModal}>✖</button>
    </header>
    <div className="modal-body">
      <Container>
        <Row className="m-auto mb-5 text-center">
          <div className="confirm-send-off">
            <p>You're all set! </p>
            <p className="gray-subtitle">
            Here is your latest pricing breakdown.
            Unpakt looks forward to moving you!
            </p>
          </div>
        </Row>
        
        <Row className="d-flex justify-content-between price-line mt-5 pt-3">
          <div className="col-8 pl-0">
            <p><b>Movers</b></p>
          </div>
          <div className="col-4 text-right pr-0">
            <p>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(prices.moving_cost)}</p>
          </div>
          <p className="desc m-0">Includes { laborers } movers for { hours } hours (${keepTwoDecimals(prices.moving_cost/hours)} per hour of mover after)</p>
        </Row>
        
        <Row className="d-flex justify-content-between price-line">
          <div className="col-8 pl-0">
            <p><b>Truck Travel</b></p>
          </div>
          <div className="col-4 text-right pr-0">
            <p>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(prices.hourly_truck_cost)}</p>
          </div>
          <p className="desc m-0">Includes all travel to your location and truck ({truckSize})</p>
        </Row>

        <Row className="d-flex justify-content-between price-line">
          <div className="col-8 pl-0">
            <p><b>Estimated travel between addresses</b></p>
          </div>
          <div className="col-4 text-right pr-0">
            <p>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(prices.travel_cost - prices.hourly_truck_cost)}</p>
          </div>
          <p className="desc m-0">Includes { laborers } movers for {keepOneDecimals(prices.estimated_travel_minutes/60)} hours</p>
        </Row>

        { stairs &&
          <Row className="d-flex justify-content-between price-line">
            <div className="col-8 pl-0">
              <p><b>Stairs</b></p>
            </div>
            <div className="col-4 text-right pr-0">
              <p>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(prices.hourly_stairs_cost)}</p>
            </div>
            <p className="desc m-0">Cost based on selected stairs option</p>
          </Row>
        }

        { num_hourly_overweight &&
          <Row className="d-flex justify-content-between price-line">
            <div className="col-8 pl-0">
              <p><b>Items over 150 pounds</b></p>
            </div>
            <div className="col-4 text-right pr-0">
              <p>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(prices.hourly_overweight_cost)}</p>
            </div>
            <p className="desc m-0">Cost based on selected {num_hourly_overweight} items over 150 pounds</p>
          </Row>
        }

        { num_hourly_oversize &&
          <Row className="d-flex justify-content-between price-line">
            <div className="col-8 pl-0">
              <p><b>Items over 10 feet</b></p>
            </div>
            <div className="col-4 text-right pr-0">
              <p>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(prices.hourly_oversize_cost)}</p>
            </div>
            <p className="desc m-0">Cost based on selected {num_hourly_oversize} items over 10 feet</p>
          </Row>
        }

        { num_hourly_upright_pianos &&
          <Row className="d-flex justify-content-between price-line">
            <div className="col-8 pl-0">
              <p><b>Upright Pianos</b></p>
            </div>
            <div className="col-4 text-right pr-0">
              <p>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(prices.hourly_upright_piano_cost)}</p>
            </div>
            <p className="desc m-0">Cost based on selected {num_hourly_upright_pianos} upright pianos</p>
          </Row>
        }

        { num_hourly_baby_grand_pianos &&
          <Row className="d-flex justify-content-between price-line">
            <div className="col-8 pl-0">
              <p><b>Baby Grand Pianos</b></p>
            </div>
            <div className="col-4 text-right pr-0">
              <p>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(prices.hourly_grand_piano_cost)}</p>
            </div>
            <p className="desc m-0">Cost based on selected {num_hourly_baby_grand_pianos} baby grand pianos</p>
          </Row>
        }

        { prices.mover_special_discount !== 0 &&
        <Row className="d-flex justify-content-between price-line">
          <div className="col-8 pl-0">
            <p><b>Mover Special { prices.mover_special_percentage !== 0 && <span> ({prices.mover_special_percentage}% off)</span> }</b></p>
          </div>
          <div className="col-4 text-right pr-0">
            <p>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(prices.mover_special_discount)}</p>
          </div>
        </Row>
        }

        { (prices.discount_without_social_sharing + prices.facebook_social_discount + prices.twitter_social_discount) !== 0 &&
        <Row className="d-flex justify-content-between price-line">
          <div className="col-8 pl-0">
            <p><b>Other Discounts</b></p>
          </div>
          <div className="col-4 text-right pr-0">
            <p>{(prices.discount_without_social_sharing + prices.facebook_social_discount + prices.twitter_social_discount)}</p>
          </div>
        </Row>
        }

        <Row className="d-flex justify-content-between mt-5 mb-5">
          <div className="col-7">
            <h3><b>GRAND TOTAL</b></h3>
          </div>
          <div className="col-5 text-right">
            <h3><b>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(prices.total_cost)}</b></h3>
          </div>
        </Row>

      </Container>

      <div className="d-flex align-items-center justify-content-center w-100 flex-wrap-reverse">
        <span className="p-2">
          <button className="gray-pill-button mt-0 m-4" onClick={ closeModal }>GO BACK</button>
        </span>
          <button className="green-pill-button m-4 mt-0" onClick={ completeHourlyConfirmationFunnel }>ALL DONE!</button>
      </div>
    </div>


    <style jsx>
      {`
        p {
          line-height: 1;
          margin: 0
        }
        :global(.price-line) {
          margin-top: 1rem;
          padding-bottom: 8px;
          border-width: 0 0 1px 0;
          margin-bottom: 0px;
          border-style: solid;
          border-image: linear-gradient( to left, rgba(0,0,0,0), #3fbee8, rgba(0, 0, 0, 0) ) 1 0%;
        }
        :global(.price-line) b {
          font-weight: 200;
        }
        h3 {
            font-size: 1.8rem;
            font-weight: 800;
            color: #47BFE6;
        }
        .green {
          color: #2FC468
        }
        .confirm-send-off p{
          margin: 0 auto;
          line-height: 1.4
        }
        .desc {
          font-size: 11px;
          color: #737677;
          line-height: 16px;
          min-height: 16px;
        }
        @media screen and (max-width: 420px) {
          .gray-pill-button {
            display: none;
          }
        }

      `}
    </style>
  </>
)


export default connect(mapStateToProps, {closeModal, completeHourlyConfirmationFunnel})(ConfirmCompleteHourly);
