import React from "react";
import {closeAllModals} from "../../../redux/actions/modals";
import {connect} from "react-redux";


class Backdrop extends React.Component {
  render() {
    return (
      <div className="modal-background" onClick={() => this.props.closeAllModals()}>
        {/*language=CSS*/}
        <style jsx> {`
          .modal-background {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 10000;
            background: rgb(26, 55, 73);
            opacity: .65;
          }
        `}</style>
      </div>
    )
  }
}
const mapStateToProps = () => ({
});
export default connect(mapStateToProps,{closeAllModals})(Backdrop);
