import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import {
  signIn,
  setErrorMessage,
  setAuthSubmitting
} from "../../redux/actions/auth";
import { isLoggedIn } from "../../redux/reducers/auth";
import { Container, Row } from "reactstrap";
import ReactLoading from "react-loading";
import SocialSection from "./SocialSection";

const mapStateToProps = ({ auth, movePlan, funnel }) => ({
  apiErrorText: auth.authErrorText,
  submitting: auth.submitting,
  isLoggedIn: isLoggedIn(auth),
  userEmail: isLoggedIn(auth) ? auth.user.email : null,
  lastMPID: movePlan.lastMPID,
  previousLocation: funnel.previousLocation
});

const SignIn = props => {
  useEffect(() => {
    props.setErrorMessage("");
    props.setAuthSubmitting(false);
  }, []);

  return (
    <div className="with-header">
      <div className="confirmation-flex-container">
        <Formik
          initialValues={{
            email: "",
            password: "",
            showPassword: false
          }}
          validateOnChange={false}
          validateOnBlur={false}
          validate={values => {
            let errors = {};
            if (!values.email) {
              errors.email = "Email is required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
            ) {
              errors.email = `"${values.email}" is not a valid email address.`;
            }
            if (!values.password) {
              errors.password = "Password is required";
            }
            console.log(errors.email);
            return errors;
          }}
          handleSubmit={e => e.preventDefault()}
          onSubmit={(values, { setSubmitting }) => {
            // Ternary is for expired sessions
            props.signIn(
              values,
              !!props.previousLocation
                ? props.previousLocation
                : props.destination 
                ? `${props.destination}`
                : "/"
            );
            setSubmitting(false);
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setErrors
          }) => (
            <div className="confirm-step-container mt-5">
              <div className="confirm-header">
                <h1>Sign In</h1>
                <p>Please sign in to your Unpakt account to proceed.</p>
              </div>
              <div>
                <form onSubmit={handleSubmit} noValidate>
                  <div className="confirm-interior pl-0 pr-0">
                    <Container>
                      <Row>
                        <div className="col-12 pt-5">
                          <Container>
                            <Row>
                              <div className="col-12 mb-1">
                                <span>EMAIL</span>
                              </div>
                              <div className="col-12 mb-3">
                                <input
                                  type="email"
                                  className={`edit-input ${
                                    touched.email && errors.email
                                      ? "input-error"
                                      : ""
                                  }`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  onFocus={() => {
                                    setErrors({});
                                    props.setErrorMessage("");
                                  }}
                                  value={values.email}
                                  name="email"
                                  placeholder="Email"
                                  autoComplete="email"
                                  required
                                />
                              </div>
                              {errors.email && touched.email && (
                                <div className="col-12 col-sm-8 offset-sm-2 mb-2">
                                  <div className="well error-well mt-n2">
                                    <p className="f-s-xs-14 text-center mt-0 mb-0">
                                      {errors.email}
                                    </p>
                                  </div>
                                </div>
                              )}

                              <div className="col-12 mb-1">
                                <span>PASSWORD</span>
                              </div>
                              <div className="col-12 required-input-container mb-4">
                                <input
                                  type={
                                    values.showPassword ? "text" : "password"
                                  }
                                  className={`edit-input ${
                                    touched.password && errors.password
                                      ? "input-error"
                                      : ""
                                  }`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  onFocus={() => {
                                    setErrors({});
                                    props.setErrorMessage("");
                                  }}
                                  value={values.password}
                                  name="password"
                                  placeholder="Password"
                                  autoComplete="new-password"
                                  required
                                />
                              </div>
                              {errors.password && touched.password && (
                                <div className="col-12 col-sm-8 offset-sm-2 mb-2">
                                  <div className="well error-well mt-n2">
                                    <p className="f-s-xs-14 text-center mt-0 mb-0">
                                      {errors.password}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </Row>
                            <Row className="mt-3">
                              <div className="col-8 forgot-password">
                                <Link
                                  className="text-blue font-semibold pl-1"
                                  to="/forgotpassword"
                                >
                                  Forgot Password?
                                </Link>
                              </div>
                              <div className="col-4 show-checkbox">
                                <div className="unpakt-checkbox-container">
                                  <label className="align-top pr-3">Show</label>
                                  <input
                                    type="checkbox"
                                    className="unpakt-checkbox"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.showPassword}
                                    name="showPassword"
                                  />
                                </div>
                              </div>
                            </Row>
                          </Container>
                        </div>
                      </Row>

                      {!!props.apiErrorText && (
                        <Row>
                          <div className="col-xs-12 col-sm-8 offset-sm-2  ">
                            <hr />
                            <div className="well error-well p-t-5 p-b-5 ">
                              <p className="f-s-xs-14 text-center mt-0 mb-0">
                                {props.apiErrorText}
                              </p>
                            </div>
                          </div>
                        </Row>
                      )}
                    </Container>
                  </div>
                  <SocialSection />
                  <div className="confirmation-actions-save mt-3">
                    <button
                      type="submit"
                      disabled={props.submitting}
                      onClick={handleSubmit}
                      className="green-long-button"
                    >
                      {props.submitting ? (
                        <ReactLoading
                          className="button-spinner"
                          type={"spinningBubbles"}
                          color={"#ffffff"}
                          height={30}
                          width={30}
                        />
                      ) : (
                        <span>SUBMIT</span>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        />
      </div>
      <style jsx>{`
        input {
          font-size: 1.6rem;
        }
        .with-header {
          margin-top: 70px;
        }
        .show-checkbox {
          align-items: end;
        }
        .forgot-password {
          line-height: 20px;
          font-size: 1.6rem;
        }
        .forgot-password > a {
          color: #40bfe9 !important;
        }
        .unpakt-checkbox-container {
          display: flex;
          justify-content: flex-end;
        }
        .unpakt-checkbox-container > label {
          line-height: 20px;
          font-size: 1.6rem;
        }
        .green-long-button {
          position: relative;
        }
        input[type="checkbox"] {
          display: flex;
          -webkit-appearance: none;
          -moz-appearance: none;
          background: white;
          cursor: pointer;
          border-radius: 0;
          border: solid 1px #40bee9;
          padding: 7px;
          height: 20px;
          width: 20px;
        }

        input[type="checkbox"]:checked {
          background: url(/assets/blue_checkmark.svg) no-repeat center center;
        }

        .error-well p {
          color: red;
          font-size: 14px;
        }
      `}</style>
    </div>
  );
};

export default connect(
  mapStateToProps,
  { signIn, setErrorMessage, setAuthSubmitting }
)(SignIn);
