import React, { useEffect, useRef, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Link } from "react-router-dom";
import { checkIsMobile } from '../../redux/actions/ui/mobile';
import { isLoggedIn } from '../../redux/reducers/auth';
import { checkCookies, checkToken } from './../../redux/actions/auth';
import SignIn from '../auth/SignIn';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ConfirmationProgress from './../common/ConfirmationProgress';
import Header from './../common/Header';
import { FUNNEL_PATHS, HOURLY_FUNNEL_PATHS } from '../../constants'
import { skipConfirmationStep } from '../../redux/actions/funnel';
import { skipHourlyConfirmationStep } from "../../redux/actions/hourlyFunnel"
import { useScrollTop } from '../common/Hooks';
// import { useCookies } from 'react-cookie';

const AsyncLanding = lazy(() => import ("../Landing"));
const AsyncBasics = lazy(() => import ("../1_Basics/Basics"));
const AsyncBasicsEdit = lazy(() => import ("../1_Basics/BasicsEdit"));
const AsyncCoi = lazy(() => import ("../2_Building/Coi"));
const AsyncCoiEdit = lazy(() => import ("../2_Building/CoiEdit"));
const AsyncElevator = lazy(() => import ("../2_Building/ElevatorRestrictions"));
const AsyncElevatorEdit = lazy(() => import ("../2_Building/ElevatorEdit"));
const AsyncCompleted = lazy(() => import ("../Completed"));
const AsyncSpecialHandling = lazy(() => import ("../3_Services/SpecialHandling"));
const AsyncSpecialHandlingEdit = lazy(() => import ("../3_Services/SpecialHandlingEdit"));
const AsyncPackingAndBoxes = lazy(() => import ("../3_Services/PackingAndBoxes"));
const AsyncPackingAndBoxesEdit = lazy(() => import ("../3_Services/PackingAndBoxesEdit"));
const AsyncCoverageAndInsurance= lazy(() => import ("../3_Services/CoverageAndInsurance"));
const AsyncYourInventory = lazy(() => import ("../4_Move_Day/YourInventory"));
const AsyncInventoryEdit = lazy(() => import ("../4_Move_Day/InventoryEdit"));
const AsyncMoveDayPaperwork = lazy(() => import ("../4_Move_Day/MoveDayPaperwork"));
const AsyncPayment = lazy(() => import ("../4_Move_Day/Payment"));
const AsyncMoveDayCheckIn = lazy(() => import ("../MoveDayCheckIn"));
// const AsyncPageNotFound = lazy(() => import ("../common/PageNotFound"));
const AsyncForgotPassword = lazy(() => import ("../auth/ForgotPassword"));

const mapStateToProps = ({auth, router, funnel, hourlyFunnel, movePlan}) => ({
  isLoggedIn: isLoggedIn(auth),
  location: router.location,
  animationClass: funnel.animationClass,
  activeStep: movePlan.currentPlan.hourly_plan? hourlyFunnel.currentConfirmationStep : funnel.currentConfirmationStep,
  currentIndex: movePlan.currentPlan.hourly_plan? hourlyFunnel.currentIndex : funnel.currentIndex,
  hourly: movePlan.currentPlan.hourly_plan
});

const Loading = () => <div className="confirmation-view"></div>

const MainConfirmation = ({isLoggedIn, location, checkToken, checkIsMobile, animationClass, activeStep, currentIndex, hourlyCurrentIndex, skipConfirmationStep, skipHourlyConfirmationStep, checkCookies, hourly}) => {
  // const [cookies, setCookie] = useCookies(['react_funnel_utm_params']);
  let wrapperRef = useRef(null);

  useEffect(() => {
    checkToken();
    checkIsMobile();
    //checkCookies(cookies);
  }, []); 
  useScrollTop(wrapperRef);
  let hideExtras = location.pathname === "/" || location.pathname.includes("/checkin") || location.pathname.includes("/completed");
  let isEditPage = location.pathname.includes("/edit")
  return !isLoggedIn 
    ? <div className="confirmation-content">
        <Header/>
        <div ref={wrapperRef} className="confirmation-flex-container">
          <Switch>
            <Route exact path="/forgotpassword" render={() => <Suspense fallback={<Loading/>}><AsyncForgotPassword/></Suspense>}/>
            <Route exact path="/checkin" render={() => <Suspense fallback={<Loading/>}><AsyncMoveDayCheckIn/></Suspense>}/>
            <Route component={SignIn}/>
          </Switch>
        </div>
      </div>
    : <div className="confirmation-content">
      <Header/>
      <div ref={wrapperRef} className="view-wrapper">
        <div className={`confirmation-flex-container progress-slide ${ isEditPage ? 'progress-slide-up' : 'progress-slide-down'}`}>
          { !hideExtras && <ConfirmationProgress />}
        </div>
        { !hideExtras && 
          <div className={isEditPage && "hide-panels"}>
            <Link className="go-back" to={ hourly? `${HOURLY_FUNNEL_PATHS[currentIndex - 1]}` : `${FUNNEL_PATHS[currentIndex - 1]}`} >               
              <svg className="arrows">
                <use xlinkHref="#left-arrow"/>
              </svg> 
            </Link>

            {(hourly && HOURLY_FUNNEL_PATHS[currentIndex + 1]) || (!hourly && FUNNEL_PATHS[currentIndex + 1])? 
            <Link className="go-forward" to={ hourly? `${HOURLY_FUNNEL_PATHS[currentIndex + 1]}` : `${FUNNEL_PATHS[currentIndex + 1]}`} onClick={() => hourly? skipHourlyConfirmationStep(activeStep) : skipConfirmationStep(activeStep)} >     
              <svg className="arrows">
                <use xlinkHref="#left-arrow"/>
              </svg> 
            </Link> 
            :
            ''
            }
          </div>
        }
        <TransitionGroup className="transition-group">
          <CSSTransition key={location.pathname} timeout={{ enter: 400, exit: 400 }} classNames={animationClass}>
            <section className="route-section">
              <Switch location={location}>
                <Route exact path="/" render={() => <Suspense fallback={<Loading/>}><AsyncLanding/></Suspense>}/>                
                <Route exact path="/completed" render={() => <Suspense fallback={<Loading/>}><AsyncCompleted/></Suspense>}/>
                <Route exact path="/1/basics" render={() => <Suspense fallback={<Loading/>}><AsyncBasics/></Suspense>}/>
                <Route exact path="/1/basics/edit" render={() => <Suspense fallback={<Loading/>}><AsyncBasicsEdit/></Suspense>}/>
                <Route exact path="/2/coi" render={() => <Suspense fallback={<Loading/>}><AsyncCoi/></Suspense>}/>
                <Route exact path="/2/coi/edit" render={() => <Suspense fallback={<Loading/>}><AsyncCoiEdit/></Suspense>}/>
                <Route exact path="/2/elevator" render={() => <Suspense fallback={<Loading/>}><AsyncElevator/></Suspense>}/>
                <Route exact path="/2/elevator/edit" render={() => <Suspense fallback={<Loading/>}><AsyncElevatorEdit/></Suspense>}/>

                {!hourly && (<Route exact path="/3/specialhandling" render={() => <Suspense fallback={<Loading/>}><AsyncSpecialHandling/></Suspense>}/>)}
                {!hourly && (<Route exact path="/3/specialhandling/edit" render={() => <Suspense fallback={<Loading/>}><AsyncSpecialHandlingEdit/></Suspense>}/>)}
                {!hourly && (<Route exact path="/3/packing" render={() => <Suspense fallback={<Loading/>}><AsyncPackingAndBoxes/></Suspense>}/>)}
                {!hourly && (<Route exact path="/3/packing/edit" render={() => <Suspense fallback={<Loading/>}><AsyncPackingAndBoxesEdit/></Suspense>}/>)}
                {!hourly && (<Route exact path="/3/coverage" render={() => <Suspense fallback={<Loading/>}><AsyncCoverageAndInsurance/></Suspense>}/>)}                            
                {!hourly && (<Route exact path="/4/inventory" render={() => <Suspense fallback={<Loading/>}><AsyncYourInventory/></Suspense>}/>)}
                {!hourly && (<Route exact path="/4/inventory/edit" render={() => <Suspense fallback={<Loading/>}><AsyncInventoryEdit/></Suspense>}/>)} 
                {!hourly && (<Route exact path="/4/paperwork" render={() => <Suspense fallback={<Loading/>}><AsyncMoveDayPaperwork/></Suspense>}/>)}
                {!hourly && (<Route exact path="/4/payment" render={() => <Suspense fallback={<Loading/>}><AsyncPayment/></Suspense>}/>)}

                {hourly && (<Route exact path="/3/hourly" render={() => <Suspense fallback={<Loading/>}><AsyncYourInventory/></Suspense>}/>)}
                {hourly && (<Route exact path="/3/hourly/edit" render={() => <Suspense fallback={<Loading/>}><AsyncInventoryEdit/></Suspense>}/>)}
                {hourly && (<Route exact path="/3/paperwork" render={() => <Suspense fallback={<Loading/>}><AsyncMoveDayPaperwork/></Suspense>}/>)}
                {hourly && (<Route exact path="/3/payment" render={() => <Suspense fallback={<Loading/>}><AsyncPayment/></Suspense>}/>)}                

                <Route exact path="/checkin" render={() => <Suspense fallback={<Loading/>}><AsyncMoveDayCheckIn/></Suspense>}/>
                <Route render={() => <Suspense fallback={<Loading/>}><AsyncLanding/></Suspense>}/>
                {/* <Route render={() => <Suspense fallback={<Loading/>}><AsyncPageNotFound/></Suspense>}/> */}
              </Switch>
            </section>
          </CSSTransition>
        </TransitionGroup>
      </div>
      <style jsx>{`
        .progress-slide {
          will-change: transform;
        }
        .progress-slide-down {
          transition: transform .3s linear, display 5s linear;
          transform: translate3d(0,0,0);
          display: flex;
        }
        .progress-slide-up {
          transition: transform .3s linear, display 5s linear;
          transform: translate3d(0,-100%,0);
          display: none;
        }
        .hide-panels {
          display: none;
        }
        .transition-group {
          position: relative;
        }
        .transition-group section {
          will-change: transform;
        }
        .slide-forward-enter {
          opacity: 0;
          transform: translate3d(100%, 0, 0);
          -webkit-transform: translate3d(100%, 0, 0);
        }
        .slide-forward-enter-active {
          opacity: 1;
          transition: transform .4s ease-out, opacity .4s linear;
          transform: translateZ(0);
          -webkit-transform: translateZ(0);
        }
        .slide-forward-exit {
          opacity: 1;
          transform: translateZ(0);
          -webkit-transform: translateZ(0)
        }
        .slide-forward-exit-active {
          opacity: 0;
          transform: translate3d(-100%, 0, 0);
          -webkit-transform: translate3d(-100%, 0, 0);
          transition: transform .4s ease-out, opacity .4s linear;
        }
        .slide-back-enter {
          opacity: 0.01;
          transform: translate3d(-100%, 0, 0);
          -webkit-transform: translate3d(-100%, 0, 0);
        }
        .slide-back-enter-active {
          opacity: 1;
          transition: transform .4s ease-out, opacity .4s linear;
          transform: translateZ(0);
          -webkit-transform: translateZ(0)
        }
        .slide-back-exit {
          opacity: 1;
          transform: translateZ(0);
          -webkit-transform: translateZ(0)
        }
        .slide-back-exit-active {
          opacity: 0.01;
          transition: transform .4s ease-out, opacity .4s linear;
          transform: translate3d(100%, 0, 0);
          -webkit-transform: translate3d(100%, 0, 0);   
        }
        .fade-enter {
          opacity: 0.01;
        }
        .fade-enter-active {
          opacity: 1;
          transition: opacity .4s linear;
        }
        .fade-exit {
          opacity: 1;
        }
        .fade-exit-active {
          opacity: 0.01;
          transition: opacity .4s linear;
        }
        .arrows {
          fill: #12beeb;
          height: 15px;
          width: 9px;
        }

      `}</style>
    </div>
}


export default connect(mapStateToProps, {checkToken, checkIsMobile, checkCookies, skipConfirmationStep, skipHourlyConfirmationStep})(MainConfirmation);
