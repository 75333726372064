import {
  CHECK_IS_MOBILE,
  SET_IS_MOBILE
} from '../../actionTypes';

export const checkIsMobile = (bool) => ({
  type: CHECK_IS_MOBILE,
  payload: bool
});

export const setIsMobile = (bool) => ({
  type: SET_IS_MOBILE,
  payload: bool
});
