import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {INVENTORY_ROOMS} from "../../../constants";
import cx from "classnames";
import {getTotalCounts} from "../../../redux/reducers/inventory";
import {addSpecialHandlingItems} from "../../../redux/actions/inventory";
import {closeModal} from "../../../redux/actions/modals";
import '../sprites.css';
class MyInventoryModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedList: {}
    }
  }
  handleSelect(item) {
    if(this.state.selectedList.hasOwnProperty(item.mp_inventory_item_id)) {
      let temp = this.state.selectedList;
      delete temp[item.mp_inventory_item_id];
      this.setState({selectedList: temp});
    } else {
      this.setState({
        selectedList: {
          ...this.state.selectedList,
          [item.mp_inventory_item_id]: item.item
        }
      })
    }
    return true;
  }
  handleSubmit() {
    this.props.addSpecialHandlingItems(this.state.selectedList);
  }
  render() {
    return (
      <Fragment>
        <header className="top-action-panel">
          <button className="close-reveal-modal" onClick={this.props.closeModal}>✖</button>
        </header>
        <div className="modal-body">
          <h3 className="title">My Inventory</h3>
          <div className="my-inventory-list">
            <p className="gray-subtitle">{ this.props.totalCount[0] } items in inventory</p>
            <div className="table-items-wrapper ">
              <ul className="table-items ">
                <li>
                  { Object.keys(this.props.inventoryItems).map( roomKey => {
                    if (Object.keys(this.props.inventoryItems[roomKey]).length > 0) {
                      return (
                        <ul key={roomKey} className="room-item">
                          <li className="table-category room-name">{INVENTORY_ROOMS[roomKey - 1] || 'OTHER ITEMS'}</li>
                          { Object.keys(this.props.inventoryItems[roomKey]).map( itemKey => {
                            if(!this.props.specialHandlingItems.hasOwnProperty(this.props.inventoryItems[roomKey][itemKey].mp_inventory_item_id)){
                              return (
                                <li key={itemKey} className="table-item table m-0" >
                                  <ul className="item-list">
                                    <li className="item-details p-0">
                                      <div className={cx('image', 'p-0', 'd-inline-block', 'inventory-breakdown-icon', `${this.props.inventoryItems[roomKey][itemKey].icon_css_class}_small`)}/>
                                        <div className="info">
                                          <span className="name">{this.props.inventoryItems[roomKey][itemKey].name}</span>
                                          <br/>
                                          <span className="dimensions">{this.props.inventoryItems[roomKey][itemKey].description}</span>
                                        </div>
                                    </li>
                                    <li className={cx('item-checkbox',
                                      {selected: this.state.selectedList.hasOwnProperty(this.props.inventoryItems[roomKey][itemKey].mp_inventory_item_id)})}>
                                      <div className="unpakt-checkbox-container">
                                        <input type="checkbox"
                                               className="unpakt-checkbox"
                                               onChange={ () => this.handleSelect({
                                                 mp_inventory_item_id: this.props.inventoryItems[roomKey][itemKey].mp_inventory_item_id,
                                                 item: this.props.inventoryItems[roomKey][itemKey]
                                               })}/>
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                              )} else return null
                            })
                          }
                        </ul>
                      )
                    } else return null
                  })}

                </li>
                { Object.keys(this.props.customItems).length > 0 &&
                  Object.keys(this.props.customItems).map( itemKey => {
                    if(!this.props.specialHandlingItems.hasOwnProperty(this.props.customItems[itemKey].mp_inventory_item_id)){
                      return (
                        <Fragment key={itemKey}>
                          <li className="table-category room-name">Custom Items</li>
                          <li className="table-item table m-0">
                            <ul className="item-list">
                              <li className="item-details p-0">
                                <div className={cx('image', 'p-0', 'd-inline-block', 'inventory-breakdown-icon',
                                  `${this.props.customItems[itemKey].icon_css_class}_small`)}/>
                                <div className="info">
                                  <span className="name">{this.props.customItems[itemKey].name}</span>
                                  <br/><span className="dimensions">{this.props.customItems[itemKey].description}</span>
                                </div>
                              </li>
                              <li className={cx('item-checkbox', {selected: this.props.customItems[itemKey].selected})}>
                                <div className="unpakt-checkbox-container">
                                  <input type="checkbox"
                                         className="unpakt-checkbox"
                                         onChange={ () => this.handleSelect({
                                           mp_inventory_item_id: this.props.customItems[itemKey].mp_inventory_item_id,
                                           item: this.props.customItems[itemKey]
                                         })}/>
                                </div>
                              </li>
                            </ul>
                          </li>
                        </Fragment>
                      )
                    } else return null
                  })
                }
              </ul>
              <div className="scrollbar"></div>
            </div>
            { this.props.totalCount[0] === 0 &&
              <div className="no-items">
                <div className="no-inventory-img"></div>
                You have not selected any items
              </div>
            }
          </div>
        </div>
        { this.props.totalCount[0] > 0 &&
          <footer className="confirmation-actions-save">
            <button className="green-long-button" onClick={() => this.handleSubmit()}>ADD ITEMS</button>
          </footer>
        }

        <style jsx>
          {`

            .table-items-wrapper {
                max-height: calc(100vh - 500px);
                border-width: 1px 0 1px 0;
                border-style: solid;
                border-color: #b2b2b2;
                overflow-y: scroll;
            }
            .table-item {
                border-bottom: 1px solid #dfdfdf;
                border-left: 4px solid transparent;
            }
            .table-item:hover {
                background: #fdfdfd;
                border-left: 4px solid #27afe7;
            }

            .title {
                font-family: "UnpaktHelvetica", sans-serif;
                font-size: 22px;
                display: block;
                margin-bottom: 6px;
                text-transform: uppercase;
            }
            .cta-button {
                display: inline-block;
                padding: 9px 20px 6px;
                font-size: 15px;
            }
            .bottom-action-panel {
              display: flex;
              justify-content: flex-end;
            }
            .my-inventory-list {
                padding-bottom: 20px;
            }
            .list-count {
                font-size: 12px;
                padding: 5px 0 10px 0;
            }

            .room-item {
              padding-bottom: 30px
            }

            .table-category {
                font-family: "ProximaNovaBold", sans-serif;
                font-size: 11px;
                text-transform: uppercase;
                padding: 4px 0;
                border-bottom: 1px solid #dfdfdf;
            }
            .item-list {
                width: 100%;
                height: 50px;
                padding: 5px 0;
                align-items: center;
                display: grid;
                grid-template-columns: 4fr 1fr;

            }
            .item-details {
                display: flex;

            }
            .inventory-breakdown-icon {
                background-image: url(/assets/spritesheet-small.png);
                width: 40px;
                height: 40px;
            }
            .image {
                margin-left: 6px;
                vertical-align: middle;
            }

            .info {
                width: calc(100% - 70px);
                display: inline-block;
                line-height: 14px;
                padding-left: 15px;
                vertical-align: middle;
                margin: auto;
            }
            .name {
                font-size: 13px;
            }
            .dimensions {
                font-size: 12px;
                color: #747474;
            }

            .room-name {
              font-weight: 700;
              font-size: 1.6rem;
              padding: 10px 0;
              color: #12beeb;
              align-items: center;
              display: flex;
            }
            input[type="checkbox"] {
              -webkit-appearance: none;
              -moz-appearance: none;
              background: white;
              cursor: pointer;
              border-radius: 0;
              border: solid 1px #40BEE9;
              padding: 7px;
              height: 25px;
              width: 25px;
            }
            input[type="checkbox"]:checked {
              background: url(/assets/blue_checkmark.svg) no-repeat center center;
              border: none;
            }

            .green-long-button {
              max-width: 100%
            }
            @media screen and (max-width: 40em) {
              .table-items-wrapper {
                max-height: calc(85vh - 200px);
            }
            `}
        </style>
      </Fragment>
    )
  }
}

const mapStateToProps = ({inventory}) => ({
  inventoryItems: inventory.inventoryItems,
  customItems: inventory.customItems,
  totalCount: getTotalCounts(inventory),
  specialHandlingItems: inventory.specialHandlingItems
});

export default connect(mapStateToProps,{
  addSpecialHandlingItems,
  closeModal
})(MyInventoryModal);
