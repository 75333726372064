import React, {Fragment} from 'react';
import {connect} from "react-redux";
import {closeModal} from "../../../redux/actions/modals";

const BoxesInfoModal = ({closeModal, cancel}) => (
  <Fragment>
    <header className="top-action-panel">
      <button className="close-reveal-modal"
              onClick={() => closeModal()}>✖</button>
    </header>

    <div className="modal-body">

      <div>
        <h4 className="mt-3">Through Unpakt</h4>
        <hr />

        <div className="image-content by-unpakt">
          <div>
            <h5>Add Packing Services</h5>
            <p>Boxes will be provided and professionally packed by the moving company on the day of the move.</p>
          </div>
          {/*
          <div>
            <h5 className="pt-3">Add Box Delivery</h5>
            <p>Boxes will be delivered to you a few days before your move by the moving company you chose. You can pack
              your belongings at your convenience before move day.</p>
          </div>
          */}
        </div>
      </div>

      <div className="mt-5 mb-5">
        <h4 className="mt-5">By Yourself</h4>
        <hr />

        <div className="image-content by-yourself">
          <div className="by-yourself-suggestions">
              <h5>Find Free Boxes</h5>
              <p>
                Check out our article on where to&nbsp;
                <a href="https://blog.unpakt.com/top-7-places-to-find-free-moving-boxes/"
                   target="_blank"
                   rel="noopener noreferrer"
                   className="content-link">
                  find free moving boxes.
                </a>
              </p>
            <div>
              <h5 className="pt-3">Buy Boxes</h5>
              <p>
                We’ve provided a go-to guide on where to buy&nbsp;
                <a href="https://blog.unpakt.com/ultimate-guide-buying-cheap-moving-boxes-supplies/"
                   target="_blank"
                   rel="noopener noreferrer"
                   className="content-link">
                   cheap moving supplies and boxes.
                </a>
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>


    <style jsx>
      {`
        p {
          line-height: 1.3;
        }
        .modal-header {
            border: none;
            flex-direction: column;
        }
        h4 {
          font-size: 1.6rem;
          margin-top: 10px;
          font-weight: 600
        }
        h5 {
          font-size: 1.6rem;
          font-weight: 600;
        }
        .image-content {
            padding-left: 120px;
            padding-top: 10px;
            position: relative;

        }
        .image-content:before {
          content: '';
          display: block;
          height: 100%;
          width: 120px;
          position: absolute;
          left: 0;
          background-size: cover;
        }
        .image-content.by-unpakt:before {
            background: url('/assets/byunpakt.png') no-repeat top center;
        }
        .image-content.by-yourself:before {
            background: url('/assets/byyourself.png') no-repeat top center;
        }
        .content-link {
            color: #2ABCED;
            border-bottom: 1px dashed #f7801e;
            transition: border 0.2s ease;
            text-decoration: none;
        }

        `}
    </style>
  </Fragment>
);

export default connect(null, {closeModal})(BoxesInfoModal);
