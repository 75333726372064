import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Switch } from "react-router-dom";
import Header from './../common/Header';
import { connect } from 'react-redux';
import { isLoggedIn } from '../../redux/reducers/auth';
import LeftNav from '../myhome/LeftNav';
import { useMedia } from '../common/Hooks';
import SignIn from '../auth/SignIn';
import LoadingPage from '../common/LoadingPage';
import { initMyHome } from '../../redux/actions/myHome';

const AsyncMyHome = lazy(() => import("../myhome/MyHome"));
const AsyncStartNewMove = lazy(() => import("../myhome/StartNewMove"));
const AsyncConfirmMove = lazy(() => import("../myhome/ConfirmMove"));
const AsyncAddServices = lazy(() => import("../myhome/AddServices"));
const AsyncAddSpecialHandling = lazy(() => import("../myhome/AddSpecialHandling"));
const AsyncCurrentMoveReview = lazy(() => import("../myhome/CurrentMoveReview"));
const AsyncResetPassword = lazy(() => import("../myhome/ResetPassword"));
const AsyncMovingChecklist = lazy(() => import("../myhome/MovingChecklist"));
const AsyncBenefits = lazy(() => import("../myhome/Benefits"));
const AsyncMoveHistory = lazy(() => import("../myhome/MoveHistory"));
const AsyncDealsPage = lazy(() => import("../myhome/Deals"));
const AsyncRelocationServices = lazy(() => import("../myhome/RelocationServices"));
const AsyncFeedback = lazy(() => import("../myhome/Feedback"));
const AsyncContact = lazy(() => import("../myhome/ContactUnpakt"));

const AsyncPageNotFound = lazy(() => import("../common/PageNotFound"));


const mapStateToProps = ({ auth, router }) => ({
  isLoggedIn: isLoggedIn(auth),
  path: router.location.pathname,
});

const MyHome = ({ isLoggedIn, checkCookies, path, initMyHome }) => {
  useEffect(() => initMyHome() //eslint-disable-next-line
  , []);
  const viewModel = useMedia(
    ['(min-width: 1440px)', '(min-width: 992px)', '(min-width: 768px)', '(min-width: 1px)'],
    ['wide', 'desktop', 'tablet', 'mobile'],
    'desktop'
  );
  return (
    <>
      <Header />

      {isLoggedIn ?
        <div className="my-home-container">
          <LeftNav viewModel={viewModel} />
          <main className="w-100">
            <Switch>
              <Route exact path="/my-home" render={() => <Suspense fallback={<LoadingPage />}><AsyncMyHome viewModel={viewModel} /></Suspense>} />
              <Route exact path="/my-home/start-new-move" render={() => <Suspense fallback={<LoadingPage />}><AsyncStartNewMove viewModel={viewModel} /></Suspense>} />
              <Route exact path="/my-home/confirm-move" render={() => <Suspense fallback={<LoadingPage />}><AsyncConfirmMove viewModel={viewModel} /></Suspense>} />
              <Route exact path="/my-home/add-services" render={() => <Suspense fallback={<LoadingPage />}><AsyncAddServices viewModel={viewModel} /></Suspense>} />
              <Route exact path="/my-home/add-special-handling" render={() => <Suspense fallback={<LoadingPage />}><AsyncAddSpecialHandling /></Suspense>} />
              <Route exact path="/my-home/current-move-review" render={() => <Suspense fallback={<LoadingPage />}><AsyncCurrentMoveReview viewModel={viewModel}/></Suspense>} />
              <Route exact path="/my-home/reset-password" render={() => <Suspense fallback={<LoadingPage />}><AsyncResetPassword viewModel={viewModel} /></Suspense>} />
              <Route exact path="/my-home/moving-checklist" render={() => <Suspense fallback={<LoadingPage />}><AsyncMovingChecklist /></Suspense>} />
              <Route exact path="/my-home/refer-friends" render={() => <Suspense fallback={<LoadingPage />}><AsyncBenefits /></Suspense>} />
              <Route exact path="/my-home/move-history" render={() => <Suspense fallback={<LoadingPage />}><AsyncMoveHistory viewModel={viewModel} /></Suspense>} />
              <Route exact path="/my-home/deals" render={() => <Suspense fallback={<LoadingPage />}><AsyncDealsPage viewModel={viewModel} /></Suspense>} />
              <Route exact path="/my-home/relocation-services" render={() => <Suspense fallback={<LoadingPage />}><AsyncRelocationServices viewModel={viewModel} /></Suspense>} />
              <Route exact path="/my-home/feedback" render={() => <Suspense fallback={<LoadingPage />}><AsyncFeedback viewModel={viewModel} /></Suspense>} />
              <Route exact path="/my-home/contact" render={() => <Suspense fallback={<LoadingPage />}><AsyncContact viewModel={viewModel} /></Suspense>} />
              <Route render={() => <Suspense fallback={<LoadingPage />}><AsyncPageNotFound /></Suspense>} />
            </Switch>
          </main>
        </div>
        :
        <div className="d-flex pt-5 flex-column align-items-center justify-content-center w-100">
          <SignIn destination={'my-home'} />
        </div>
      }
      <style jsx>
        {`
        main {
          background: #EAEAEA
        }
        `}
      </style>

    </>
  )
}

export default connect(mapStateToProps, { initMyHome })(MyHome);