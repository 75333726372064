import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Modal from './Modal';
import Backdrop from "./Backdrop";

const Modals = ({modals}) => {
  useEffect(() => {
    if(modals.length > 0) {document.body.style.position = "fixed"; document.body.style.width = '100%'}
    else document.body.style.position = "relative"
  },[modals])
  return ( modals.length > 0 &&
  <div className="container-fluid">
    <Backdrop/>
    {modals.map(({name, properties}, index) => (<Modal key={index} name={name} properties={properties} />))}
    <style jsx>{`
      .modal-center {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    `}</style>
  </div>
)};

const mapStateToProps = state => ({
  modals: state.modals
});

export default connect(mapStateToProps)(Modals);