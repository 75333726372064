import { handleActions } from 'redux-actions';
import { FLOW_STEPS, HOURLY_CONFIRMATION_STEPS, HOURLY_FUNNEL_PATHS } from '../../constants';
import { SET_HOURLY_CURRENT_STEP, SKIP_HOURLY_CONFIRMATION_STEP, COMPLETE_HOURLY_CONFIRMATION_STEP, SET_HOURLY_CURRENT_CONFIRMATION_STEP } from '../actionTypes';
import { LOCATION_CHANGE } from 'connected-react-router';

const initialState = {
  currentStep: '',
  lastStep: '',
  bodyClass: 'location-details-body',
  progressBarSteps:{
    INVENTORY: {
      id:  'inventory',
      title:  'Inventory',
      step: 'INVENTORY',
      nextStepButtonText: 'continue',
      showNextStepButton: true,
      isCompleted: true,
      nextStep: 'COMPARE'
    },
    DETAILS: {
      id:  'details',
      title:  'Details',
      step: 'DETAILS',
      nextStepButtonText: 'continue',
      showNextStepButton: true,
      isCompleted: false,
      nextStep: 'LAST_STEP'
    },
    COMPARE: {
      id:  'compare',
      title:  'Compare Movers',
      step: 'COMPARE',
      nextStepButtonText: 'continue',
      showNextStepButton: false,
      isCompleted: false,
      nextStep: 'BOOK'
    },
    PLAN: {
      id:  'plan',
      title:  'Review',
      step: 'PLAN',
      nextStepButtonText: 'continue',
      showNextStepButton: false,
      isCompleted: false,
      nextStep: 'BOOK'
    },
    BOOK: {
      id:  'book',
      title:  'Book Online',
      step: 'BOOK',
      nextStepButtonText: 'continue',
      showNextStepButton: true,
      isCompleted: false,
      nextStep: 'CONGRATS'
    }
  },
  confirmationSteps: HOURLY_CONFIRMATION_STEPS,
  currentConfirmationStep: 0,
  currentIndex: 0,
  animationClass: 'slide-right'
};

const hourlyFunnelReducer = handleActions({
  [SET_HOURLY_CURRENT_STEP]: (state, { payload }) => ({
    ...state,
    currentStep: payload
  }),
  // [SET_LAST_STEP]: (state, {payload}) => ({
  //   ...state,
  //   lastStep: payload
  // }),
  // [SET_STEP_COMPLETED]: (state, {payload}) => {
  //   if( (payload !== 'INVENTORY') ||
  //       (payload !== 'DETAILS') ||
  //       (payload !== 'PLAN') ||
  //       (payload !== 'BOOK') ||
  //       (payload !== 'CONGRATS')) {
  //     return ({
  //       ...state,
  //       progressBarSteps: {
  //         ...state.progressBarSteps,
  //         [payload]: {
  //           ...state.progressBarSteps[payload],
  //           isCompleted: true
  //         }
  //       }
  //     });
  //   }
  // },

  [SKIP_HOURLY_CONFIRMATION_STEP]: (state, {payload}) => {
    let confirmationSteps = state.confirmationSteps.map( (step, index) => {
      if(index !== payload) return step
      return {
        ...step,
        skipped: true
      }
    })
    return {
    ...state,
    confirmationSteps: confirmationSteps
  }},

  [COMPLETE_HOURLY_CONFIRMATION_STEP]: (state, {payload}) => {
    let confirmationSteps = state.confirmationSteps.map( (step, index) => {
      if(index !== payload) return step
      return {
        ...step,
        completed: true,
        skipped: false
      }
    })
    return {
    ...state,
    confirmationSteps: confirmationSteps
  }},

  [SET_HOURLY_CURRENT_CONFIRMATION_STEP]: (state, {payload}) => ({
    ...state,
    currentConfirmationStep: payload
  }),

  [LOCATION_CHANGE]: (state, {payload}) => {
    const index = HOURLY_FUNNEL_PATHS.findIndex((p) => p === payload.location.pathname );
    const lastIndex = state.currentIndex;
    if(index < 0) {
      return ({
        ...state,
        currentIndex: index,
        animationClass: 'fade'
      });
    } else if( index >= lastIndex) {
      return ({
        ...state,
        currentIndex: index,
        animationClass: 'slide-forward'
      });
    } else {
      return ({
        ...state,
        currentPath: payload.location.pathname,
        currentIndex: index,
        animationClass: 'slide-back'
      });
    }
  }
}, initialState);

export default hourlyFunnelReducer;

export const allowedToVisit = (state, action) => {
  if(state.auth.isAdmin || state.movePlan.currentPlan.is_read_only ) {
    return true;
  }
  if(state.movePlan.currentPlan.is_read_only) { return true }
  if(state.funnel.currentStep === 'PLAN' && action === 'print') {
    return true;
  }
  if(state.movePlan.currentPlan.is_done && state.funnel.action === 'PLAN') {
    return false;
  }
  if(state.auth.isMover) {
    if(action === 'PLAN') {
      return true
    } else if(action === 'BOOK') {
      return false;
    } else return !state.movePlan.currentPlan.is_public;
  }
  if(!!state.movePlan.currentPlan.last_step) {
    return !!(FLOW_STEPS.indexOf(action) <= FLOW_STEPS.indexOf(state.movePlan.currentPlan.last_step.toUpperCase()));
  }
  return false
};

export const toUSD = (amount) => (
  !!amount ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount) : '$0'
);
