import React from 'react';
import {connect} from 'react-redux';
import {closeModal} from "../../../redux/actions/modals";
import { requestInsurance } from '../../../redux/actions/movePlan';

const mapStateToProps = ({movePlan}) => ({
  insuranceRequested: movePlan.currentPlan.insuranceRequested,
});

const ConfirmInsuranceModal = ({closeModal, requestInsurance}) =>
(
  <>
    <header className="top-action-panel">
      <button className="close-reveal-modal" onClick={closeModal}>✖</button>
    </header>
    <div className="d-flex w-100 justify-content-center mb-4">
      <svg x="0px" y="0px" viewBox="0 0 15.8 18.7">
        <g>
          <path d="M15.2,1.3c-0.6,0.1-1.3,0.1-1.9,0.1c-1.7,0-3.3-0.6-4.7-1.1C8.4,0.2,8.2,0.2,8,0.1c-0.1,0-0.2,0-0.3,0
          C7.5,0.2,7.3,0.2,7.1,0.3c-1.4,0.5-3,1.1-4.7,1.1c-0.7,0-1.3,0-1.9-0.1c-0.1,0-0.2,0-0.3,0.1C0.1,1.5,0.1,1.6,0.1,1.7v9.1
          c0,4.3,7.3,7.6,7.6,7.7c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.3-0.2,7.6-3.4,7.6-7.7V1.7c0-0.1-0.1-0.2-0.1-0.3
          C15.4,1.3,15.3,1.2,15.2,1.3z M14.8,10.8c0,3.4-5.9,6.4-6.9,6.9c-0.5-0.2-2-1-3.4-2C2.9,14.5,1,12.8,1,10.9V2.1
          c0.5,0.1,1,0.1,1.6,0.1c1.8,0,3.5-0.6,5-1.1C7.7,1,7.9,1,8,0.9C8.1,1,8.3,1,8.4,1.1c1.5,0.5,3.1,1.1,5,1.1c0.5,0,1,0,1.6-0.1
          L14.8,10.8L14.8,10.8z" />
          <polygon points="6.5,10.1 4.9,8.6 3.8,9.7 6.5,12.4 12.5,6.4 11.3,5.2 	" />
        </g>
      </svg>
    </div>
    <div className="modal-body">
      <p>Please confirm your insurance request below and we will have an insurance agent contact you shortly!</p>
      <div className="mb-4 d-flex align-items-center justify-content-center">

        <button className="gray-pill-button" onClick={closeModal}>CANCEL</button>
        <button className="green-pill-button" onClick={() => {requestInsurance(); closeModal()}}>CONTACT ME</button>
      </div>
    </div>





    <style jsx>
      {`
        svg {
          height: 50px;
          margin: 0 15px;
          fill: #27beeb
        }
        `}
    </style>

  </>
)

export default connect(mapStateToProps, {closeModal, requestInsurance})(ConfirmInsuranceModal);
