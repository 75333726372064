export const FUNNEL_STEPS = {
  INVENTORY: {
    id:  'inventory',
    title:  'Inventory',
    step: 'INVENTORY',
    nextStepButtonText: 'continue',
    showNextStepButton: true,
    isCompleted: false,
    nextStep: 'COMPARE'
  },
  DETAILS: {
    id:  'details',
    title:  'Details',
    step: 'INVENTORY',
    nextStepButtonText: 'continue',
    showNextStepButton: false,
    isCompleted: false,
    nextStep: 'LAST_STEP'
  },
  COMPARE: {
    id: 'compare',
    title: 'Compare Movers',
    step: 'INVENTORY',
    nextStepButtonText: 'continue',
    showNextStepButton: false,
    isCompleted: false,
    nextStep: 'BOOK'
  },
  BOOK: {
    id:  'book',
    title:  'Book Online',
    step: 'INVENTORY',
    nextStepButtonText: 'continue',
    showNextStepButton: true,
    isCompleted: false,
    nextStep: 'CONGRATS'
  },
};

export const  FLOW_STEPS = [
  'INVENTORY',
  'DETAILS',
  'COMPARE',
  'PLAN',
  'BOOK',
  'CONGRATS'
];

export const 	STATES = [
  { value: 'AL', label: 'AL', full: 'Alabama'},
  { value: 'AK', label: 'AK', full: 'Alaska'},
  { value: 'AZ', label: 'AZ', full: 'Arizona'},
  { value: 'AR', label: 'AR', full: 'Arkansas'},
  { value: 'CA', label: 'CA', full: 'California'},
  { value: 'CO', label: 'CO', full: 'Colorado'},
  { value: 'CT', label: 'CT', full: 'Connecticut'},
  { value: 'DC', label: 'DC', full: 'District of Columbia'},
  { value: 'DE', label: 'DE', full: 'Delaware'},
  { value: 'FL', label: 'FL', full: 'Florida'},
  { value: 'GA', label: 'GA', full: 'Georgia'},
  { value: 'HI', label: 'HI', full: 'Hawaii'},
  { value: 'ID', label: 'ID', full: 'Idaho'},
  { value: 'IL', label: 'IL', full: 'Illinois'},
  { value: 'IN', label: 'IN', full: 'Indiana'},
  { value: 'IA', label: 'IA', full: 'Iowa'},
  { value: 'KS', label: 'KS', full: 'Kansas'},
  { value: 'KY', label: 'KY', full: 'Kentucky'},
  { value: 'LA', label: 'LA', full: 'Louisiana'},
  { value: 'ME', label: 'ME', full: 'Maine'},
  { value: 'MD', label: 'MD', full: 'Maryland'},
  { value: 'MA', label: 'MA', full: 'Massachusetts'},
  { value: 'MI', label: 'MI', full: 'Michigan'},
  { value: 'MN', label: 'MN', full: 'Minnesota'},
  { value: 'MS', label: 'MS', full: 'Mississippi'},
  { value: 'MO', label: 'MO', full: 'Missouri'},
  { value: 'MT', label: 'MT', full: 'Montana'},
  { value: 'NE', label: 'NE', full: 'Nebraska'},
  { value: 'NV', label: 'NV', full: 'Nevada'},
  { value: 'NH', label: 'NH', full: 'New Hampshire'},
  { value: 'NJ', label: 'NJ', full: 'New Jersey'},
  { value: 'NM', label: 'NM', full: 'New Mexico'},
  { value: 'NY', label: 'NY', full: 'New York'},
  { value: 'NC', label: 'NC', full: 'North Carolina'},
  { value: 'ND', label: 'ND', full: 'North Dakota'},
  { value: 'OH', label: 'OH', full: 'Ohio'},
  { value: 'OK', label: 'OK', full: 'Oklahoma'},
  { value: 'OR', label: 'OR', full: 'Oregon'},
  { value: 'PA', label: 'PA', full: 'Pennsylvania'},
  { value: 'RI', label: 'RI', full: 'Rhode Island'},
  { value: 'SC', label: 'SC', full: 'South Carolina'},
  { value: 'SD', label: 'SD', full: 'South Dakota'},
  { value: 'TN', label: 'TN', full: 'Tennessee'},
  { value: 'TX', label: 'TX', full: 'Texas'},
  { value: 'UT', label: 'UT', full: 'Utah'},
  { value: 'VT', label: 'VT', full: 'Vermont'},
  { value: 'VA', label: 'VA', full: 'Virginia'},
  { value: 'WA', label: 'WA', full: 'Washington'},
  { value: 'WV', label: 'WV', full: 'West Virginia'},
  { value: 'WI', label: 'WI', full: 'Wisconsin'},
  { value: 'WY', label: 'WY', full: 'Wyoming'}
];

export const FUNNEL_PATHS = [
  "/",
  "/1/basics",
  "/2/coi",
  "/2/elevator",
  "/3/specialhandling",
  "/3/packing",
  "/3/coverage",
  "/4/inventory",
  "/4/paperwork",
  "/4/payment"
]

export const HOURLY_FUNNEL_PATHS = [
  "/",
  "/1/basics",
  "/2/coi",
  "/2/elevator",
  "/3/hourly",
  "/3/paperwork",
  "/3/payment"
]

export const TIME_OPTIONS = [
  "8:00 AM - 9:00 AM",
  "9:00 AM - 10:00 AM",
  "10:00 AM - 11:00 AM",
  "11:00 AM - 12:00 PM",
  "12:00 PM - 1:00 PM",
  "1:00 PM - 2:00 PM",
  "2:00 PM - 3:00 PM",
  "3:00 PM - 4:00 PM",
  "4:00 PM - 5:00 PM",
  "5:00 PM - 6:00 PM",
  "6:00 PM - 7:00 PM"
];

export const TIME_OPTIONS_SELECT = [
  { value: '8:00 AM - 9:00 AM', label: '8:00 AM - 9:00 AM' },
  { value: '9:00 AM - 10:00 AM', label: '9:00 AM - 10:00 AM' },
  { value: '10:00 AM - 11:00 AM', label: '10:00 AM - 11:00 AM' },
  { value: '11:00 AM - 12:00 PM', label: '11:00 AM - 12:00 PM' },
  { value: '12:00 PM - 1:00 PM', label: '12:00 PM - 1:00 PM' },
  { value: '1:00 PM - 2:00 PM', label: '1:00 PM - 2:00 PM' },
  { value: '2:00 PM - 3:00 PM', label: '2:00 PM - 3:00 PM' },
  { value: '3:00 PM - 4:00 PM', label: '3:00 PM - 4:00 PM' },
  { value: '4:00 PM - 5:00 PM', label: '4:00 PM - 5:00 PM' },
  { value: '5:00 PM - 6:00 PM', label: '5:00 PM - 6:00 PM' },
  { value: '6:00 PM - 7:00 PM', label: '6:00 PM - 7:00 PM' },
];

export const TIME_FRAMES = [
  {
    time: "8-10 AM",
    full_time: '8:00 AM - 10:00 AM'
  },
  {
    time: "9-11 AM",
    full_time: '9:00 AM - 11:00 AM'
  },
  {
    time: "1-3 PM",
    full_time: '1:00 PM - 3:00 PM'
  },
  {
    time: "2-4 PM",
    full_time: '2:00 PM - 4:00 PM'
  }
];

export const INVENTORY_ROOMS = ["Living Room", "Bedroom", "Dining Room", "Kitchen", "Kids Room", "Office", "Outdoor", "Other", '',"Boxes I Packed"];

export const INVENTORY_BOXES = [
  {
    id: 1,
    name: 'Small Box',
    description: 'For small and heavy items',
    size: '12"x12.5"x17"',
    cubic_feet: 1.5,
    icon: 'linen',
  },
  {
    id: 2,
    name: 'Medium Box',
    description: 'For general stuff',
    size: '16"x16"x18"',
    cubic_feet: 3.0,
    icon: 'medium'
  },
  {
    id: 3,
    name: 'Large Box',
    description: 'For large and light items',
    size: '20"x20"x18"',
    cubic_feet: 4.5,
    icon: 'large'
  },
  {
    id: 4,
    name: 'China/Dish Box',
    description: 'For fragile items',
    size: '18"x18"x28"',
    cubic_feet: 5.2,
    icon: 'china'
  },
  {
    id: 5,
    name: 'File Box',
    description: 'For folders and files',
    size: '12"x12"x24"',
    cubic_feet: 2.0,
    icon: 'letter'
  },
  {
    id: 6,
    name: 'Legal File Box',
    description: 'For folders',
    size: '12"x15"x24"',
    cubic_feet: 2.5,
    icon: 'legal'
  },
  {
    id: 7,
    name: 'Wardrobe Box',
    description: 'For hangers',
    size: '24"x23"x48"',
    cubic_feet: 15.5,
    icon: 'wardrobe'
  },
];

export const HOME_SIZES_SELECT = [
  { value: 17 , text: 'One item', subText: false, short_formatted_type: 'One item'  },
  { value: 16 , text: 'Just a few items', subText: false, short_formatted_type: 'Just a few items'  },
  { value: 1 , text: 'Studio', subText: '(400-600 sq ft)', short_formatted_type: 'A studio'  },
  { value: 2 , text: 'Studio Alcove', subText: '(600-700 sq ft)', short_formatted_type: 'A studio alcove'  },
  { value: 3 , text: '1 Bedroom, Small', subText: '(600-800 sq ft)', short_formatted_type: '1 small bedroom'  },
  { value: 4 , text: '1 Bedroom, Large', subText: '(800-1000 sq ft)', short_formatted_type: '1 large bedroom'  },
  { value: 5 , text: '2 Bedroom', subText: '(1000 to 1500 sq ft)', short_formatted_type: '2 bedrooms'  },
  { value: 6 , text: '3 Bedroom', subText: '(1500 to 2000 sq ft)', short_formatted_type: '3 bedrooms'  },
  { value: 7 , text: '4+ Bedroom', subText: 'over 2000 sq ft', short_formatted_type: '4+ bedrooms'  }
];

export const HOME_SIZES = {
  1: {
    type: 'Studio', size: '400-600 sq ft', short_formatted_type: 'A studio'
  },
  2: {
    type: 'Studio Alcove', size: '600-700 sq ft', short_formatted_type: 'A studio alcove'
  },
  3: {
    type: '1 Bedroom, Small', size: '600-800 sq ft', short_formatted_type: '1 small bedroom'
  },
  4: {
    type: '1 Bedroom, Large', size: '800-1000 sq ft', short_formatted_type: '1 large bedroom'
  },
  5: {
    type: '2 Bedroom', size: '1000 to 1500 sq ft', short_formatted_type: '2 bedrooms'
  },
  6: {
    type: '3 Bedroom', size: '1500 to 2000 sq ft', short_formatted_type: '3 bedrooms'
  },
  7: {
    type: '4+ Bedroom', size: 'over 2000 sq ft', 
  },
  16: {
    type: 'Just a few items', size: '', short_formatted_type: 'Just a few items'
  },
  17: {
    type: 'One item', size: '', short_formatted_type: 'One item'
  }
};

export const HEIGHTS_BY_ID = {
  1: 'Elevator',
  2: 'No stairs - ground floor',
  3: 'Stairs - 2nd floor',
  4: 'Stairs - 3rd floor',
  5: 'Stairs - 4th floor',
  6: 'Stairs - 5th floor',
  7: 'Stairs - 6th floor'
}

export const HEIGHTS = [
  { value: '1', label: 'Elevator'},
  { value: '2', label: 'No stairs - ground floor'},
  { value: '3', label: 'Stairs - 2nd floor'},
  { value: '4', label: 'Stairs - 3rd floor'},
  { value: '5', label: 'Stairs - 4th floor'},
  { value: '6', label: 'Stairs - 5th floor'},
  { value: '7', label: 'Stairs - 6th floor'}
];

export const CONFIRMATION_STEPS = [
  {
    name: 'basics',
    completed: false,
    skipped: false
  },
  {
    name: 'coi',
    completed: false,
    skipped: false
  },
  {
    name: 'elevator',
    completed: false,
    skipped: false
  },
  {
    name: 'specialhandling',
    completed: false,
    skipped: false
  },
  {
    name: 'packing',
    completed: false,
    skipped: false
  },
  {
    name: 'coverage',
    completed: false,
    skipped: false
  },
  {
    name: 'inventory',
    completed: false,
    skipped: false
  },
  {
    name: 'paperwork',
    completed: false,
    skipped: false
  },
  {
    name: 'payment',
    completed: false,
    skipped: false
  },
  {
    name: 'completed',
    completed: false,
    skipped: false
  },
]

export const HOURLY_CONFIRMATION_STEPS = [
  {
    name: 'basics',
    completed: false,
    skipped: false
  },
  {
    name: 'coi',
    completed: false,
    skipped: false
  },
  {
    name: 'elevator',
    completed: false,
    skipped: false
  },
  {
    name: 'hourly',
    completed: false,
    skipped: false
  },
  {
    name: 'paperwork',
    completed: false,
    skipped: false
  },
  {
    name: 'payment',
    completed: false,
    skipped: false
  },
  {
    name: 'completed',
    completed: false,
    skipped: false
  },
]

export const truckSizeOptions = [
  {label: 'No Truck', value: 1},
  {label: 'Small', value: 2},
  {label: 'Medium', value: 3},
  {label: 'Large', value: 4},
  {label: 'Extra Large', value: 5}
]
export const stairsOptions = [
  {label: 'Yes', value: 1},
  {label: 'No', value: 0}
]
export const confirmationalStairsOptions = [
  {label: 'Yes', value: true},
  {label: 'No', value: false}
]
export const laborersOptions = [
  {label: 1, value: 1},
  {label: 2, value: 2},
  {label: 3, value: 3},
  {label: 4, value: 4},
  {label: 5, value: 5},
  {label: 6, value: 6}
]
export const hoursOptions = [
  {label: 1, value: 1},
  {label: 2, value: 2},
  {label: 3, value: 3},
  {label: 4, value: 4},
  {label: 5, value: 5},
  {label: 6, value: 6},
  {label: 7, value: 7},
  {label: 8, value: 8},
  {label: 9, value: 9},
  {label: 10, value: 10},
  {label: 11, value: 11},
  {label: 12, value: 12}
]
export const travelHoursOptions = [
  {label: 0, value: 0},
  {label: 1, value: 1},
  {label: 2, value: 2},
  {label: 3, value: 3},
  {label: 4, value: 4},
  {label: 5, value: 5},
  {label: 6, value: 6}
]
export const minutesOptions = [
  {label: 0, value: 0},
  {label: 10, value: 0.17},
  {label: 20, value: 0.33},
  {label: 30, value: 0.5},
  {label: 40, value: 0.67},
  {label: 50, value: 0.83},
]
export const uprightPianosOptions = [
  {label: 0, value: 0},
  {label: 1, value: 1},
  {label: 2, value: 2},
  {label: 3, value: 3},
  {label: 4, value: 4},
  {label: 5, value: 5},
  {label: 6, value: 6}
]
export const babyGrandPianosOptions = [
  {label: 0, value: 0},
  {label: 1, value: 1},
  {label: 2, value: 2},
  {label: 3, value: 3},
  {label: 4, value: 4},
  {label: 5, value: 5},
  {label: 6, value: 6}
]
export const hourlyOverWeightOptions = [
  {label: 0, value: 0},
  {label: 1, value: 1},
  {label: 2, value: 2},
  {label: 3, value: 3},
  {label: 4, value: 4},
  {label: 5, value: 5},
  {label: 6, value: 6}
]
export const hourlyOverSizeOptions = [
  {label: 0, value: 0},
  {label: 1, value: 1},
  {label: 2, value: 2},
  {label: 3, value: 3},
  {label: 4, value: 4},
  {label: 5, value: 5},
  {label: 6, value: 6}
]