import React from 'react';
import {connect} from 'react-redux';
import { Container, Row } from 'reactstrap';

const mapStateToProps = ({movePlan}) => ({
  uuid: movePlan.currentMPID
});

const SocialSection = (props) => {
  const handleGoogleSignin = () => {
    window.location.href = `${process.env.REACT_APP_BASE_API_URL}/users/auth/google_oauth2?redirect_url=${process.env.REACT_APP_DOMAIN}/`
  }
  const handleFacebookSignin = () => {
    window.location.href = `${process.env.REACT_APP_BASE_API_URL}/users/auth/facebook?redirect_url=${process.env.REACT_APP_DOMAIN}/`
  }
  return (
    <Container>
      <Row>
        <div className="col-12 col-sm-6 mb-2">
          <button 
            type="button" 
            className="social-button p-3 google" 
            onClick={handleGoogleSignin}>          
            <span className="social-text m-auto">Sign in with Google</span>
          </button>
        </div>
        
        <div className="col-12 col-sm-6 mb-2">
          <button 
            type="button" 
            className="social-button p-3 facebook" 
            onClick={handleFacebookSignin}>

            <span className="social-text m-auto">Sign in with Facebook</span>
          </button>
        </div>
        

      </Row>
      <style jsx>
        {/* CSS */`
          .social-block {
              color: #fff;
              margin: 0 auto;
              display: flex;
              flex-direction: column;
              padding: 0 10px;
          }

          .social-block a {
              color: white;
          }

          .facebook, .google {
              display: flex;
              align-items: center;

              background: #3b5998;
              color: #fff;
              padding: 0 10px 0 0;
              font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
              border: none;
              cursor: pointer;
              border-radius: 3px;
          }
          .google {
            background: #3c70d0
          }
          .icon-facebook. .icon-google {
            margin-top: 2px;
          }
          .icon-google {
            padding: 2px;
          }
          svg {
            color: white;
            margin: auto;
            margin-right: 5px;

          }
          .social-button {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 12px;
            overflow: hidden;
            white-space: nowrap;
            line-height: 14px;
            margin: 5px auto;
            padding: 5px 10px;
            width: inherit;
          }
          .social-text {
            margin-left: 10px;
          }
          .google {
          background: #3c70d0
          }
          .icon-google {
            padding: 2px;
          }
          `}
      </style>
    </Container>
  )
}

export default connect(mapStateToProps)(SocialSection)
