import React from 'react';
import {connect} from 'react-redux';
import { closeModal } from '../../../redux/actions/modals';
import { reusePlan } from '../../../redux/actions/myHome';
import { Formik } from 'formik';
import ReuseInventoryModalFields from './ReuseInventoryModalFields';
import { HOME_SIZES_SELECT } from '../../../constants';
import { addDays, format } from 'date-fns';

const ReuseInventoryModal = props => {
  const getHomeSizeId = () => (
    HOME_SIZES_SELECT.find(size => size.label = props.plan.home_size ? size.value : null)
  );

  return (
    <Formik
      initialValues={{
        pick_up: {},
        drop_off: {},
        move_date: addDays(Date.now(), 10)
      }}
      validateOnChange={false}
      validate={values => {
        let errors = {};
        if (!!!values.pick_up.geocoded_address) { errors.pick_up_address = 'Pick Up Address is required';};
        if (!!!values.drop_off.geocoded_address) { errors.drop_off_address = 'Drop Off Address is required';};

        return errors;
        
      }}
      handleSubmit={(e) =>  {
        e.preventDefault();
      }}

      onSubmit={ (values) => {
        const hsID = getHomeSizeId();
        let move_plan = {
          src: "gmaps",
          pick_up_address: values.pick_up,
          drop_off_address: values.drop_off,
          move_date: format(values.move_date, 'MM/DD/YYYY'),
          home_size_id: hsID.value,
          white_label_name: "Unpakt"
        };
        props.reusePlan(props.plan.uuid, move_plan)
      }}

      render={({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  setFieldError
                }) => (
        <ReuseInventoryModalFields
          values={values}
          errors={errors}
          onChange={handleChange}
          onBlue={handleBlur}
          handleSubmit={handleSubmit}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
          isSubmitting={isSubmitting}
          />
      )
    }/>
  )
};

export default connect(null, {closeModal, reusePlan})(ReuseInventoryModal);