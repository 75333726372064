import { INIT_MDA, NETWORK_SUCCESS, NETWORK_FAILURE, GET_MOVEPLAN_WITH_TOKEN, MOVE_DAY_ADJUSTMENT, INIT_MDA_FINISHED, ACCEPT_MDA, REJECT_MDA } from "../actionTypes";
import { setCurrentMoveplanID, setCurrentPlanInfo, setMoveplanApiError } from "../actions/movePlan";
import { getMoveplanWithToken, setMdaToken, updateMDAPrice, setMDALoading, setMDAHourlyUpdateLoading, fetchPrevHourlyValues } from "../actions/mda";
import { apiRequest } from "../actions/api";
import { fetchInventory, fetchAllInventory, setInventoryLoading } from "../actions/inventory";


const mdaMiddleware = ({ getState, dispatch }) => next => action => {
  next(action);

  if( action.type === INIT_MDA) {
    dispatch(setMDALoading(true));
    let token = getState().router.location.search.substring(1).split('=')[1];
    dispatch(setMdaToken(token))
    dispatch(getMoveplanWithToken(token));
    dispatch(setMoveplanApiError(null))
  }

  if( action.type === INIT_MDA_FINISHED ) {
    // This is completes after fetch all inventory finishes, enter any other side effects here
    dispatch(setMDALoading(false));
  }

  if (action.type === GET_MOVEPLAN_WITH_TOKEN ) {
    dispatch(
      apiRequest({
        url: `/api/v3/move_plans/show?token=${action.payload}`,
        method: 'GET',
        fromAction: GET_MOVEPLAN_WITH_TOKEN
      })
    );
  }

  if (action.type === MOVE_DAY_ADJUSTMENT) {
    dispatch(setInventoryLoading(true))
    dispatch(setMDAHourlyUpdateLoading(true))
    dispatch(
      apiRequest({
        url: `${process.env.REACT_APP_BASE_API_URL}/api/v3/mda/mda_edit`,
        method: 'POST',
        params: action.payload,
        fromAction: MOVE_DAY_ADJUSTMENT
      })
    );
  }

  if (action.type === ACCEPT_MDA) {
    dispatch(
      apiRequest({
        url: `${process.env.REACT_APP_BASE_API_URL}/api/v3/mda/accept_mda`,
        method: 'POST',
        params: action.payload,
        fromAction: ACCEPT_MDA
      })
    );
  }

  if (action.type === REJECT_MDA) {
    dispatch(
      apiRequest({
        url: `${process.env.REACT_APP_BASE_API_URL}/api/v3/mda/reject_mda`,
        method: 'POST',
        params: action.payload,
        fromAction: REJECT_MDA
      })
    );
  }


  if(action.type === NETWORK_SUCCESS) {
    switch (action.meta.originalRequest.fromAction) {
      case GET_MOVEPLAN_WITH_TOKEN:
        dispatch(setCurrentMoveplanID(action.payload.response.data.plan.uuid));
        dispatch(setCurrentPlanInfo(action.payload.response.data.plan));
        dispatch(fetchInventory(action.payload.response.data.plan.uuid));
        dispatch(fetchAllInventory(action.payload.response.data.plan.uuid, 'MDA'));
        break;
      case MOVE_DAY_ADJUSTMENT:
        if (getState().movePlan.currentPlan.hourly_plan && (action.payload.response.data.status === 'submitted' || action.payload.response.data.status === 'accepted')) {
          dispatch(fetchPrevHourlyValues(action.payload.response.data))
        }
        dispatch(updateMDAPrice(action.payload.response.data.mda_cost))
        dispatch(setInventoryLoading(false))
        dispatch(setMDAHourlyUpdateLoading(false))
        break;
      default:
        break;
    }
  }
  
  if(action.type === NETWORK_FAILURE) {
    switch (action.meta.originalRequest.fromAction) {
      case GET_MOVEPLAN_WITH_TOKEN:
          if(action.payload.response.response.status === 404) {
            dispatch(setMoveplanApiError('Could not find MDA Plan'))
          }
          dispatch(setMDALoading(false));
          break;
      case MOVE_DAY_ADJUSTMENT:
          dispatch(setInventoryLoading(false))
          dispatch(setMDAHourlyUpdateLoading(false))
          break;
      default:
        break;
    }
  }
}

export default mdaMiddleware;