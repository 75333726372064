import React, {useState} from 'react';
import {connect} from 'react-redux';
import { isLoggedIn } from '../../redux/reducers/auth';
import { signOut } from '../../redux/actions/auth';
import './header.scss'
import { Link } from 'react-router-dom';

const mapStateToProps = ({auth}) => ({
  isLoggedIn: isLoggedIn(auth),
  isAdmin: auth.isAdmin || auth.isSuperAdmin,
  currentUser: auth.user
})

const Header = ({isLoggedIn, signOut, isAdmin, currentUser}) => {
  const [showMobileMenu, setMobileMenu] = useState(false);
  const toggleMobileMenu = () => {setMobileMenu(!showMobileMenu)}
  const menuItemClick = () => {setMobileMenu(false)}

  const signOutM = () => {
    setMobileMenu(!showMobileMenu);
    signOut();
  }
  
  return (
    <div className={showMobileMenu && 'funnel-sidebar-open mobile-menu-open'}>
      <section className="header-container">
        <a className="logo-container left" href={process.env.REACT_APP_MARKETING_URL}>
          <img className="logo-size" alt="unpakt we move people" src="/assets/wemovepople_wide_white_v2.png" />
        </a>
        <nav id="mobile-navbar" className="mobile-navbar">
          <div className="menu__level menu__level--current">
              <div className="menu__wrap">
              { isLoggedIn &&
                <div className="mobile-users-section">
                  <div className="mobile-user-name-holder">
                    <span className="mobile-user-name">{ currentUser.name ? currentUser.name : currentUser.email }</span>
                  </div>
                  <ul className="mobile-menu mobile-user-menu">
                    <li className="mobile-menu-item" onClick={() => menuItemClick()}>
                      <a href='/my-home' className="mobile-menu-item-link" target="_self">My Moves</a>
                    </li>
                    { isAdmin && 
                      <li className="mobile-menu-item" onClick={() => menuItemClick()} ng-if="isAdmin()">
                        <a href={ process.env.REACT_APP_MARKETING_URL + '/admin'} className="mobile-menu-item-link" target="_self">Admin Dashboard</a>
                      </li>
                    }
                    
                    <li className="mobile-menu-item" onClick={() => menuItemClick()}>
                      <a href={ process.env.REACT_APP_MARKETING_URL + '/users/edit'} className="mobile-menu-item-link" target="_self">Account Settings</a>
                    </li>
                    <li className="mobile-menu-item" onClick={() => menuItemClick()}>
                      <a className="dropdown-menu-item-link mobile-menu-benefits-link" onClick={() => menuItemClick()}
                      target="_self" href={process.env.REACT_APP_MARKETING_URL + '/benefits'}>Get $20</a>
                    </li>
                  </ul>
                </div>
              }

              <ul className="mobile-menu mobile-main-menu">
                <li className="mobile-menu-item active">
                  <a className="mobile-menu-item-link active" onClick={() => menuItemClick()} href={ process.env.REACT_APP_MARKETING_URL}>Unpakt Home</a>
                </li>
                <li className="mobile-menu-item">
                  <a className="mobile-menu-item-link" onClick={() => menuItemClick()} href={ process.env.REACT_APP_MARKETING_URL + '/faq'}> FAQ</a>
                </li>
                <li className="mobile-menu-item">
                  <a className="mobile-menu-item-link" onClick={() => menuItemClick()} href={ process.env.REACT_APP_MARKETING_URL + '/customer-service'}> Customer Support</a>
                </li>
                { isLoggedIn &&
                  <li className="mobile-menu-item">
                    <span className="mobile-menu-item-link" onClick={() => signOutM()} >
                      Log out
                    </span>
                  </li>
                }

                { !isLoggedIn &&
                  <li className="mobile-menu-item">
                    <Link to={'/signin'} className="mobile-menu-item-link" onClick={() => toggleMobileMenu()} >
                      LOG IN
                    </Link>
                  </li>
                }
              </ul>
            </div>
          </div>
        </nav>

        <div className="hamburger" onClick={() => toggleMobileMenu()}>
          <span className="bar"></span>
        </div>
      </section>
      <div  id="header-container" className="dimmer" onClick={() => toggleMobileMenu()}/>

      <style jsx> {/* CSS */`
        svg {
          fill: #fff
        }
        .logo-size {
          height: 90%;
          margin-bottom: 5px;
        }
        .mobile-menu-item-link {
          cursor: pointer;
        }
        `}
      </style>
    </div>
  );
}

export default connect(mapStateToProps, {signOut})(Header)
