import { handleActions } from 'redux-actions';
import { SET_MDA_TOKEN, RESET_MDA, UPDATE_MDA_PRICE, MOVE_DAY_ADJUSTMENT, SET_MDA_LOADING, SET_MDA_HOURLY_UPDATE_LOADING, FETCH_PREV_HOURLY_VALUES } from '../actionTypes';
import { minutesOptions } from '../../constants'
import { truckSizeOptions } from '../../constants'

const initialState = {
  loading: false,
  token: null,
  moveDayItems: {},
  newMDAPrice: 0,
  mdaHourlyUpdateLoading: false,
  prevHourlyValues: null
};

const movePlanReducer = handleActions({
  [SET_MDA_TOKEN]: (state, { payload }) => ({
    ...state,
    token: payload
  }),
  [MOVE_DAY_ADJUSTMENT]: (state, { payload }) => {
    return ({
      ...state,
      moveDayItems: payload,
    })
  },
  [UPDATE_MDA_PRICE]: (state, { payload }) => {
    return ({
      ...state,
      newMDAPrice: payload,
    })
  },
  [RESET_MDA]: (state, { payload }) => {
    return ({
      ...state,
      moveDayItems: payload,
      newMDAPrice: 0,
    });
  },
  [SET_MDA_LOADING]: (state, { payload }) => ({
    ...state,
    loading: payload
  }),
  [SET_MDA_HOURLY_UPDATE_LOADING]: (state, { payload }) => ({
    ...state,
    mdaHourlyUpdateLoading: payload
  }),
  [FETCH_PREV_HOURLY_VALUES]: (state, { payload }) => ({
    ...state,
    prevHourlyValues: payload
  })
}, initialState);

export const fitMinsSelections = (mins) => {
  let options = minutesOptions.map(option => option.value)
  let diff = 1
  let result = -1
  options.forEach(option => {
    if (Math.abs(mins - option) < diff) {
      diff = Math.abs(mins - option)
      result = option
    }
  })
  return result
}

export const convertToHoursMins = (minutes, init=false) => {
  let hrValue = Math.floor(minutes / 60)
  let mins = minutes % 60
  let minValue
  if (!init) {
    minValue = fitMinsSelections(mins / 60)
  } else {
    minValue = Math.round(mins / 60 * 100) / 100
  }
  
  return {hr: hrValue, min: minValue}
}

export const castReadableNewValue = (item) => {
  if (item.preference_name === 'hourly_stairs') {
    if (item.new_value === 1) {
      return 'Yes'
    } else if (item.new_value === 0) {
      return 'No'
    }
  } else if (item.preference_name === 'truck_size_id') {
    if (!item.new_value) {
      return 'No Truck'
    }
    return truckSizeOptions.find(option => option.value === item.new_value).label
  } else if (item.preference_name === 'num_hours' || item.preference_name === 'hourly_travel_hours') {
    return Math.round(item.new_value * 100) / 100
  } else {
    if (!item.new_value) {
      return 0
    } else {
      return item.new_value
    }
  }
}
export const castReadableOriginalValue = (preference_name, old_value, estimated_travel_minutes) => {
  if (preference_name === 'hourly_stairs') {
    if (old_value === true || old_value === 1) {
      return 'Yes'
    } else if (old_value === false || old_value === 0) {
      return 'No'
    }
  } else if (preference_name === 'num_hourly_upright_pianos' || preference_name === 'num_hourly_baby_grand_pianos' || preference_name === 'num_hourly_overweight' || preference_name === 'num_hourly_oversize') {
    if (!old_value) {
      return 0
    } else {
      return old_value
    }
  } else if (preference_name === 'truck_size_id') {
    return truckSizeOptions.find(option => option.value === old_value).label
  } else if (preference_name === 'hourly_travel_hours') {
    return roundedMinToDigits(estimated_travel_minutes)
  } else {
    return old_value
  }
}

export const roundedMinToDigits = (mins) => {
  return Math.round(mins/60 * 100) / 100
}

export const convertSubmittedHours = (value) => {
  return {hr: Math.floor(value), min: Math.round(value % 1 * 100) / 100}

}
export default movePlanReducer;