import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const mapStateToProps = ({funnel, hourlyFunnel, movePlan}) => ({
  currentStep: movePlan.currentPlan.hourly_plan? hourlyFunnel.currentConfirmationStep : funnel.currentConfirmationStep,
  partOneDone: funnel.confirmationSteps[0].completed,
  partTwoDone: funnel.confirmationSteps[1].completed && funnel.confirmationSteps[2].completed,
  partThreeDone: funnel.confirmationSteps[3].completed && funnel.confirmationSteps[4].completed && funnel.confirmationSteps[5].completed,
  partFourDone: funnel.confirmationSteps[6].completed && funnel.confirmationSteps[7].completed,
  hourly: movePlan.currentPlan.hourly_plan,
  hourlyPartOneDone: hourlyFunnel.confirmationSteps[0].completed,
  hourlyPartTwoDone: hourlyFunnel.confirmationSteps[1].completed && hourlyFunnel.confirmationSteps[2].completed,
  hourlyPartThreeDone: hourlyFunnel.confirmationSteps[3].completed && hourlyFunnel.confirmationSteps[4].completed,
});

const ConfirmationProgress = ({currentStep, partOneDone, partTwoDone, partThreeDone, partFourDone, 
  hourly, hourlyPartOneDone, hourlyPartTwoDone, hourlyPartThreeDone, hourlyPartFourDone}) => {
  return (
    <div className="confirmation-wizard-container">
      {hourly? 
        <div className="confirmation-steps">
          <div className="step-line-hourly" />
          <Link to="/1/basics" className="step-box">
            <p className={currentStep < 1 ? '' : 'inactive-text'}>Basics</p>
            <div className={`step-circle ${currentStep >= 0 && currentStep < 1 ? 'current' : 'doop'} ${hourlyPartOneDone ? 'completed' : ''}`}>1</div>
          </Link>
          <Link to="/2/coi" className="step-box">
            <p className={(currentStep > 0 && currentStep < 3) ? '' : 'inactive-text'}>Building</p>
            <div className={`step-circle ${currentStep >= 1 && currentStep < 3 ? 'current' : ''} ${hourlyPartTwoDone ? 'completed' : ''}`}>2</div>
          </Link>
          <Link to="/3/hourly" className="step-box">
            <p className={(currentStep > 2 && currentStep < 6) ? '' : 'inactive-text'}>Move Day</p>
            <div className={`step-circle ${currentStep >= 3 ? 'current' : ''} ${hourlyPartThreeDone ? 'completed' : ''}`}>3</div>
          </Link>
        </div>
        :
        <div className="confirmation-steps">
          <div className="step-line" />
          <Link to="/1/basics" className="step-box">
            <p className={currentStep < 1 ? '' : 'inactive-text'}>Basics</p>
            <div className={`step-circle ${currentStep >= 0 && currentStep < 1 ? 'current' : 'doop'} ${partOneDone ? 'completed' : ''}`}>1</div>
          </Link>
          <Link to="/2/coi" className="step-box">
            <p className={(currentStep > 0 && currentStep < 3) ? '' : 'inactive-text'}>Building</p>
            <div className={`step-circle ${currentStep >= 1 && currentStep < 3 ? 'current' : ''} ${partTwoDone ? 'completed' : ''}`}>2</div>
          </Link>
          <Link to="/3/specialhandling" className="step-box">
            <p className={(currentStep > 2 && currentStep < 6) ? '' : 'inactive-text'}>Services</p>
            <div className={`step-circle ${currentStep >= 3 && currentStep < 6 ? 'current' : ''} ${partThreeDone ? 'completed' : ''}`}>3</div>
          </Link>
          <Link to="/4/inventory" className="step-box">
            <p className={(currentStep > 5 && currentStep < 9) ? '' : 'inactive-text'}>Move Day</p>
            <div className={`step-circle ${currentStep >= 6 ? 'current' : ''} ${partFourDone ? 'completed' : ''}`}>4</div>
          </Link>
      </div>
      }
      
      <style jsx>
      {`
        .confirmation-steps {
          font-family: ProximaNova, sans-serif;
          color: #00c2eb;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          position: relative;
          width: 320px;
          max-width: 340px;
          height: 70px
        }
        .confirmation-steps p {
          margin-bottom: 0px;
          transition-duration: .3s;
          line-height: 2.4rem;
          white-space: nowrap;
        }
        .step-line {
          position: absolute;
          top: 56%;
          left: 10%;
          height: 1px;
          width: 80%;
          border-top: 1px solid #47bfe6;
          z-index: -1;
        }
        .step-line-hourly {
          position: absolute;
          top: 56%;
          left: 10%;
          height: 1px;
          width: 50%;
          border-top: 1px solid #47bfe6;
          z-index: -1;
        }
        .step-box {
          z-index: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 70px;
        }
        .step-box p {
          font-size: 1.4rem;
        }
        .step-circle {
          margin: 0 0 15px;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          border: 2px solid #c9c9c9;
          background: #eaeaea;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #c9c9c9;
          font-size: 1.3rem;
          transition-duration: .3s;
        }
        .completed {
          background: #eaeaea url("/assets/blue_checkmark.svg");
          background-size: 15px 15px;
          background-repeat: no-repeat;
          background-position: center;
          font-size: 0;
          border: 2px solid #47bfe6;
          color: #47bfe6;
          width: 30px;
          height: 30px;
          box-shadow: none;
          transition-duration: .3s;
        }
        .current {
          border: 2px solid #47bfe6;
          color: #47bfe6;
          width: 32px;
          height: 32px;
          box-shadow: 0 10px 15px rgba(0,0,0,0.12);
          transition-duration: .3s;
        }



        .inactive-text {
          color: #c9c9c9;
          transition-duration: .2s;
        }
      `}
      </style>
    </div>
  );
};

export default connect(mapStateToProps)(ConfirmationProgress);
