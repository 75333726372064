import {handleActions} from 'redux-actions';
import { SET_ALL_PLANS } from '../actionTypes';
import has from 'lodash/has';
import { isAfter, parse } from 'date-fns';

const initialState = {};

const myHomeReducer = handleActions({
  [SET_ALL_PLANS]: (state, {payload}) => ({
    ...state,
    userPlans: payload
  })
}, initialState);

export const hasUpcomingJob = (userPlans) => {
  if(has(userPlans, 'booked_move_plans')) {
    if(userPlans.booked_move_plans.length > 0) {
      return userPlans.booked_move_plans.map( plan => ( isAfter(parse(plan.move_date, "MM/DD/YYYY", Date.now())) ))[0];
    }
  }
  return false;
};

export default myHomeReducer;