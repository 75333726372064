import {
  BOOK,
  CHECK_PROMO, INIT_BOOK_PAGE,
  SET_BOOK_CONTACT, SET_CARD_ERRORS,
  SET_CLIENT_TOKEN,
  SET_COUPON_DATA, SET_HOSTED_FIELDS_INSTANCE,
  SET_INVALID_COUPON, SET_USER, SUBMIT_BOOK, TOGGLE_IS_SUBMITTING
} from '../actionTypes';

export const initBookPage = (movePlanId) => ({
  type: INIT_BOOK_PAGE,
  payload: movePlanId
});

export const checkPromo = (couponCode) => ({
  type: CHECK_PROMO,
  payload: couponCode
});

export const setCouponData = (data) => ({
  type: SET_COUPON_DATA,
  payload: data
});

export const setInvalidCoupon = (bool) => ({
  type: SET_INVALID_COUPON,
  payload: bool
});

export const setClientToken = (token) => ({
  type: SET_CLIENT_TOKEN,
  payload: token
});

export const setCardErrors = (errors) => ({
  type: SET_CARD_ERRORS,
  payload: errors
});

export const setUser = (user) =>({
  type:SET_USER,
  payload: user
});

export const setHostedFieldsInstance = (instance) => ({
  type: SET_HOSTED_FIELDS_INSTANCE,
  payload: instance
});

export const book = (payload) => ({
  type: BOOK,
  payload: payload
});

export const submitBook = (payload) => ({
  type: SUBMIT_BOOK,
  payload: payload
});

export const setBookContact = (payload) => ({
  type: SET_BOOK_CONTACT,
  payload: payload
});

export const toggleIsSubmitting = () => ({
  type: TOGGLE_IS_SUBMITTING,
});