import React from 'react';
import {addItem, removeItem} from "../../../redux/actions/inventory";
import {connect} from "react-redux";
import MyInventoryModal from "./MyInventoryModal";
import ConfirmInsuranceModal from "./ConfirmInsuranceModal";
import BoxesInfoModal from "./BoxesInfoModal";
import ConfirmComplete from "./ConfirmCompleteModal";
import ConfirmCompleteHourly from "./ConfirmCompleteHourlyModal";
import InventoryView from "./ViewInventoryModal";
import EmailShareModal from './EmailShareModal'
import ReuseInventoryModal from './ReuseInventoryModal'
import DeletePlanModal from './DeletePlanModal';
import EmailMoveChecklist from './EmailMoveChecklist';

const MODALS = {
  myInventory: MyInventoryModal,
  confirmInsuranceModal: ConfirmInsuranceModal,
  boxesInfo: BoxesInfoModal,
  confirmComplete: ConfirmComplete,
  ConfirmCompleteHourly: ConfirmCompleteHourly,
  inventoryView: InventoryView,
  emailShareModal: EmailShareModal,
  reuseInventory: ReuseInventoryModal,
  confirmDeletePlan: DeletePlanModal,
  emailMoveChecklist: EmailMoveChecklist
};

const Modal = ({ name, properties }) => {
  const Component = MODALS[name];
  return (
    <div className="center-modal">
      {!!properties
        ? <Component {...properties} />
        : <Component/>
      }
      <style jsx>{`
        .center-modal {
          max-height: calc(100% - 100px);
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 10001;
          background: white;
          overflow-y: auto;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);

        }
        @media screen and (max-width: 40em) {
          .center-modal {
            max-height: 85vh;
            position: fixed;
            z-index: 10000;
            background: white;
            overflow-y: auto;
            height: 100vh;
            width: 100vw;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
          }
        }

      `}</style>
    </div>)
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { addItem, removeItem })(Modal)
