import React from 'react';

const PageNotFound = () => (
  <div className="view-wrapper error-view-wrapper">
	  <div className="p-b-40">
      <div className="error-view page-view m-t-40">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 offset-xs-1 col-sm-6 offset-sm-3 m-t-20">
              <img className="center-block" src="https://unpakt-unpakt.netdna-ssl.com/client_assets/images/non-funnel/404.png" alt="404: Page Not Found"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PageNotFound;
