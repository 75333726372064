import {
  SET_CURRENT_MOVEPLAN_ID,
  GET_CURRENT_MOVEPLAN,
  SET_CURRENT_PLAN_INFO,
  ADD_BOX_PURCHASE,
  REMOVE_BOX_PURCHASE,
  SET_MOVE_DATE,
  GET_VALID_DATES,
  UPDATE_MOVE_DATE,
  SET_VALID_DATES,
  UPDATE_MOVE_PLAN,
  UPDATE_BOX_PURCHASES,
  UPDATE_PACKING_SERVICES,
  SET_COI_INFO,
  SET_TIME_WINDOW,
  CHECK_VALID_DATES,
  CHECK_MOVER_AVAILABILITY,
  PREPARE_RESCHEDULE_REQUEST,
  SET_RESCHEDULE_REQUEST,
  GET_ALL_MOVEPLANS,
  GET_MOVEPLAN,
  EMAIL_PLAN,
  ADDRESS_UPDATE,
  REQUEST_INSURANCE,
  SET_MOVEPLAN_INSURANCE,
  CANCEL_MOVE,
  INIT_BOX_PURCHASES,
  CLEAR_BOX_PURCHASES,
  CREATE_MOVEPLAN,
  CREATE_MOVEPLAN_FOR_PARTNER,
  UPDATE_COI,
  UPDATE_ELEVATORS,
  SET_MOVEPLAN_LOADING,
  SUBMIT_MOVE_DAY_FEEDBACK,
  SET_SUBMIT_FEEDBACK_RESPONSE,
  COMPLETE_CONFIRMATION_FUNNEL,
  COMPLETE_HOURLY_CONFIRMATION_FUNNEL,
  UPDATE_CONFIRMATION_FUNNEL,
  UPDATE_HOURLY_CONFIRMATION_FUNNEL,
  SET_MOVE_PLAN_API_ERROR,
  SUBMIT_DNA,
  CONTINUE_LAST_PLAN,
  UPDATE_NUM_HOURS,
  UPDATE_NUM_LABORERS,
  UPDATE_TRUCK_SIZE,
  UPDATE_NUM_UPRIGHTS,
  UPDATE_HOURLY_STAIRS,
  UPDATE_NUM_HOURLY_OVERSIZE,
  UPDATE_NUM_BABY_GRAND,
  UPDATE_NUM_HOURLY_OVERWEIGHT,
  SET_HOURLY_UPDATE_LOADING
} from '../actionTypes';

export const createMovePlan = params => ({
  type: CREATE_MOVEPLAN,
  payload: params
});

export const createForPartner = params => ({
  type: CREATE_MOVEPLAN_FOR_PARTNER,
  payload: params
});

export const updateMovePlan = (newParams,
  redirect) => ({
  type: UPDATE_MOVE_PLAN,
  payload: newParams,
  meta: !!redirect ? { redirect: redirect } : {}
});

export const updateMP = (newParams) => {
  return dispatch => {
    dispatch(updateMovePlan(newParams))
  }
}

export const setCurrentMoveplanID = movePlanId => ({
  type: SET_CURRENT_MOVEPLAN_ID,
  payload: { movePlanId }
});

export const getAllMoveplans = (redirect) => ({
  type: GET_ALL_MOVEPLANS,
  meta: !!redirect ? { redirect: redirect } : {}
});

export const getCurrentMoveplan = () => ({
  type: GET_CURRENT_MOVEPLAN
});

export const getMoveplan = (uuid) => ({
  type: GET_MOVEPLAN,
  payload: uuid
});

export const setCurrentPlanInfo = plan => ({
  type: SET_CURRENT_PLAN_INFO,
  payload:  { plan }
});

export const addBoxPurchase = boxId => ({
  type: ADD_BOX_PURCHASE,
  payload: boxId
});

export const removeBoxPurchase = boxId => ({
  type: REMOVE_BOX_PURCHASE,
  payload: boxId
});

export const updateMoveDate = move_date => ({
  type: UPDATE_MOVE_DATE,
  payload: { move_date }
});

export const setMoveDate = moveDate => ({
  type: SET_MOVE_DATE,
  payload: moveDate
});

export const getValidDates = () => ({
  type: GET_VALID_DATES
});

export const setValidDates = validDates => ({
  type: SET_VALID_DATES,
  payload: validDates
});

export const updateBoxPurchases = (boxPurchases, date) => ({
  type: UPDATE_BOX_PURCHASES,
  payload: {date: date, boxPurchases: boxPurchases}
});

export const updatePackingServices = serviceNumber => ({
  type: UPDATE_PACKING_SERVICES,
  payload: serviceNumber
});

export const setCoiInfo = (coiInfo) => ({
  type: SET_COI_INFO,
  payload: coiInfo
});

export const setTimeWindow = (timeWindow) => ({
  type: SET_TIME_WINDOW,
  payload: timeWindow
});

export const checkValidDates = (params) => ({
  type: CHECK_VALID_DATES,
  payload: params
});

export const checkMoverAvailability = (params) => ({
  type: CHECK_MOVER_AVAILABILITY,
  payload: params
});

export const prepareRescheduleRequest = (params) => ({
  type: PREPARE_RESCHEDULE_REQUEST,
  payload: params
});

export const setRescheduleRequest = (params) => ({
  type: SET_RESCHEDULE_REQUEST,
  payload: params
});

export const emailPlan = (email) => ({
  type: EMAIL_PLAN,
  payload: email
});

export const addressUpdate = (addressKey, route) => ({
  type: ADDRESS_UPDATE,
  payload: { addressKey: addressKey, route: route }
});

export const requestInsurance = () => ({
  type: REQUEST_INSURANCE
});

export const setMoveplanInsurance = (bool) =>({
  type: SET_MOVEPLAN_INSURANCE,
  payload: bool
});

export const cancelMove = () => ({
  type: CANCEL_MOVE
});

export const initBoxPurchases = (inventoryBoxes) => ({
  type: INIT_BOX_PURCHASES,
  payload: inventoryBoxes
});

export const clearBoxPurchases = () => ({
  type: CLEAR_BOX_PURCHASES
});

export const updateCoi = (payload, redirect) => ({
  type: UPDATE_COI,
  payload: payload,
  meta: !!redirect ? { redirect: redirect } : {}
})

export const updateElevators = payload => ({
  type: UPDATE_ELEVATORS,
  payload: payload
})

export const setMoveplanLoading = bool => ({
  type: SET_MOVEPLAN_LOADING,
  payload: bool
});

export const submitMoveDayFeedback = payload => ({
  type: SUBMIT_MOVE_DAY_FEEDBACK,
  payload: payload
});

export const setSubmitFeedbackResponse = (response) => ({
  type: SET_SUBMIT_FEEDBACK_RESPONSE,
  payload: response
});

export const completeConfirmationFunnel = () => ({
  type: COMPLETE_CONFIRMATION_FUNNEL,
});
export const completeHourlyConfirmationFunnel = () => ({
  type: COMPLETE_HOURLY_CONFIRMATION_FUNNEL,
});
export const updateConfirmationFunnel = (payload) => ({
  type: UPDATE_CONFIRMATION_FUNNEL,
  payload: payload
});

export const updateHourlyConfirmationFunnel = (payload) => ({
  type: UPDATE_HOURLY_CONFIRMATION_FUNNEL,
  payload: payload
});

export const setMoveplanApiError = error => ({
  type: SET_MOVE_PLAN_API_ERROR,
  payload: error
});

export const submitDNA = payload => ({
  type: SUBMIT_DNA,
  payload: payload
});

export const continueLastPlan = () => ({
  type: CONTINUE_LAST_PLAN
});

// Hourly Preferences
export const setHourlyUpdateLoading = bool => ({
  type: SET_HOURLY_UPDATE_LOADING,
  payload: bool
});

export const updateHourlyTruckSize = truckSize => ({
  type: UPDATE_TRUCK_SIZE,
  payload: truckSize
});

export const updateHourlyNumHours = hours => ({
  type: UPDATE_NUM_HOURS,
  payload: hours
});

export const updateHourlyNumLabor = laborers => ({
  type: UPDATE_NUM_LABORERS,
  payload: laborers
});

export const updateHourlyStairs = stairs => ({
  type: UPDATE_HOURLY_STAIRS,
  payload: stairs
});


export const updateHourlyNumUprights = uprights => ({
  type: UPDATE_NUM_UPRIGHTS,
  payload: uprights
});


export const updateHourlyNumBabyGrands = baby_grands => ({
  type: UPDATE_NUM_BABY_GRAND,
  payload: baby_grands
});


export const updateNumHourlyOverWeight = num_hourly_overweight => ({
  type: UPDATE_NUM_HOURLY_OVERWEIGHT,
  payload: num_hourly_overweight
});


export const updateNumHourlyOverSize = num_hourly_oversize => ({
  type: UPDATE_NUM_HOURLY_OVERSIZE,
  payload: num_hourly_oversize
});