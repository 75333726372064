import { INIT_DETAILS_PAGE, SUBMIT_LOCATION, SUBMIT_BASICS } from '../actionTypes';
import { goToLastStep, setCurrentStep } from '../actions/funnel';
import {
  checkValidDates,
  getCurrentMoveplan, setCoiInfo, setCurrentMoveplanID,
  setRescheduleRequest,
  updateMovePlan
} from '../actions/movePlan';
import { setDetailsLoading } from '../actions/details';
import { FUNNEL_STEPS } from '../../constants';
import { format } from 'date-fns';
import { setMoveplanLoading } from './../actions/movePlan';

const detailsMiddleware = ({ getState, dispatch }) => next => action => {
  next(action);
  if(action.type === INIT_DETAILS_PAGE) {
    dispatch(setDetailsLoading(true));
    dispatch(setCurrentStep(FUNNEL_STEPS.DETAILS));
    dispatch(setCurrentStep('DETAILS'));
    if(getState().movePlan.currentMPID !== action.payload.movePlanId) {
      dispatch(setCurrentMoveplanID(action.payload.movePlanId));
    }
    dispatch(getCurrentMoveplan(getState().movePlan.currentMPID));
  }

  if(action.type === SUBMIT_LOCATION) {
    let formData = action.payload;
    let { details } = getState().movePlan.currentPlan;
    let payload = {
      home_size_id: parseInt(formData.home_size.value, 10),
      move_date: formData.date.format('MM/DD/YYYY'),
      move_time: formData.move_time,
    };
    payload.pick_up = {
      street_address: formData.pick_up.street_address,
      apartment_number: !!formData.pick_up.apt ? formData.pick_up.apt : null,
      city: formData.pick_up.city,
      state: formData.pick_up.state.value,
      zip: formData.pick_up.zip,
      geocoded_address: formData.pick_up.geocoded_address,
      height_id: parseInt(formData.pick_up.height.value, 10),
      certificate_of_insurance_required: !!formData.pick_up.coi,
      latitude: formData.pick_up.latitude,
      longitude: formData.pick_up.longitude
    };

    payload.drop_off = {
      street_address: formData.drop_off.street_address,
        apartment_number: !!formData.drop_off.apt ? formData.drop_off.apt : null,
        city: formData.drop_off.city,
        state: formData.drop_off.state.value,
        zip: formData.drop_off.zip,
        geocoded_address: formData.drop_off.geocoded_address,
        height_id: parseInt(formData.drop_off.height.value, 10),
        certificate_of_insurance_required: !!formData.drop_off.coi,
        latitude: formData.drop_off.latitude,
        longitude: formData.drop_off.longitude
    };

    if(!!formData.extra_pick_up_enabled) {
      payload.extra_pick_up = {
        street_address: formData.extra_pick_up.street_address,
        apartment_number: !!formData.extra_pick_up.apt ? formData.extra_pick_up.apt : null,
        city: formData.extra_pick_up.city,
        state: formData.extra_pick_up.state.value,
        zip: formData.extra_pick_up.zip,
        geocoded_address: formData.extra_pick_up.geocoded_address,
        height_id: parseInt(formData.extra_pick_up.height.value, 10),
        certificate_of_insurance_required: !!formData.extra_pick_up.coi,
        latitude: formData.extra_pick_up.latitude,
        longitude: formData.extra_pick_up.longitude
      };
      if(!!formData.extra_pick_up.elevator) {
        payload.extra_pick_up.elevator_start_time = details.extra_pick_up.elevator_start_time;
        payload.extra_pick_up.elevator_end_time = details.extra_pick_up.elevator_end_time;
      }
      if(!!formData.extra_pick_up.coi) {
        payload.extra_pick_up.management_contact_name = details.extra_pick_up.management_contact_name;
        payload.extra_pick_up.management_phone_number = details.extra_pick_up.management_phone_number;
      } else {
        dispatch(
          setCoiInfo({
            addressKey: 'extra_pick_up',
            certificate_of_insurance_required: false,
            management_contact_name: null,
            management_phone_number: null
          })
        )
      }
    }

    if(formData.extra_drop_off_enabled) {
      payload.extra_drop_off = {
        street_address: formData.extra_drop_off.street_address,
        apartment_number: !!formData.extra_drop_off.apt ? formData.extra_drop_off.apt : null,
        city: formData.extra_drop_off.city,
        state: formData.extra_drop_off.state.value,
        zip: formData.extra_drop_off.zip,
        geocoded_address: formData.extra_drop_off.geocoded_address,
        height_id: parseInt(formData.extra_drop_off.height.value, 10),
        certificate_of_insurance_required: !!formData.extra_drop_off.coi,
        latitude: formData.extra_drop_off.latitude,
        longitude: formData.extra_drop_off.longitude
      };
      if(!!formData.extra_drop_off.elevator) {
        payload.extra_drop_off.elevator_start_time = details.extra_drop_off.elevator_start_time;
        payload.extra_drop_off.elevator_end_time = details.extra_drop_off.elevator_end_time;
      }
      if(!!formData.extra_drop_off.coi) {
        payload.extra_drop_off.management_contact_name = details.extra_drop_off.management_contact_name;
        payload.extra_drop_off.management_phone_number = details.extra_drop_off.management_phone_number;
      } else {
        dispatch(
          setCoiInfo({
            addressKey: 'extra_pick_up',
            certificate_of_insurance_required: false,
            management_contact_name: null,
            management_phone_number: null
          })
        )
      }
    }


    if(!!formData.user_note) { payload.user_note = formData.user_note }
    if(!!formData.email_lead) { payload.email_lead = formData.email_lead }

    if(!!formData.pick_up.elevator) {
      payload.pick_up.elevator_start_time = details.pick_up.elevator_start_time;
      payload.pick_up.elevator_end_time = details.pick_up.elevator_end_time;
    }
    if(!!formData.drop_off.elevator) {
      payload.drop_off.elevator_start_time = details.drop_off.elevator_start_time;
      payload.drop_off.elevator_end_time = details.drop_off.elevator_end_time;
    }
    if(!!formData.pick_up.coi) {
      payload.pick_up.management_contact_name = details.pick_up.management_contact_name;
      payload.pick_up.management_phone_number = details.pick_up.management_phone_number;
    }
    if(!!formData.drop_off.coi) {
      payload.drop_off.management_contact_name = details.drop_off.management_contact_name;
      payload.drop_off.management_phone_number = details.drop_off.management_phone_number;
    }

    if(formData.storage_type.move_into_storage.value && formData.storage_enabled) {
      payload.move_into_storage = true
    }
    if(formData.storage_type.storage_in_transit.value && formData.storage_enabled) {
      payload.storage_move_out_date = formData.storage_move_out_date.format('MM/DD/YYYY');
      payload.storage_in_transit = true;
    }

    if(details['pick_up'].certificate_of_insurance_required !== formData['pick_up'].coi) {
      dispatch(setCoiInfo({
        addressKey: 'pick_up',
        certificate_of_insurance_required: formData['pick_up'].coi,
        management_contact_name: formData['pick_up'].management_contact_name,
        management_phone_number: formData['pick_up'].management_phone_number
      }))
    }
    if(details['drop_off'].certificate_of_insurance_required !== formData['drop_off'].coi) {
      dispatch(setCoiInfo({
        addressKey: 'drop_off',
        certificate_of_insurance_required: formData['drop_off'].coi,
        management_contact_name: formData['drop_off'].management_contact_name,
        management_phone_number: formData['drop_off'].management_phone_number
      }))
    }


    // Check if this is a reschedule request for storage

    if( formData.storage_move_out_date !== getState().movePlan.currentPlan.details.storage_move_out_date
      && formData.storage_type.storage_in_transit.clicked) {
      dispatch(checkValidDates(formData.storage_move_out_date.format('MM/DD/YYYY')));
      dispatch(setRescheduleRequest({ move_date: formData.storage_move_out_date.format('MM/DD/YYYY')}));
    } else {
      // TODO: check that Amery fixed the dang endpoint
      console.log(payload);
      dispatch(updateMovePlan({ address_update: true, move_plan: payload}));

      // TODO: start loader here
      dispatch(goToLastStep());
      dispatch(setDetailsLoading(false))
    }
  }
  if (action.type === SUBMIT_BASICS) {
    dispatch(setMoveplanLoading(true));
    const formData = action.payload;
    const details = getState().movePlan.currentPlan.details;
    let addressUpdate = !!formData.drop_off_key || !!formData.pick_up_key || !!formData.extra_pick_up_key || formData.extra_drop_off_key || (formData.extra_drop_off_enabled !== details.extra_drop_off_enabled) || (formData.extra_pick_up_enabled !== details.extra_pick_up_enabled);

    let payload = {
      home_size_id: parseInt(formData.home_size.value, 10),
      move_date: format(formData.date, 'MM/DD/YYYY'),
      move_time: formData.move_time,
      extra_drop_off_enabled: formData.extra_drop_off_enabled,
      extra_pick_up_enabled: formData.extra_pick_up_enabled
    };

    if( !!formData.pick_up_key ) {
      payload.pick_up = {
        key: formData.pick_up_key,
        height_id: parseInt(formData.pick_up.height.value, 10),
        apartment_number: formData.pick_up.apartment_number
      }
    } else if(formData.pick_up.height.value !== details.pick_up.height_id ||
      formData.pick_up.apartment_number !== details.pick_up.apartment_number) {
      payload.pick_up = {
        ...details.pick_up,
        apartment_number: formData.pick_up.apartment_number,
        height_id: parseInt(formData.pick_up.height.value)
      }
      addressUpdate = true
    } else {
      // this is the worst endpoint imaginable...
      payload.pick_up = details.pick_up
    }

    if (!(formData.storage_enabled && formData.storage_type.move_into_storage.value)) {
      if( !!formData.drop_off_key ) {
        payload.drop_off = {
          key: formData.drop_off_key,
          height_id: parseInt(formData.drop_off.height.value, 10),
          apartment_number: formData.drop_off.apartment_number
        }
      } else if(formData.drop_off.height.value !== details.drop_off.height_id ||
        formData.drop_off.apartment_number !== details.drop_off.apartment_number) {
        payload.drop_off = {
          ...details.drop_off,
          apartment_number: formData.drop_off.apartment_number,
          height_id: parseInt(formData.drop_off.height.value)
        }
        addressUpdate = true
      } else {
        // this is the worst endpoint imaginable...
        payload.drop_off = details.drop_off
      }
    }

    if(formData.extra_pick_up_enabled) {
      if( formData.extra_pick_up_key) {
        payload.extra_pick_up_enabled = true
        payload.extra_pick_up = {
          key: formData.extra_pick_up_key,
          height_id: parseInt(formData.extra_pick_up.height.value, 10),
          apartment_number: formData.extra_pick_up.apartment_number
        }
      } else if(formData.extra_pick_up.height.value !== details.extra_pick_up.height_id ||
        formData.extra_pick_up.apartment_number !== details.extra_pick_up.apartment_number) {
        payload.extra_pick_up = {
          ...details.extra_pick_up,
          apartment_number: formData.extra_pick_up.apartment_number,
          height_id: parseInt(formData.extra_pick_up.height.value)
        }
        addressUpdate = true
      } else {
        payload.extra_pick_up_enabled = false
      }
    }
    if(formData.extra_drop_off_enabled) {
      if(formData.extra_drop_off_key) {
        payload.extra_drop_off_enabled = true
        payload.extra_drop_off = {
          key: formData.extra_drop_off_key,
          height_id: parseInt(formData.extra_drop_off.height.value, 10),
          apartment_number: formData.extra_drop_off.apartment_number
        }
      } else if(formData.extra_drop_off.height.value !== details.extra_drop_off.height_id ||
        formData.extra_drop_off.apartment_number !== details.extra_drop_off.apartment_number) {
        payload.extra_drop_off = {
          ...details.extra_drop_off,
          apartment_number: formData.extra_drop_off.apartment_number,
          height_id: parseInt(formData.extra_drop_off.height.value)
        }
        addressUpdate = true
      } else {
        payload.extra_drop_off_enabled = false
      }
    }

    if(formData.storage_enabled) {
      if( !!formData.storage_type.storage_in_transit.value ) {
        payload = {
          ...payload,
          storage_in_transit: true,
          move_into_storage: false,
          warehouse_destination: false,
          storage_move_out_date: format(formData.storage_move_out_date, "MM/DD/YYYY")
        }
      } else if( !!formData.storage_type.move_into_storage.value) {
        delete payload.drop_off;
        delete payload.extra_drop_off;
        payload = {
          ...payload,
          storage_in_transit: false,
          storage_move_out_date: null,
          move_into_storage: true,
          warehouse_destination: true
        }
      }
    } else {
      payload = {
        ...payload,
        storage_in_transit: false,
        storage_move_out_date: null,
        move_into_storage: false,
        warehouse_destination: false
      }
    }
    dispatch(updateMovePlan({ address_update: addressUpdate, move_plan: payload }, '/1/basics'))
  }
};


export default detailsMiddleware;
