import {
  ADD_NOTE, APPLY_COUPON, DELETE_ADJUSTMENT, DELETE_NOTE,
  FINALIZE_PLAN, GET_HISTORY, GET_NOTES,
  INIT_PLAN,
  INIT_PLAN_FINISHED, INIT_PRINT_PLAN,
  INIT_ROUTES, NETWORK_SUCCESS,
  TOGGLE_STORAGE_PLAN, UPDATE_ADJUSTMENT, INIT_SPECIAL_HANDLING
} from '../actionTypes';
import { getCurrentMoveplan, setCurrentMoveplanID, updateMovePlan, setMoveplanLoading } from '../actions/movePlan';
import { push } from "connected-react-router";
import { gotoBook, setCurrentStep } from '../actions/funnel';
import { fetchInventory } from '../actions/inventory';
import {
  getNotes,
  setAdjustmentLoading,
  setAdminNotes,
  setHistory,
  setHistoryUpdating,
  setMoverNotes,
  setNotesUpdating,
  setRoutes,
  setShowStorage,
  setPlanUpdating,
  updateCoupon,
  setSelectedTabPlan,
  initRoutes,
} from '../actions/plan';
import { getPricingBreakdown } from '../actions/compare';
import { format, addDays} from 'date-fns';
import { apiRequest } from '../actions/api';
import has from 'lodash/has';

const planMiddleware = ({ getState, dispatch }) => next => action => {
  next(action);

  if(action.type === INIT_PLAN) {
    dispatch(setPlanUpdating(true));
    dispatch(setCurrentStep('PLAN'));
    dispatch(setSelectedTabPlan('plan'));
    if(getState().movePlan.currentMPID !== action.payload.movePlanId) {
      dispatch(setCurrentMoveplanID(action.payload.movePlanId));
    }
    dispatch(getCurrentMoveplan());
    dispatch(fetchInventory(action.payload.movePlanId));
  }

  if( action.type === INIT_SPECIAL_HANDLING) {
    dispatch(setMoveplanLoading(true));
    dispatch(getCurrentMoveplan(getState().movePlan.currentMPID));
    dispatch(fetchInventory(getState().movePlan.currentMPID))
  }
  
  if(action.type === INIT_PRINT_PLAN) {
    dispatch(initRoutes());
    if(has(getState().movePlan.currentPlan, 'mover')) {
      dispatch(getPricingBreakdown(getState().movePlan.currentPlan.mover.id));
    }
  }

  if(action.type === INIT_PLAN_FINISHED) {
    if(has(getState().movePlan.currentPlan, 'details')) {
      if(!!getState().movePlan.currentPlan.details.storage_in_transit) {
        dispatch(setShowStorage(true));
      }
    }
    dispatch(getPricingBreakdown(getState().movePlan.currentPlan.mover.id));
    dispatch(setPlanUpdating(false));
  }

  if(action.type === FINALIZE_PLAN) {
    if (getState().movePlan.currentPlan.is_booked) {
      dispatch(push(`/moving/${getState().movePlan.currentMPID}/billing`));
    } else {
      // TODO: implement loader start here
      //Loader.start('book')
      dispatch(gotoBook());
    }
  }

  if(action.type === INIT_ROUTES) {
    // TODO: extract this to environment variables
    let routesPrefix = "https://maps.googleapis.com/maps/api/staticmap?scale=2&size=400x200&maptype=roadmap&zoom=16";
    let mapStyles = "style=weight:1|hue:0x00c3ff|gamma:0.84|saturation:53|lightness:-16&style=feature:landscape|element:geometry.fill|weight:1|hue:0x00c3ff|gamma:0.84|saturation:53|lightness:-16";
    let labelStyles = "style=feature:all|element:labels.text.fill|visibility:on|color:0x0a698f";
    let iconStyle = "markers=icon:https://unpakt.com/client_assets/images/plan/map-marker.png";
    let mapKey = "AIzaSyAY2EUHP3otWWkaF01lJS6cVEY091aq6Cg";

    dispatch(setRoutes({
      pick_up: {
        enabled: true,
        cssClass: 'from',
        displayName: 'Moving From',
        loading: false,
        bodyEnabled: true,
        altBodyText: '',
        mapUrl: `${routesPrefix}&${mapStyles}&${labelStyles}&${iconStyle}|${getLatLngStr(getState().movePlan.currentPlan.details['pick_up'].latitude , getState().movePlan.currentPlan.details['pick_up'].longitude)}&key=${mapKey}`
      },
      drop_off:{
        enabled: true,
        cssClass: 'to',
        displayName: 'Moving To',
        loading: false,
        bodyEnabled: !getState().movePlan.currentPlan.details.move_into_storage,
        altBodyText: 'Storage',
        mapUrl: `${routesPrefix}&${mapStyles}&${labelStyles}&${iconStyle}|${getLatLngStr(getState().movePlan.currentPlan.details['drop_off'].latitude , getState().movePlan.currentPlan.details['drop_off'].longitude)}&key=${mapKey}`
      },
      extra_pick_up:{
        enabled: getState().movePlan.currentPlan.details.extra_pick_up_enabled,
        cssClass: 'extra-from',
        displayName: 'Extra pickup',
        loading: false,
        bodyEnabled: true,
        altBodyText: '',
        mapUrl: `${routesPrefix}&${mapStyles}&${labelStyles}&${iconStyle}|${getLatLngStr(getState().movePlan.currentPlan.details['extra_pick_up'].latitude , getState().movePlan.currentPlan.details['extra_pick_up'].longitude)}&key=${mapKey}`
      },
      extra_drop_off:{
        enabled: getState().movePlan.currentPlan.details.extra_drop_off_enabled,
        cssClass: 'extra-to',
        displayName: 'Extra drop off',
        loading: false,
        bodyEnabled: true,
        altBodyText: '',
        mapUrl: `${routesPrefix}&${mapStyles}&${labelStyles}&${iconStyle}|${getLatLngStr(getState().movePlan.currentPlan.details['extra_drop_off'].latitude , getState().movePlan.currentPlan.details['extra_drop_off'].longitude)}&key=${mapKey}`
      }
    }))
  }

  if(action.type === TOGGLE_STORAGE_PLAN) {
    let hasStorage = getState().movePlan.currentPlan.details.storage_in_transit;
    dispatch(updateMovePlan({
      move_plan:{
        storage_in_transit: !hasStorage,
        storage_move_out_date: !hasStorage
          ? format(addDays(Date.parse(getState().movePlan.currentPlan.details.move_date, 'MM/DD/YYYY')), 'MM/DD/YYYY')
          : null
      }
    }))
  }

  if(action.type === GET_HISTORY) {
    dispatch(setHistoryUpdating(true));
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/history.json`,
        method: 'GET',
        fromAction: GET_HISTORY
      })
    )
  }

  if(action.type === GET_NOTES) {
    dispatch(setNotesUpdating(true));
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/notes`,
        method: 'GET',
        params: {
          type: action.payload
        },
        fromAction: GET_NOTES
      })
    );
  }

  if(action.type === ADD_NOTE) {
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/notes`,
        method: 'POST',
        params: {
          note: {
            content: action.payload,
            updated_at: format(Date.now(),'YYYY/MM/DD'),
            admin_generated: getState().auth.isAdmin
          }
        },
        fromAction: ADD_NOTE
      })
    );
  }

  if(action.type === DELETE_NOTE) {
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/notes/${action.payload}`,
        method: 'DELETE',
        fromAction: GET_NOTES
      })
    );
  }

  if(action.type === UPDATE_ADJUSTMENT) {
    dispatch(setAdjustmentLoading(true));
    dispatch(
      apiRequest({
        url: `api/v3/admin/admin_adjustments`,
        method: 'POST',
        params: {
          admin_adjustment: action.payload,
          move_plan_id: getState().movePlan.currentMPID
        },
        fromAction: UPDATE_ADJUSTMENT
      })
    );
  }

  if(action.type === DELETE_ADJUSTMENT) {
    dispatch(setAdjustmentLoading(true));
  }

  if(action.type === APPLY_COUPON) {
    dispatch(updateCoupon({ key: 'loading', value: true}));
    dispatch(updateCoupon({ key: 'couponApplied', value: false}));
    dispatch(updateCoupon({ key: 'couponInvalid', value: false}));
    dispatch(
      apiRequest({
        url: `api/v3/jobs/${getState().movePlan.currentPlan.job.id}/apply_coupon`,
        method: 'PATCH',
        params: {
          coupon_code: getState().plan.coupon.promoCode
        },
        fromAction: APPLY_COUPON
      })
    );
  }

  if (action.type === NETWORK_SUCCESS) {
    switch (action.meta.originalRequest.fromAction) {
      case GET_HISTORY:
        dispatch(setHistory(action.payload.response.data.history));
        dispatch(setHistoryUpdating(false));
        break;
      case ADD_NOTE:
        dispatch(getNotes(getState().auth.isAdmin ? 'admin' : 'mover'));
        break;
      case DELETE_NOTE:
        dispatch(getNotes(getState().auth.isAdmin ? 'admin' : 'mover'));
        break;
      case GET_NOTES:
        getState().plan.selectedTab === 'admin' && getState().auth.isAdmin
          ? dispatch(setAdminNotes(action.payload.response.data.notes))
          : dispatch(setMoverNotes(action.payload.response.data.notes));
        dispatch(setNotesUpdating(false));
        break;
      case DELETE_ADJUSTMENT:

        dispatch(setAdjustmentLoading(false));
        break;
      case UPDATE_ADJUSTMENT:
        dispatch(getCurrentMoveplan());
        dispatch(setAdjustmentLoading(false));
        break;
      case APPLY_COUPON:
        dispatch(updateCoupon({ key: 'loading', value: true}));
        //todo: if payload promoCode is a success,
        // dispatch(updateCoupon({ key: 'couponApplied', value: true}));
        // else
        // dispatch(updateCoupon({ key: 'couponInvalid', value: true}));
        break;
      default:
        break;
    }
  }
};

const getLatLngStr = (lat, lng) => {
  if(!!!lat || !!! lng) {
    return ""
  } else {
    return `${lat}, ${lng}`
  }
};



export default planMiddleware;
