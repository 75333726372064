import React, {useEffect, useState} from 'react';

const ScriptLoadContainer = (props) => {
  const [status, setStatus] = useState('start');
  let { src, srcId } = props;

  useEffect(() => {
    const do_load = (inputScript) => {
      inputScript.then(function() {
        setStatus('done');
      }).catch(function() {
        setStatus('error');
      })
    };
    const new_script = (src) => {
      return new Promise(function(resolve, reject){
        let script = document.createElement('script');
        script.src = src;
        script.defer = 'defer';
        script.async = 'true'
        script.crossorigin="anonymous"
        script.addEventListener('load', function () {
          resolve();
        });
        script.addEventListener('error', function (e) {
          reject(e);
        });
        if(!!srcId) script.id = srcId;
        document.body.appendChild(script);
      })
    }
    
    if(!!window.google && props.script === "google") {
      setStatus('done');
    } else if(!!window.loadSplititPaymentForm && props.script === "splitit") {
      setStatus('done');
    } else {
      let inputScript = new_script(src);
      setStatus('loading');
      setTimeout(function () {
        do_load(inputScript)
      }, 0);
    }
  }, [src, setStatus, srcId, props.script])

  return status === 'done' && <>{props.children}</>;
}

export default ScriptLoadContainer;