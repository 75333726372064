import React from "react";
import { connect } from "react-redux";
import { closeModal } from "../../../redux/actions/modals";
import DatePicker from "react-datepicker/es";
import { addDays, addYears } from "date-fns";
import Placeitron from "../../common/Placeitron";
import ScriptLoadContainer from "../ScriptLoadContainer";
import "react-datepicker/dist/react-datepicker.css";
import ReactLoading from 'react-loading';

const ReuseInventoryModalForm = props => {
  const {
    errors,
    values,
    setFieldValue,
    setFieldError,
    handleSubmit,
    isSubmitting,
    closeModal
  } = props;
  return (
    <form onSubmit={handleSubmit}>
      <header className="top-action-panel reuse-model">
        <button className="close-reveal-modal" onClick={() => closeModal()}>
          ✖
        </button>
      </header>
      <div className="modal-body reuse-model">
        <header className="modal-header">
          <h3 className="title">Reuse Inventory</h3>
        </header>
        <ScriptLoadContainer script="google" src={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`}>
          <div className="sidebar-form-row moving-from w-100 mb-3">
          { errors.pick_up_address && <span className="field-error-text">{errors.pick_up_address}</span> }
            <Placeitron
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              classes={'m-b-10'}
              addressKey="pick_up"
              error={errors.pick_up_address}
              placeholder="Moving From Address"
              exactAddress={false}
              icon={"from-arrow"}
              required={true} />
          </div>
          <div className="sidebar-form-row to w-100 mb-3">
          { errors.pick_up_address && <span className="field-error-text">{errors.drop_off_address}</span> }
            <Placeitron
              setFieldValue={setFieldValue}
              setFieldError={setFieldError}
              classes={`m-b-10`}
              addressKey="drop_off"
              error={errors.drop_off_address}
              placeholder="Moving To Address"
              exactAddress={false}
              icon={"to-arrow"}
              required={true} />
          </div>
        </ScriptLoadContainer>
        <div className="move-date-container move-date-reuse">
          <label className="sidebar-label">Move Date:</label>
          <div className="datepicker-wrapper pika-static-parent">
            <svg className="icon icon-calendar">
              <use xlinkHref="#icon-calendar-2" />
            </svg>

            <DatePicker
              selected={values.move_date}
              onChange={date => setFieldValue("move_date", date)}
              placeholderText={"Select Date"}
              minDate={addDays(Date.now(), 2)}
              maxDate={addYears(Date.now())}
              dropdownMode={"select"}
              withPortal
            />
          </div>
        </div>
      </div>
      <div className="m-5">
        
          <button
            type="submit"
            disabled={isSubmitting}
            className="primary-btn-small m-auto w-100"
          >
            {!!!isSubmitting
              ? 'Start a New Move Plan'
              : <ReactLoading className="button-spinner" type={"spinningBubbles"} color={"#ffffff"} height={30} width={30}/>
            }
          </button>
      </div>

      <style jsx>
        {/* CSS */`
          .modal-header {
            margin-bottom: 20px;
            font-size: 1rem;
            padding: 0;
            display: flex;
            justify-content: center;
            border: none;
          }
          .modal-body {
            padding: 0 3rem 20px;
          }
          .sidebar-form-row > :global(.inner-form-row) {
            width: 100%;
          }
          .sidebar-form-row :global(input),
          :global(.react-datepicker__input-container) > :global(input) {
            padding-left: 35px;
            background: #fff;
            width: 100%;
            min-height: 34px;
            font-size: 14px;
            line-height: 20px;
            padding: 8px 40px;
            height: 34px;
          }
          .sidebar-form-row,
          .datepicker-wrapper {
            position: relative;
          }
          :global(.react-datepicker__input-container) {
            width: 100%;
          }
          svg {
            position: absolute;
            left: 10px;
            top: 8px;
          }
          .datepicker-wrapper svg {
            z-index: 3;
          }

          .primary-btn-small {
            height: 34px;
            font-size: 1.6rem;
          }

          .field-error-text {
            color: red;

          }
          

          // :global(.placeify) {
          //   position: relative;
          // }
          // :global(.placeify:before) {
          //   position: absolute;
          //   left: 3px;
          //   top: 8px;
          //   font-size: 22px;
          //   color: #91a4aa;
          // }
          // :global(.placeify ul) {
          //   list-style: none;
          //   margin: 0;
          // }

          :global(.predictions-dropdown) {
            position: absolute;
            top: 34px;
            z-index: 1000;
            background: #fff;
            width: 100%;
            border: solid #829ca1;
            border-width: 0 1px;
            border-radius: 0 0 2px 2px;
            transition: opacity 0.2s ease-in;
            cursor: pointer;
          }
          :global(.prediction-item) {
            position: relative;
            transition: background 0.2s ease-in-out;
            padding: 8px;
            font-size: 13px;
            line-height: 18px;
            border-bottom: 1px solid #e9e9e9;
            text-decoration: none;
          }
          :global(.prediction-item:before) {
            color: #91a4aa;
            vertical-align: middle;
            margin-right: 4px;
            transition: color 0.25s;
          }
          :global(.prediction-item:after) {
            content: "";
            display: block;
            width: 3px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: transparent;
            text-transform: none;
            transition: background-color 0.25s;
          }
          :global(.prediction-item:hover),
          :global(.prediction-item.selected),
          :global(.prediction-item.hovered) {
            background-color: #e2e3e5;
          }
          :global(.prediction-item:hover:before),
          :global(.prediction-item.selected:before),
          :global(.prediction-item.hovered:before) {
            color: orange;
          }
          :global(.prediction-item:hover:after),
          :global(.prediction-item.selected:after),
          :global(.prediction-item.hovered:after) {
            background-color: orange;
          }
        `}
      </style>
    </form>
  );
};

export default connect(
  null,
  { closeModal }
)(ReuseInventoryModalForm);
