import { handleActions } from 'redux-actions';
import {
  SET_IS_MOBILE
} from '../../actionTypes';

export const initialState = {
  isMobile: false
};

const mobileReducer = handleActions(
  {
    [SET_IS_MOBILE]: (state, {payload}) => ({
      ...state,
      isMobile: payload
    })
  },
  initialState
);

export default mobileReducer;
