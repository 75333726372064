import { EventTypes } from 'redux-segment';
import { TRACK_LOADED_MOVEDAY_PAGE, TRACK_TO_SEGMENT } from './../actionTypes';

export const track = (event, data) => ({
  type: TRACK_TO_SEGMENT,
  meta: {
    analytics: { 
      eventType: EventTypes.track,
      eventPayload: {
        event: event,
        properties: {
          data: data
        }
      } 
    }
  }
});

export const trackPage = (name, category) => ({
  type: TRACK_LOADED_MOVEDAY_PAGE,
  payload: {
    name: name,
    category: category
  },
  meta: {
    analytics: { eventType: EventTypes.page}
  }
});


