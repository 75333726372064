import { BOOK, CHECK_PROMO, INIT_BOOK_PAGE, NETWORK_SUCCESS, SUBMIT_BOOK } from '../actionTypes';
import { apiRequest } from '../actions/api';
import {
  setBookContact,
  setClientToken,
  setCouponData,
  setInvalidCoupon
} from '../actions/book';
import { getCurrentMoveplan, setCurrentMoveplanID, updateMovePlan } from '../actions/movePlan';
// import client from 'braintree-web/client';
// import hostedFields from 'braintree-web/hosted-fields';
import { gotoCongrats, setCurrentStep } from '../actions/funnel';
import has from 'lodash/has';
import { registerUser } from '../actions/auth';

const bookMiddleware = ({ getState, dispatch }) => next => action => {
  next(action);

  if(action.type === INIT_BOOK_PAGE) {
    // dispatch movePlan validDates if theres no validDates fetched
    // let validDates = getState().movePlan.validDates;
    if(getState().movePlan.currentMPID !== action.payload.movePlanId) {
      dispatch(setCurrentMoveplanID(action.payload.movePlanId));
    }
    dispatch(getCurrentMoveplan());
    dispatch(setCurrentStep('BOOK'));
    // if(!validDates.cardcard_charge_date) {
    //   dispatch(getValidDates());
    // }
    // var hostedFields = require('braintree-web/hosted-fields');
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/bookings/client_token`,
        method: 'GET',
        fromAction: INIT_BOOK_PAGE
      })
    );
  }

  if( action.type === CHECK_PROMO) {
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/check_coupon`,
        method: 'GET',
        params: {
          code: action.payload.couponCode
        },
        fromAction: CHECK_PROMO
      })
    )
  }

  if( action.type === BOOK) {
    const values = action.payload;
    // TODO: check coupon first
    if(!has(getState().auth, 'user')){
      dispatch(registerUser(
        {
          email: values.registrationEmail,
          password: values.newPassword1,
          password_confirmation: values.newPassword2
        }
      ));
    }
    dispatch(setBookContact({
      contact_information: {
        name: values.name,
        phone_number: values.phoneNumber
      },
      payment: {
        first_name: values.fname,
        last_name: values.lname
      }
    }));
    if(values.user_note) {
      dispatch(updateMovePlan({ user_note: values.user_note}));
    }

  }

  if( action.type === SUBMIT_BOOK) {
    dispatch(
      apiRequest({
        url: `api/v3/move_plans/${getState().movePlan.currentMPID}/bookings`,
        method: 'POST',
        params: getState().book.bookPayload,
        fromAction: SUBMIT_BOOK
      })
    );
  }


  if( action.type === NETWORK_SUCCESS) {
    switch (action.meta.originalRequest.fromAction) {
      case CHECK_PROMO:
        console.log('promo check');
        if(action.payload.response.data.success) {
          dispatch(setCouponData(action.payload.response.data))
          dispatch(setInvalidCoupon(false));
        } else {
          dispatch(setInvalidCoupon(true));
        }
        break;

      case INIT_BOOK_PAGE:
        dispatch(setClientToken(action.payload.response.data.braintree_client_token));
        // client.create({
        //   authorization: action.payload.response.data.braintree_client_token
        // }, function (err, clientInstance) {
        //   if (err) {
        //     console.error(err);
        //     return;
        //   }
        //   hostedFields.create({
        //     client: clientInstance,
        //     styles: {
        //       'input': {
        //         'color': '#042b3b'
        //       },
        //       '.valid': {
        //         'color': 'green'
        //       },
        //       '.invalid': {
        //         'color': 'red'
        //       },
        //     },
        //     fields: {
        //       number: {
        //         selector: '#card-number',
        //         placeholder: 'Card Number',
        //       },
        //       cvv: {
        //         selector: '#cvv',
        //         placeholder: 'CVV'
        //       },
        //       expirationMonth: {
        //         selector: '#expiration-month',
        //         placeholder: 'MM'
        //       },
        //       expirationYear: {
        //         selector: '#expiration-year',
        //         placeholder: 'YY'
        //       },
        //       postalCode: {
        //         selector: '#postal-code',
        //         placeholder: 'Zip'
        //       },



        //     }
        //   }, function (err, hostedFieldsInstance) {
        //     if (err) {
        //       console.error(err);
        //       return;
        //     }
        //     // dispatch(setHostedFieldsInstance(hostedFieldsInstance));
        //     hostedFieldsInstance.on('validityChange', function (event) {
        //       let field = event.fields[event.emittedBy];
        //       let errors = {};
        //       console.log(event.fields[event.emittedBy])
        //       if (field.isValid) {
        //         if (event.emittedBy === 'expirationMonth') {
        //           if (!event.fields.expirationMonth.isValid) {
        //             errors.date = 'This month is not valid';
        //             dispatch(setCardErrors(errors));
        //             return -1;
        //           }
        //         } else if( event.emittedBy === 'expirationYear') {
        //           if(!event.fields.expirationYear.isValid) {
        //             errors.date = 'This year is not valid';
        //             dispatch(setCardErrors(errors));
        //             return -1;
        //           }
        //         } else if (event.emittedBy === 'number') {
        //           if(!event.fields.number.isValid) {
        //             errors.number = 'Credit card is invalid';
        //             dispatch(setCardErrors(errors));
        //           }
        //         }
        //       } else if (field.isPotentiallyValid) {
        //         // Remove styling  from potentially valid fields
        //         dispatch(setCardErrors({}));
        //         if (event.emittedBy === 'number') {
        //           errors.number = 'There is a problem with this card';
        //           dispatch(setCardErrors(errors));
        //         }
        //       } else {
        //         if (event.emittedBy === 'number') {
        //           errors.number = 'Looks like this card number has an error.';
        //           dispatch(setCardErrors(errors));
        //         }
        //       }
        //     });
        //     let elem = document.getElementById("book-form");
        //     elem.addEventListener("submit", function () {
        //       hostedFieldsInstance.tokenize(function (err, payload) {
        //         if (err) {
        //           console.error(err);
        //           return;
        //         }
        //         if(payload){
        //           console.log("this should be the payload");
        //           let bookPayload = getState().book.bookPayload;
        //           bookPayload.payment.billing_nonce = payload.nonce;
        //           console.log(bookPayload);
        //           dispatch(submitBook())
        //         }
        //         return
        //       });
        //     });
        //   });
        // });
        break;
      case SUBMIT_BOOK:
        dispatch(gotoCongrats());
        console.log('booking was a success');
        console.log(action.payload.response.data);
        break;
      default:
        break;
    }
  }
};

export default bookMiddleware;
