import React from 'react';
import {connect} from 'react-redux';
import { closeModal } from '../../../redux/actions/modals';
import { deletePlan } from '../../../redux/actions/myHome';

const DeletePlanModal = ({index, closeModal, deletePlan}) => (
  <div>
    <header className="top-action-panel">
      <button className="close-reveal-modal" onClick={() => closeModal()}>✖</button>
    </header>
    <div className="modal-body text-center">
      <header className="modal-header">
        <h3 className="title">Are You Certain You Want To Delete This Plan?</h3>
      </header>
      <div className="content-section">
        <p>You won't be able to recover your move plan after this.</p>
      </div>
    </div>
    <footer className="mb-5 d-flex justify-content-between">
      <button className="gray-pill-button"
              onClick={() => closeModal()}>
        No
      </button>
      <button className="green-pill-button"
              onClick={() => {deletePlan(index); closeModal() }}>
        Yes, Delete
      </button>
    </footer>

    <style jsx>
      {`
        .modal-header {
          padding: 0;
          border: none;
          margin-bottom: 20px;
        }
        .modal-body {
            padding: 0 50px 20px;
        }
        .title {
          font-weight: 800;
          font-size: 22px;
          display: block;
          margin-bottom: 6px;
          text-transform: uppercase;
        }
        p {
            margin-bottom: 0.5em;
        }
        .content-section {
            padding-bottom: 20px;
        }
        .green-pill-button {
          background: #de3d3d
        }
    
        `}
    </style>
  </div>
);

export default connect(null, {closeModal, deletePlan})(DeletePlanModal)
