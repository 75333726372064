import {
    // GOTO_BOXES,
    // SET_STEP_COMPLETED,
    // GOTO_COMPARE,
    // GOTO_LAST_STEP,
    // GOTO_MY_INVENTORY,
    // GOTO_DETAILS,
    // GOTO_STEP,
    // NEXT_STEP,
    // PRINT_PAGE,
    SET_HOURLY_CURRENT_STEP,
    // SET_LAST_STEP, GOTO_BOOK, GOTO_CONGRATS, GOTO_PLAN, SET_ROUTE_KEY
    SKIP_HOURLY_CONFIRMATION_STEP, COMPLETE_HOURLY_CONFIRMATION_STEP, SET_HOURLY_CURRENT_CONFIRMATION_STEP
  } from '../actionTypes';
  
//   export const nextStep = () => ({
//     type: NEXT_STEP
//   });
  
  export const setHourlyCurrentStep = step => ({
    type: SET_HOURLY_CURRENT_STEP,
    payload: step
  });
  
//   export const setLastStep = step => ({
//     type: SET_LAST_STEP,
//     payload: step
//   });
  
//   export const gotoMyInventory = () => ({
//     type: GOTO_MY_INVENTORY
//   });
  
//   export const gotoDetails = () => ({
//     type: GOTO_DETAILS
//   });
  
//   export const gotoCompare = () => ({
//     type: GOTO_COMPARE
//   });
  
//   export const gotoPlan = () => ({
//     type: GOTO_PLAN
//   });
  
//   export const gotoBook = () => ({
//     type: GOTO_BOOK
//   });
  
//   export const gotoCongrats = () => ({
//     type: GOTO_CONGRATS
//   });
  
//   export const gotoBoxes = () => ({
//     type: GOTO_BOXES
//   });
  
//   export const goToStep = (step) => ({
//     type: GOTO_STEP,
//     payload: step
//   });
  
//   export const goToLastStep = () => ({
//     type: GOTO_LAST_STEP
//   });
  
//   export const printPage = (page, isCurrent) => ({
//     type: PRINT_PAGE,
//     payload: { page: page, isCurrentPage: isCurrent}
//   });
  
//   export const setStepCompleted = step => ({
//     type: SET_STEP_COMPLETED,
//     payload: step
//   });
  
  //// CONFIRMATION FUNNEL ////
  
  export const skipHourlyConfirmationStep = index => ({
    type: SKIP_HOURLY_CONFIRMATION_STEP,
    payload: index
  });
  
  export const completeHourlyConfirmationStep = index => ({
    type: COMPLETE_HOURLY_CONFIRMATION_STEP,
    payload: index
  });
  
  export const setHourlyCurrentConfirmationStep = index => ({
    type: SET_HOURLY_CURRENT_CONFIRMATION_STEP,
    payload: index
  });
  
//   export const setRouteKey = routeKey => ({
//     type: SET_ROUTE_KEY,
//     payload: routeKey
//   });
  
  