import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import PageNotFound from "./components/common/PageNotFound";
import MainConfirmation from "./components/layouts/MainConfirmation";
import MainMyHome from "./components/layouts/MainMyHome";
import MainMDA from "./components/layouts/MainMDA";
import "bootstrap/dist/css/bootstrap.css";
import Modals from "./components/common/Modals/Modals";

const App = () => {
  return (
    <div className="confirmation-main confirmation-view">
      <Modals />
      <Switch>
        <Route path="/my-home" component={MainMyHome} />
        <Route path="/move-day-adjustment" component={MainMDA} />
        <Route path='/moving/:uuid/:page' component={(props) => {window.location =`${process.env.REACT_APP_DOMAIN}/moving/${props.match.params.uuid}/${props.match.params.page}`; return null;} }/>
        <Route path="/" component={MainConfirmation} />
        <Route component={PageNotFound} />
      </Switch>
    </div>
  );
};

export default withRouter(App);
