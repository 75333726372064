import { handleActions } from 'redux-actions';
import {
  SET_BOOK_CONTACT,
  SET_CARD_ERRORS,
  SET_CLIENT_TOKEN,
  SET_COUPON_DATA,
  SET_HOSTED_FIELDS_INSTANCE,
  SET_INVALID_COUPON, TOGGLE_IS_SUBMITTING
} from '../actionTypes';

const initialState = {
  couponData: {},
  couponInvalid: false,
  errorMessages: undefined,
  clientToken: '',
  cardErrors: {},
  hostedFieldsInstance: {},
  bookPayload: {},
  isSubmitting: false
};

const bookReducer = handleActions(
  {
    [SET_COUPON_DATA]: (state, { payload }) => ({
      ...state,
      couponData: payload
    }),
    [SET_INVALID_COUPON]: (state, { payload }) => ({
      ...state,
      couponInvalid: payload
    }),
    [SET_CLIENT_TOKEN]: (state, {payload}) => ({
      ...state,
      clientToken: payload
    }),
    [SET_CARD_ERRORS]: (state, {payload}) => ({
      ...state,
      cardErrors: payload
    }),
    [SET_HOSTED_FIELDS_INSTANCE]: (state, {payload}) => ({
      ...state,
      hostedFieldsInstance: payload
    }),
    [SET_BOOK_CONTACT]: (state, {payload}) => ({
      ...state,
      bookPayload: payload
    }),
    [TOGGLE_IS_SUBMITTING]: (state) => ({
      ...state,
      isSubmitting: !state.isSubmitting
    })
}, initialState);


export const showPromoNotification = (movePlan) => {
  // todo: add localstorage check here
  return movePlan.promo_code && !movePlan.currentPlan.is_booked && !movePlan.currentPlan.is_done;
};

export default bookReducer;