import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import { isLoggedIn } from '../../redux/reducers/auth';
import { getMoveplan } from '../../redux/actions/movePlan';
import {connect} from 'react-redux';
import { track } from '../../redux/actions/analytics';

const Arrow = ({color}) => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 8.3 14.5">
    <path d="M0.3,14.2c0.4,0.4,1.1,0.4,1.5,0l0,0L8,8c0.4-0.4,0.4-1.1,0-1.5l0,0L1.8,0.3c-0.4-0.4-1.1-0.4-1.5,0
    s-0.4,1.1,0,1.5l5.5,5.5l-5.5,5.5C-0.1,13.1-0.1,13.8,0.3,14.2z" />
    <style jsx> {`
    svg {
      ${color === 'blue' ?
      'width: 1rem; fill:  #0abee7; margin-left: 0;'
      :
      'width: .7rem; fill:  #193749; margin-left: 1rem;'
      }
    `}
    </style>
  </svg>
)

const mapStateToProps = ({auth, router, movePlan}) => ({
  isLoggedIn: isLoggedIn(auth),
  user: auth.user,
  router: router,
  shortId: movePlan.shortMPID,
  uuid: !!auth.moveplans ? auth.moveplans.booked_move_plans[0] ? auth.moveplans.booked_move_plans[0].uuid : auth.moveplans.unbooked_move_plans[0] ? auth.moveplans.unbooked_move_plans[0].uuid : undefined : undefined,
  bookedPlans: auth.moveplans ? auth.moveplans.booked_move_plans.length > 0 : false,
  unbookedPlans: auth.moveplans ? auth.moveplans.unbooked_move_plans.length > 0 : false
})


const LeftNav = ({isLoggedIn, user, viewModel, router, uuid, bookedPlans, unbookedPlans, shortId, track}) => {

  const [openMobileMenu, setOpenMobileMenu] = useState(router.location.pathname === '/my-home' ? true : false);
  const toggleMenu = () => {
    if (openMobileMenu && (viewModel === 'mobile' || viewModel === 'tablet')) {
      document.getElementById('navbar').classList.add('slide-out-left'); 
      setTimeout(() => setOpenMobileMenu(!openMobileMenu), 300) 
    } else {
      setOpenMobileMenu(!openMobileMenu)
    }
  }

  return (
    <>
    {(viewModel === 'tablet' || viewModel === 'mobile') && 
    <div className="menu-toggle" onClick={() => toggleMenu()}>
      <div className={`rotating-arrow ${openMobileMenu ? 'rotated' : ''}`}>
      <Arrow color={'blue'} />
      </div>
    </div>
    }

    {((viewModel ==='desktop' || viewModel === 'wide') || openMobileMenu) && 
    <>
    {(openMobileMenu && !(viewModel ==='desktop' || viewModel === 'wide')) &&  <div className="black-overlay" onClick={() => toggleMenu()}></div>}
    <nav id="navbar" className={`pt-0 pl-4 pr-4 ${(viewModel === 'tablet' || viewModel === 'mobile') ? 'mobile-settings slide-in-left' : '' }`}>
    {isLoggedIn ? 
    <>
    <div className="mt-5 mb-5 text-center profile ">
    
    <div className=" name-holder position-relative">
      <div className="user-icon">
      <a href="/my-home"><img alt="logo" src="/assets/unpakt_square_logo.png" /></a>
      </div>
      <div className="user-name">
      <h3 className="mt-3">{user.email}</h3>
      </div>
    </div>

    </div>
     <Link className="nav-button" onClick={() => {toggleMenu();track('clicked Move History tab')}} to="/my-home/move-history"><button className="menu-option"><div><img className="mr-3" alt="icon" src="/assets/my_home_icons/move_history.png" />View My Moves</div><Arrow /></button></Link>
     <Link className="nav-button" onClick={() => {toggleMenu();track('clicked Start New Move tab')}} to="/my-home/start-new-move"><button className="menu-option"><div><img className="mr-3" alt="icon" src="/assets/my_home_icons/start_plan.png" />Start a New Move</div> <Arrow /></button></Link>
     <Link className="nav-button" onClick={() => {toggleMenu();track('clicked Contact Unpakt tab')}} to="/my-home/contact"><button className="menu-option"><div><img className="mr-3" alt="icon" src="/assets/my_home_icons/chatbox.png" />Contact Unpakt</div><Arrow /></button></Link>
     <Link className="nav-button" onClick={() => {toggleMenu();track('clicked Refer Friends tab')}} to="/my-home/refer-friends"><button className="menu-option"><div><img className="mr-3" alt="icon" src="/assets/my_home_icons/refer.png" />Refer Friends</div><Arrow /></button></Link>
     <Link className="nav-button" onClick={() => {toggleMenu();track('clicked Reset Password tab')}} to="/my-home/reset-password"><button className="menu-option"><div><img className="mr-3" alt="icon" src="/assets/my_home_icons/confirm_my_move.png" />Reset Password</div><Arrow /></button></Link>
     <Link className="nav-button" onClick={() => {toggleMenu();track('clicked Moving Checklist tab')}} to="/my-home/moving-checklist"><button className="menu-option"><div><img className="mr-3" alt="icon" src="/assets/my_home_icons/sign_document.png" />Moving Checklist</div><Arrow /></button></Link>

{(bookedPlans || unbookedPlans) &&
    <>
     <h1>Before the Move{' '}<small>· Move ID: {shortId}</small></h1>
      <a className="nav-button" onClick={() => {toggleMenu();track('clicked View Latest Moveplan tab')}} href={`${viewModel === 'mobile' ? `${process.env.REACT_APP_MOBILE_FUNNEL_URL}/moving/${uuid}/Review` : `${process.env.REACT_APP_DOMAIN}/moving/${uuid}/plan`}`}
      ><button className="menu-option"><div><img className="mr-3" alt="icon" src="/assets/my_home_icons/my_move_plan.png" />View My Latest Move Plan</div><Arrow /></button></a>
      {bookedPlans && 
      <Link className="nav-button" onClick={() => {toggleMenu();track('clicked Confirm My Move tab')}} to={`${(viewModel === 'mobile' || viewModel === 'tablet') ? "/" : "/my-home/confirm-move"}`}><button className="menu-option"><div><img className="mr-3" alt="icon" src="/assets/my_home_icons/truck.png" />Confirm My Move</div><Arrow /></button></Link>
      }
      <Link className="nav-button" onClick={() => {toggleMenu();track('clicked Add Boxes tab')}} to="/my-home/add-services"><button className="menu-option"><div><img className="mr-3" alt="icon" src="/assets/my_home_icons/boxes.png" />Add Boxes/Packing Service</div><Arrow /></button></Link>
      <Link className="nav-button" onClick={() => {toggleMenu();track('clicked Add Services tab')}} to="/my-home/add-special-handling"><button className="menu-option"><div><img className="mr-3" alt="icon" src="/assets/my_home_icons/extra_services.png" />Add (Dis)Assembly Service</div><Arrow /></button></Link>
      </>
      }
      {bookedPlans &&
      <>
    <h1>During the Move</h1>
      <Link className="nav-button" onClick={() => {toggleMenu();track('clicked Move Day Comments tab')}} to="/my-home/current-move-review"><button className="menu-option"><div><img className="mr-3" alt="icon" src="/assets/my_home_icons/storage.png" />Move Day Feedback</div><Arrow /></button></Link>
      </>
      }
    <h1>After the Move</h1>
      {bookedPlans && <Link className="nav-button" onClick={() => {toggleMenu();track('clicked Leave a Review tab')}} to="/my-home/feedback"><button className="menu-option"><div><img className="mr-3" alt="icon" src="/assets/my_home_icons/star_icon.png" />Leave a Review</div><Arrow /></button></Link> }
      <Link className="nav-button" onClick={() => {toggleMenu();track('clicked Deals tab')}} to="/my-home/deals"><button className="menu-option"><div><img className="mr-3" alt="icon" src="/assets/my_home_icons/dollar_sign.png" />Deals</div><Arrow /></button></Link>
      <Link className="nav-button" onClick={() => {toggleMenu();track('clicked Relocation Services tab')}} to="/my-home/relocation-services"><button className="menu-option mb-5"><div><img className="mr-3" alt="icon" src="/assets/my_home_icons/my_items.png" />Relocation Services</div><Arrow /></button></Link>
    </>
    :
    <>
    <p className="m-auto">Please sign into your account to access your dashboard.</p>
    </>
    }
    </nav>
    </>
    }

      <style jsx>{`
       nav { 
        background: #fff;
        max-width: 320px;
        width: 100%;
        overflow: auto;
        height: calc(100vh - 70px);
        position: sticky;
        top: 70px;
        z-index: 5;
        padding-bottom: 1.5rem;
      }
      .black-overlay {
        position: fixed;
        width: 100vw;
        height: calc(100vh - 70px);
        background: black;
        background: radial-gradient(transparent, black);
        opacity: .4;
        z-index: 5;
      }
      
      .name-holder {
        border-bottom: 2px solid #0abeeb; 
        height: 40px;
        margin-bottom: 5rem
      }
      .mobile-settings {
        position: fixed;
        z-index: 5; 
        box-shadow: 5px 0 20px 10px #0000003d; 
        padding-bottom: 70px;
      }
      .menu-toggle {
        position: fixed;
        z-index: 6;
        width: 40px;
        height: 40px;
        background: white;
        border-radius: 0 1rem 1rem 0;
        ${!openMobileMenu ? 'box-shadow: 5px 5px 5px #00000030;' : ''}
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        transition-duration: .3s;
      }
      .rotating-arrow {
        transform: rotate(0deg);
        transition-duration: .3s;
      }
      .rotated {
        transform: rotate(180deg);
        
      }



      nav::-webkit-scrollbar {
        width: .5rem;
      }
      nav::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      }
      nav::-webkit-scrollbar-thumb {
        background-color: #0abeeb;
        outline: none
      }


      .menu-option {
        color: #193749;
        background: #EAEAEA;
        border: none; 
        outline: none;
        width: 100%;
        font-size: 1.6rem;
        padding: 1rem 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 2px;
        margin: 2px 0;
        font-size: 1.4rem;

        // background: white;
        // border: 1px solid #0abee8;
        // padding: .7rem 1.5rem;
        // border-radius: 20px;
        // margin: 0 0 8px 0;


      }
      .menu-option:hover, .menu-option:active {
        background: #ebf9ff;
      }
      :global(a.nav-button:hover) {
        text-decoration: none
      }
      .menu-option img {
        width: 25px;
        height: 25px;
      }
      .menu-option > div {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;
      }

      h1 {
        font-size: 1.6rem;
        line-height: 2.5rem;
        font-weight: 600;
        margin: 2.5rem 0 0;
        color: #29afe8;
        text-align: left;
        border-bottom: 2px solid #0abeeb
      }
      h3 {
        font-size: 1.6rem;
        font-weight: 600;
        color: #29afe8;
      }
      .user-icon {
        width: 40px;
        height: 40px;
        border-radius: .5rem;
        border: 3px solid #31afd8;
        overflow:hidden;
        position: absolute;
        top: 15px;
        
      }
      .user-name {
        margin-left: 50px;
        height: 20px;
        position: absolute;
        top: 10px;
      }

      img {
        width: 100%
      }

      .slide-in-left {
        -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
                animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      }
      .slide-out-left {
        -webkit-animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
                animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
      }

      /* ----------------------------------------------
      * Generated by Animista on 2019-7-9 14:50:19
      * w: http://animista.net, t: @cssanimista
      * ---------------------------------------------- */
     
     /**
      * ----------------------------------------
      * animation swing-in-left-fwd
      * ----------------------------------------
      */

      @-webkit-keyframes slide-in-left {
        0% {
          -webkit-transform: translateX(-1000px);
                  transform: translateX(-1000px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateX(0);
                  transform: translateX(0);
          opacity: 1;
        }
      }
      @keyframes slide-in-left {
        0% {
          -webkit-transform: translateX(-1000px);
                  transform: translateX(-1000px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateX(0);
                  transform: translateX(0);
          opacity: 1;
        }
      }
    
          
      @-webkit-keyframes slide-out-left {
        0% {
          -webkit-transform: translateX(0);
                  transform: translateX(0);
          opacity: 1;
        }
        100% {
          -webkit-transform: translateX(-1000px);
                  transform: translateX(-1000px);
          opacity: 0;
        }
      }
      @keyframes slide-out-left {
        0% {
          -webkit-transform: translateX(0);
                  transform: translateX(0);
          opacity: 1;
        }
        100% {
          -webkit-transform: translateX(-1000px);
                  transform: translateX(-1000px);
          opacity: 0;
        }
      }
      
          
            

      `}
      </style>
  </>
  )
}
export default connect(mapStateToProps, {getMoveplan, track})(LeftNav)